import { Lead } from "../../models/lead";
import { User } from "../../models/user";
import { Pagination } from "../../types";
import { LeadClaimAction } from "../types";

export const leadClaimAction = (
  currentUser: User,
  data: LeadClaimAction["data"],
  setLeads: (leads: Lead[]) => void,
  leads: Lead[],
   //TODO Create Interface for filters
   filters?: any,
   pagination?: Pagination,
   setPagination?: (pagination: Pagination) => void
) => {
  setLeads(
    leads?.map((lead) => {
      if (Number(data?.id) !== lead?.id) return lead;

      return {
        ...lead,
        agent: data?.agent,
        priority: data?.priority,
      };
    })
  );
};
