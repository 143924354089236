import { EventTypes } from "../../models/event";
import { Lead } from "../../models/lead";
import { User } from "../../models/user";
import { Pagination } from "../../types";
import { NewEventAction } from "../types";

export const newEventAction = (
  currentUser: User,
  data: NewEventAction["data"],
  setLeads: (leads: Lead[]) => void,
  leads: Lead[],
  //TODO Create Interface for filters
  filters?: any,
  pagination?: Pagination,
  setPagination?: (pagination: Pagination) => void
) => {
  setLeads(
    leads.map((lead) => {
      if (Number(data.lead_id) !== lead.id) return lead;

      return {
        ...lead,
        events_map: {
          ...lead.events_map,
          ...(EventTypes.first_call === data.type
            ? {
                first_call: data,
              }
            : {}),
          ...(EventTypes.pricing === data.type
            ? {
                latest_price: data,
              }
            : {}),
        },
      };
    })
  );
};
