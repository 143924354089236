import { Template } from "../../../models/template";
import { httpPut } from "../../utilities";

export interface HttpAdminUpdateTemplate extends Partial<Omit<Template, "id" | "created_at" | "updated_at">> {}

export const httpAdminUpdateTemplate = async (
  id: number | string,
  data: HttpAdminUpdateTemplate
) => {
  return httpPut(`/admin/template/${id}`, data);
};
