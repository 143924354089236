import { RequestHeader, RequestQueryParamInterface } from "../types";
import { httpBasic } from "./httpBasic";

export const httpDelete = <ResponseType>(
  url: string,
  queryParams?: RequestQueryParamInterface,
  headers?: RequestHeader,
  data?: any
) => {
  return httpBasic<ResponseType>("DELETE", url, headers, queryParams, data);
};