export const toReadableDate = (datetime: string | Date) => {
  const baseDate = typeof datetime === "string" ? new Date(datetime) : datetime;

  const monthString = baseDate
    .toLocaleString("default", { month: "long" })
    .slice(0, 3);
  const yearString = baseDate.getFullYear();
  const dayString = baseDate.getDate();
  const hoursString = `${
    baseDate.getHours() < 10 ? "0" : ""
  }${baseDate.getHours()}`;
  const minutesString = `${
    baseDate.getMinutes() < 10 ? "0" : ""
  }${baseDate.getMinutes()}`;

  return `${monthString} ${dayString} ${yearString} ${hoursString}:${minutesString}`;
};
