import React from "react";

import styled from "styled-components";

export const GlobalContainer = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InputContainer = styled.label<Pick<ToggleProps, "width" | "height">>`
  position: relative;
  display: inline-block;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  > input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const Input = styled.input<Pick<ToggleProps, "backgroundColorChecked" | "backgroundColorUnchecked" | "translate">>`
  &:checked + span {
    background-color: ${({ backgroundColorChecked }) => backgroundColorChecked};
  }
  &:disabled + span {
    background-color: ${({ backgroundColorUnchecked }) =>
      backgroundColorUnchecked};
    opacity: 0.4;
    cursor: not-allowed;
  }
  &:disabled:checked + span {
    background-color: ${({ backgroundColorChecked }) => backgroundColorChecked};
    opacity: 0.4;
    cursor: not-allowed;
  }
  &:focus + span {
    box-shadow: 0 0 1px #2196f3;
  }
  &:checked + span:before {
    -webkit-transform: translateX(${({ translate }) => translate}px);
    -ms-transform: translateX(${({ translate }) => translate}px);
    transform: translateX(${({ translate }) => translate}px);
  }

  width: 100%;
  height: 100%;
`;

export const Slider = styled.span<
  Pick<
    ToggleProps,
    | "backgroundColorUnchecked"
    | "backgroundColorButton"
    | "sliderHeight"
    | "sliderWidth"
  >
>`
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ backgroundColorUnchecked }) =>
    backgroundColorUnchecked};
  -webkit-transition: 0.2s;
  transition: 0.2s;
  border-radius: 34px;
  &:before {
    position: relative;
    border-radius: 50%;
    content: "";
    height: ${({ sliderHeight }) => sliderHeight}px;
    width: ${({ sliderWidth }) => sliderWidth}px;
    left: 4px;
    background-color: ${({ backgroundColorButton }) => backgroundColorButton};
    -webkit-transition: 0.2s;
    transition: 0.2s;
  }
`;

export const Label = styled.span`
  color: #777;
  font-size: 15px;
  font-family: helvetica;
  padding: 0 10px;
`;

export type ToggleProps = {
  name?: string;
  value?: string;
  checked?: boolean;
  disabled?: boolean;
  labelRight?: string;
  labelLeft?: string;
  width?: number;
  height?: number;
  translate?: number;
  sliderWidth?: number;
  sliderHeight?: number;
  backgroundColorChecked?: string;
  backgroundColorUnchecked?: string;
  backgroundColorButton?: string;
  onChange?: () => void;
};

export const Toggle = ({
  name,
  value,
  checked,
  disabled,
  labelRight,
  labelLeft,
  width = 60,
  height = 34,
  translate = 26,
  sliderWidth = 26,
  sliderHeight = 26,
  backgroundColorChecked = "#2193F3",
  backgroundColorUnchecked = "#ccc",
  backgroundColorButton = "#fff",
  onChange,
}: ToggleProps) => {
  return (
    <GlobalContainer>
      {labelLeft && <Label>{labelLeft}</Label>}
      <InputContainer onChange={onChange} width={width} height={height}>
        <Input
          type="checkbox"
          name={name}
          value={value}
          checked={checked}
          disabled={disabled}
          translate={translate}
          backgroundColorUnchecked={backgroundColorUnchecked}
          backgroundColorChecked={backgroundColorChecked}
        />
        <Slider
          sliderWidth={sliderWidth}
          sliderHeight={sliderHeight}
          backgroundColorUnchecked={backgroundColorUnchecked}
          backgroundColorButton={backgroundColorButton}
        />
      </InputContainer>
      {labelRight && <Label>{labelRight}</Label>}
    </GlobalContainer>
  );
};
