import styled from "styled-components";

interface ParticleProps {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  alpha?: number;
  color?: string;
  animationDelay?: string;
}

export const ParticleStyle = styled.span<ParticleProps>`
  display: block;
  width: ${({ width }) => (width ? `${width}` : "32")}px;
  height: ${({ height }) => (height ? `${height}` : "32")}px;
  opacity: ${({ alpha }) => (alpha ? `${alpha}` : 1)};
  background-color: ${({ color }) => color ?? "black"};
  ${({ animationDelay }) => animationDelay ? `animation-delay:${animationDelay}` : ""};
`;
