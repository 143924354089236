import { ReactNode } from "react";
import { styled } from "styled-components";

const Curve = styled.img`
  position: fixed;
  width: calc(100% + 16px);
  bottom: 0;
  z-index: -1;
  left: -16px;
`

interface ContentProps {
  children?: ReactNode;
}

export const Content = ({ children }: ContentProps) => {
  return (
    <>
      {children}
      <Curve width="100%" src="/assets/images/curve.png" />
    </>
  );
};
