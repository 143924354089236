import { useCallback, useEffect, useState } from "react";
import { LeadProviderStatistics } from "../../../../../models/statistics";
import { Table } from "../../components/Table";
import { theme } from "../../../../../constants";
import {
  Button,
  ButtonTypes,
  FlexColumn,
  FlexItem,
  FlexRow,
  Text,
  TitleXL,
} from "../../../../../shared/styled";
import { getLastMonday } from "../utilities";
import { DateRangeValue } from "../../../../../shared/components/DateRange/types";
import { httpAdminGetLeadProviderStatistics } from "../../../../../apis/admin";
import {
  toQueryParamDateTime,
  toReadableDateOnly,
} from "../../../../../utilities/datetime";
import toast from "react-hot-toast";
import { useModalProvider } from "../../../../../contexts";
import { DateRangeFilterModal } from "../../../../../modals";
import { Icon, IconTypes } from "../../../../../shared/components";

export const ProviderTable = () => {
  const { openModal, closeModal } = useModalProvider();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [order, setOrder] = useState<string[]>([]);

  const [createdAtRange, setCreatedAtRange] = useState<DateRangeValue>({
    start: getLastMonday(),
    end: new Date(),
  });

  const [data, setData] = useState<LeadProviderStatistics[]>();

  const fetchData = useCallback(async () => {
    setLoading(true);
    if (!createdAtRange?.start || !createdAtRange?.end) {
      setLoading(false);
      return;
    }

    try {
      const leadResponse = await httpAdminGetLeadProviderStatistics(
        undefined,
        undefined,
        order,
        createdAtRange?.start && createdAtRange?.end
          ? toQueryParamDateTime(createdAtRange.start)
          : undefined,
        createdAtRange?.start && createdAtRange?.end
          ? toQueryParamDateTime(createdAtRange.end, true)
          : undefined
      );

      const preparedData = (
        leadResponse?.data as LeadProviderStatistics[]
      ).filter(
        (statistic) =>
          statistic.total_leads !== undefined && statistic.total_leads > 0
      );

      const totalData = preparedData.reduce(
        (acc, stats) => {
          acc.total_leads = (acc.total_leads ?? 0) + (stats.total_leads ?? 0);
          acc.claimed_value =
            (acc.claimed_value ?? 0) + (stats.claimed_value ?? 0);
          acc.bad_value = (acc.bad_value ?? 0) + (stats.bad_value ?? 0);
          acc.future_value =
            (acc.future_value ?? 0) + (stats.future_value ?? 0);
          acc.hot_value = (acc.hot_value ?? 0) + (stats.hot_value ?? 0);
          acc.potential_value =
            (acc.potential_value ?? 0) + (stats.potential_value ?? 0);
          acc.booked_value =
            (acc.booked_value ?? 0) + (stats.booked_value ?? 0);
          return acc;
        },
        {
          lead_provider: { index: 0, name: "TOTAL" },
          total_leads: 0,
          claimed_value: 0,
          bad_value: 0,
          future_value: 0,
          hot_value: 0,
          potential_value: 0,
          booked_value: 0,
        } as LeadProviderStatistics
      );

      setData([...preparedData, totalData]);
      setLoading(false);
    } catch (error) {
      toast.error("Error fetching Lead Providers", {
        style: {
          border: `1px solid ${theme.colors.danger}`,
          padding: "16px",
          boxShadow: "none",
          borderRadius: "24px",
        },
        iconTheme: {
          primary: `${theme.colors.danger}`,
          secondary: `${theme.colors.clean}`,
        },
      });
      console.error(error);
    }
  }, [createdAtRange]);

  useEffect(() => {
    fetchData();
  }, [order, createdAtRange]);

  return (
    <Table<LeadProviderStatistics>
      loading={isLoading}
      title="Lead Provider Statistics"
      header={(value) => true}
      tableShadow={`0px 4px 4px 0px ${theme.shadow.color}`}
      pagination={{}}
      orders={{
        orderableFields: [
          "id",
          "total_leads",
          "claimed_value",
          "bad_value",
          "future_value",
          "hot_value",
          "potential_value",
          "booked_value",
        ],
        setOrder: setOrder,
        value: order,
      }}
      columns={[
        {
          label: "No",
          key: "id",
          styling: { maxWidth: "100%" },
          mapper: (item) => item?.lead_provider?.index || <>-</>,
        },
        {
          label: "Provider Name",
          key: "id",
          styling: { maxWidth: "100%" },
          mapper: (item) => item?.lead_provider?.name || <>-</>,
        },
        {
          label: "Claimed",
          key: "total_leads",
          styling: { maxWidth: "100%" },
          mapper: (item) => item?.total_leads || <>-</>,
        },
        {
          label: "Gray",
          key: "claimed_value",
          styling: { maxWidth: "100%" },
          mapper: (item) => (
            <FlexColumn
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              gap={"4px"}
            >
              <div>{item?.claimed_value || <>-</>}</div>
              {item?.claimed_value && item?.total_leads ? (
                <div
                  style={{
                    backgroundColor: theme.colors.secondary,
                    width: "100%",
                    height: "1px",
                  }}
                />
              ) : (
                <></>
              )}
              <div>
                {item?.claimed_value && item?.total_leads ? (
                  `${((item.claimed_value / item.total_leads) * 100).toFixed(
                    2
                  )}%`
                ) : (
                  <></>
                )}
              </div>
            </FlexColumn>
          ),
        },
        {
          label: "Bad",
          key: "bad_value",
          styling: { maxWidth: "100%" },
          mapper: (item) => (
            <FlexColumn
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              gap={"4px"}
            >
              <div>{item?.bad_value || <>-</>}</div>
              {item?.bad_value && item?.total_leads ? (
                <div
                  style={{
                    backgroundColor: theme.colors.secondary,
                    width: "100%",
                    height: "1px",
                  }}
                />
              ) : (
                <></>
              )}
              <div>
                {item?.bad_value && item?.total_leads ? (
                  `${((item.bad_value / item.total_leads) * 100).toFixed(2)}%`
                ) : (
                  <></>
                )}
              </div>
            </FlexColumn>
          ),
        },
        {
          label: "Future",
          key: "future_value",
          styling: { maxWidth: "100%" },
          mapper: (item) => (
            <FlexColumn
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              gap={"4px"}
            >
              <div>{item?.future_value || <>-</>}</div>
              {item?.future_value && item?.total_leads ? (
                <div
                  style={{
                    backgroundColor: theme.colors.secondary,
                    width: "100%",
                    height: "1px",
                  }}
                />
              ) : (
                <></>
              )}
              <div>
                {item?.future_value && item?.total_leads ? (
                  `${((item.future_value / item.total_leads) * 100).toFixed(
                    2
                  )}%`
                ) : (
                  <></>
                )}
              </div>
            </FlexColumn>
          ),
        },
        {
          label: "Hot",
          key: "hot_value",
          styling: { maxWidth: "100%" },
          mapper: (item) => (
            <FlexColumn
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              gap={"4px"}
            >
              <div>{item?.hot_value || <>-</>}</div>
              {item?.hot_value && item?.total_leads ? (
                <div
                  style={{
                    backgroundColor: theme.colors.secondary,
                    width: "100%",
                    height: "1px",
                  }}
                />
              ) : (
                <></>
              )}
              <div>
                {item?.hot_value && item?.total_leads ? (
                  `${((item.hot_value / item.total_leads) * 100).toFixed(2)}%`
                ) : (
                  <></>
                )}
              </div>
            </FlexColumn>
          ),
        },
        {
          label: "Potential",
          key: "potential_value",
          styling: { maxWidth: "100%" },
          mapper: (item) => (
            <FlexColumn
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              gap={"4px"}
            >
              <div>{item?.potential_value || <>-</>}</div>
              {item?.potential_value && item?.total_leads ? (
                <div
                  style={{
                    backgroundColor: theme.colors.secondary,
                    width: "100%",
                    height: "1px",
                  }}
                />
              ) : (
                <></>
              )}
              <div>
                {item?.potential_value && item?.total_leads ? (
                  `${((item.potential_value / item.total_leads) * 100).toFixed(
                    2
                  )}%`
                ) : (
                  <></>
                )}
              </div>
            </FlexColumn>
          ),
        },
        {
          label: "Booked",
          key: "booked_value",
          styling: { maxWidth: "100%" },
          mapper: (item) => (
            <FlexColumn
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              gap={"4px"}
            >
              <div>{item?.booked_value || <>-</>}</div>
              {item?.booked_value && item?.total_leads ? (
                <div
                  style={{
                    backgroundColor: theme.colors.secondary,
                    width: "100%",
                    height: "1px",
                  }}
                />
              ) : (
                <></>
              )}
              <div>
                {item?.booked_value && item?.total_leads ? (
                  `${((item.booked_value / item.total_leads) * 100).toFixed(
                    2
                  )}%`
                ) : (
                  <></>
                )}
              </div>
            </FlexColumn>
          ),
        },
      ]}
      data={data}
      headerDimensions={{ width: "100%", height: "72px" }}
      headerComponent={
        <FlexColumn
          alignItems="flex-end"
          justifyContent="center"
          gap="5px"
          padding={{ top: "16px", bottom: "16px" }}
          dimensions={{ width: "20%" }}
        >
          <Button
            styleType={ButtonTypes?.primary}
            dimensions={{ height: "32px" }}
            disabled={isLoading}
            onClick={() =>
              openModal("createdAtProviderModal", DateRangeFilterModal, {
                setValue: setCreatedAtRange,
                value: createdAtRange,
                close: () => closeModal("createdAtProviderModal"),
                title: <TitleXL>Created At Range</TitleXL>,
              })
            }
          >
            <FlexRow
              justifyContent="space-between"
              alignItems="center"
              gap="5px"
            >
              <Icon
                type={IconTypes?.calendar}
                size="20px"
                color={theme?.colors?.clean}
              />
              <FlexItem dimensions={{ flex: 1 }}>
                <Text color={theme.colors?.clean} align="center">
                  {(!createdAtRange?.start || !createdAtRange?.end) && (
                    <>Created At Range</>
                  )}
                  {createdAtRange?.start && createdAtRange?.end && (
                    <>
                      {toReadableDateOnly(createdAtRange?.start)} -{" "}
                      {toReadableDateOnly(createdAtRange?.end)}
                    </>
                  )}
                </Text>
              </FlexItem>
            </FlexRow>
          </Button>
        </FlexColumn>
      }
    />
  );
};
