import { Outlet, useParams, useSearchParams } from "react-router-dom";
import { Button, ButtonTypes, FlexColumn, FlexRow } from "../../shared/styled";
import { Icon, IconTypes } from "../../shared/components";
import { Content, Header, Navbar } from "../components";
import { theme } from "../../constants";
import { css } from "styled-components";

const NavbarItems = [
  { path: "/agent/leads", icon: IconTypes.people, name: "Leads", id: "leads" },
  {
    path: "/agent/myleads",
    icon: IconTypes.users,
    name: "My Leads",
    id: "my-leads",
  },
  { path: "/agent/map", icon: IconTypes.location, name: "Map", id: "map" },
];

export const AgentDashboard = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const isToggled = searchParams.get("showFilters") === "true";

  return (
    <FlexRow dimensions={{ width: "100vw", height: "100vh" }} overflow="hidden">
      <Navbar routes={NavbarItems} />
      <FlexColumn dimensions={{ flex: 1 }} padding="10px" gap="10px">
        <Header
          customLeft={
            <Button
              styleType={
                isToggled ? ButtonTypes.primary : ButtonTypes.primaryReverse
              }
              onClick={() => {
                setSearchParams({
                  showFilters: String(!isToggled),
                });
              }}
              css={css`
                transition: all 0.2s;
              `}
            >
              <Icon type={IconTypes.filters} color={isToggled ? theme.colors.clean : theme.colors.primary} />
            </Button>
          }
        />
        <Content>
          <Outlet />
        </Content>
      </FlexColumn>
    </FlexRow>
  );
};
