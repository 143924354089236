import { SectionSchema } from "../types/sectionSchema";

import { ButtonSection } from "./ButtonSection";
import { ButtonSectionDocumentation } from "./documentation";


export const ButtonSectionSchema: SectionSchema = {
  component: ButtonSection,
  documentation: ButtonSectionDocumentation,
  label: "Button",
  id: "button"
}