import { NavigateFunction } from "react-router-dom";

import { Template } from "../../../../models/template";
import { Icon, IconTypes, Select, Toggle } from "../../../../shared/components";
import { TableProps } from "../../../../shared/components/Table/types/TableProps";
import { theme } from "../../../../constants";

interface TableSchemaArgs {
  toggleStatus: (id: number, status: boolean) => void | Promise<void>;
  navigate: NavigateFunction;
}

export const TableSchema = ({
  toggleStatus,
  navigate,
}: TableSchemaArgs): TableProps<Template>["columns"] => [
  {
    label: "No",
    attribute: "id",
    orderByAttribute: "id",
    styling: { dimensions: { maxWidth: "50px", width: "50px" } },
  },
  {
    label: "Name",
    attribute: "name",
    orderByAttribute: "name",
    styling: { dimensions: { maxWidth: "200px", width: "200px" } },
  },
  {
    label: "Status",
    attribute: "is_active",
    styling: { dimensions: { width: "100px", maxWidth: "100px" } },
    render: (item) => (
      <Toggle
        checked={item?.is_active}
        onChange={async () => {
          if (!item) return;

          toggleStatus(item.id, !item.is_active);
        }}
        backgroundColorChecked="#2CD06E"
        backgroundColorUnchecked="#E0E0E0"
        width={28}
        height={16}
        sliderHeight={12}
        sliderWidth={12}
        translate={9}
      />
    ),
  },
  {
    label: "Action",
    styling: { dimensions: { width: "100px", maxWidth: "100px" } },
    render: (item) => (
      <Select
        transparent={true}
        placeholder={
          <Icon
            type={IconTypes.doner}
            color={theme.colors.disabled}
            size="18px"
          />
        }
        hasCarrot={false}
        options={[{
            label: "Update",
            value: "update"
        }]}
        closeOnSelectOption={true}
        enableHoverEffect={true}
        justifyContent="center"
        onTop={true}
        style={{
          valueWrapper: {
            dimensions: { width: "120px", height: "32px" },
          },
        }}
        setValue={(value: string) => {
          if(value === "update") {
            navigate(`/admin/templates/${item?.id}`)
            return;
          }
        }}
      />
    ),
  },
];
