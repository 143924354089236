import { parsePhoneNumber } from './../../models/lead/utilities/parsePhoneNumber';
export interface LongEmailProps {
  first_name: string;
  vehicle: string;
  origin_city: string;
  origin_state: string;
  origin_postal_code: string;
  destination_city: string;
  destination_state: string;
  destination_postal_code: string;
  agent_name: string;
  agent_number: string;
  agent_number_two: string;
  price: string;
}

export const LONG_EMAIL = `Hello FIRST_NAME,
Thank you for your quote request. I want to help you transport your VEHICLE from ORIGIN_CITY, ORIGIN_STATE (ORIGIN_POSTAL_CODE) to DESTINATION_CITY, DESTINATION_STATE (DESTINATION_POSTAL_CODE).
We have more than 20 truckers active on this route every week, 3 times per week.
        
The best auto transport experience GUARANTEED!


Here’s what we, Flix Auto Transport, will guarantee:
1) Door to door transport
2) Full coverage insurance
3) Personal items allowed
4) No down payment
5) No cancellation fees
6) 24/ 7 support
7) 100% best dispatch team in the country
8) 100% rated truckers only!
9) Online tracking of your vehicle
10) All inclusive shipment!

Flix Auto Transport shipping process is simple:
1. You book your shipment
2. We will call you once your trucker has been dispatched
3. Trucker goes to your pick-up address
4. Trucker will deliver your vehicle
5. You pay on delivery

We can do this shipment for PRICE!

Tell me more about your schedule and pick-up window so I could possibly offer even a better deal!
Pick-up window flexibility is the most important factor.
Reply back with your pick-up window or you're free to call me at AGENT_NUMBER.

Best Regards,
AGENT_NAME
Transport Manager
AGENT_NUMBER_TWO - my direct phone number
(512) 583-6611 - main company line
Flix Auto Transport
`;
