import { useCallback, useEffect, useMemo, useState } from "react";
import { useModalProvider } from "../../../../../contexts";
import { getLastMonday } from "../utilities";
import { DateRangeValue } from "../../../../../shared/components/DateRange/types";
import { AgentStatistics } from "../../../../../models/statistics";
import { Table } from "../../components/Table";
import { theme } from "../../../../../constants";
import {
  Button,
  ButtonTypes,
  FlexColumn,
  FlexItem,
  FlexRow,
  Text,
  TitleXL,
} from "../../../../../shared/styled";
import { DateRangeFilterModal } from "../../../../../modals";
import {
  Icon,
  IconTypes,
  SelectOption,
} from "../../../../../shared/components";
import {
  toQueryParamDateTime,
  toReadableDateOnly,
} from "../../../../../utilities/datetime";
import {
  HttpAdminGetAgentStatisticsResponse,
  httpAdminGetAgentStatistics,
  httpGetProviders,
} from "../../../../../apis/admin";
import toast from "react-hot-toast";
import { SearchSelect } from "../../../../../shared/components/Select/SearchSelect";
import { Provider } from "../../../../../models/provider";

export const AgentTable = () => {
  const { openModal, closeModal } = useModalProvider();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [order, setOrder] = useState<string[]>([]);
  const [limit, setlimit] = useState<number>(10);
  const [offset, setoffset] = useState<number>(0);
  const [total, settotal] = useState<number>();

  const [createdAtRange, setCreatedAtRange] = useState<DateRangeValue>({
    start: getLastMonday(),
    end: new Date(),
  });
  const [providerId, setProviderId] = useState<SelectOption | undefined>();

  const [data, setData] = useState<AgentStatistics[]>();
  const [providers, setProviders] = useState<Provider[]>();

  const fetchProviders = useCallback(async () => {
    try {
      const { data: fetchedProviders } = await httpGetProviders();
      setProviders(fetchedProviders as Provider[]);
    } catch (err) {
      toast.error("Failed to fetch providers list", {
        style: {
          border: `1px solid ${theme.colors.danger}`,
          padding: "16px",
          boxShadow: "none",
          borderRadius: "24px",
        },
        iconTheme: {
          primary: `${theme.colors.danger}`,
          secondary: `${theme.colors.clean}`,
        },
      });
      console.error(err);
    }
  }, []);

  const fetchData = useCallback(async () => {
    setLoading(true);
    if (!createdAtRange?.start || !createdAtRange?.end) {
      setLoading(false);
      return;
    }
    try {
      const agentResponse = await httpAdminGetAgentStatistics(
        offset,
        limit,
        order,
        createdAtRange?.start && createdAtRange?.end
          ? toQueryParamDateTime(createdAtRange.start)
          : undefined,
        createdAtRange?.start && createdAtRange?.end
          ? toQueryParamDateTime(createdAtRange.end, true)
          : undefined,
        providerId?.value
      );
      setData(
        (
          agentResponse.data as HttpAdminGetAgentStatisticsResponse
        )?.results.filter(
          (statistic) =>
            statistic.total_leads !== undefined && statistic.total_leads > 0
        )
      );
      settotal(
        (agentResponse?.data as HttpAdminGetAgentStatisticsResponse)?.count
      );
      setLoading(false);
    } catch (error) {
      toast.error("Error fetching Agents", {
        style: {
          border: `1px solid ${theme.colors.danger}`,
          padding: "16px",
          boxShadow: "none",
          borderRadius: "24px",
        },
        iconTheme: {
          primary: `${theme.colors.danger}`,
          secondary: `${theme.colors.clean}`,
        },
      });
      console.error(error);
    }
  }, [createdAtRange, providerId, offset, limit, order]);

  useEffect(() => {
    fetchProviders();
  }, []);

  useEffect(() => {
    fetchData();
  }, [order, limit, offset, createdAtRange, providerId]);

  const providerOptions = useMemo(
    () =>
      (providers ?? [])?.map((provider) => ({
        value: provider.id,
        label: (
          <FlexRow dimensions={{ width: "100%" }}>
            <Text color={theme.font.color}>{provider.name}</Text>
          </FlexRow>
        ),
        searchValue: provider.name,
      })),
    [providers]
  );

  return (
    <Table<AgentStatistics>
      loading={isLoading}
      header={(value) => true}
      title="Agent Statistics"
      tableShadow={`0px 4px 4px 0px ${theme.shadow.color}`}
      pagination={{
        limit: limit,
        setLimit: setlimit,
        offset: offset,
        setOffset: setoffset,
        total: total,
      }}
      orders={{
        orderableFields: [
          "id",
          "first_name",
          "total_leads",
          "claimed_value",
          "bad_value",
          "future_value",
          "hot_value",
          "potential_value",
          "booked_value",
        ],
        setOrder: setOrder,
        value: order,
      }}
      columns={[
        {
          label: "No",
          key: "id",
          styling: { maxWidth: "100%" },
          mapper: (item) => item?.agent?.id || <>-</>,
        },
        {
          label: "Name",
          key: "first_name",
          styling: { maxWidth: "100%" },
          mapper: (item) =>
            `${item?.agent?.first_name} ${item?.agent?.last_name}`,
        },
        {
          label: "Claimed",
          key: "total_leads",
          styling: { maxWidth: "100%" },
          mapper: (item) => item?.total_leads || <>-</>,
        },
        {
          label: "Gray",
          key: "claimed_value",
          styling: { maxWidth: "100%" },
          mapper: (item) => (
            <FlexColumn
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              gap={"4px"}
            >
              <div>{item?.claimed_value || <>-</>}</div>
              {item?.claimed_value && item?.total_leads ? (
                <div
                  style={{
                    backgroundColor: theme.colors.secondary,
                    width: "100%",
                    height: "1px",
                  }}
                />
              ) : (
                <></>
              )}
              <div>
                {item?.claimed_value && item?.total_leads ? (
                  `${((item.claimed_value / item.total_leads) * 100).toFixed(
                    2
                  )}%`
                ) : (
                  <></>
                )}
              </div>
            </FlexColumn>
          ),
        },
        {
          label: "Bad",
          key: "bad_value",
          styling: { maxWidth: "100%" },
          mapper: (item) => (
            <FlexColumn
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              gap={"4px"}
            >
              <div>{item?.bad_value || <>-</>}</div>
              {item?.bad_value && item?.total_leads ? (
                <div
                  style={{
                    backgroundColor: theme.colors.secondary,
                    width: "100%",
                    height: "1px",
                  }}
                />
              ) : (
                <></>
              )}
              <div>
                {item?.bad_value && item?.total_leads ? (
                  `${((item.bad_value / item.total_leads) * 100).toFixed(2)}%`
                ) : (
                  <></>
                )}
              </div>
            </FlexColumn>
          ),
        },
        {
          label: "Future",
          key: "future_value",
          styling: { maxWidth: "100%" },
          mapper: (item) => (
            <FlexColumn
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              gap={"4px"}
            >
              <div>{item?.future_value || <>-</>}</div>
              {item?.future_value && item?.total_leads ? (
                <div
                  style={{
                    backgroundColor: theme.colors.secondary,
                    width: "100%",
                    height: "1px",
                  }}
                />
              ) : (
                <></>
              )}
              <div>
                {item?.future_value && item?.total_leads ? (
                  `${((item.future_value / item.total_leads) * 100).toFixed(
                    2
                  )}%`
                ) : (
                  <></>
                )}
              </div>
            </FlexColumn>
          ),
        },
        {
          label: "Hot",
          key: "hot_value",
          styling: { maxWidth: "100%" },
          mapper: (item) => (
            <FlexColumn
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              gap={"4px"}
            >
              <div>{item?.hot_value || <>-</>}</div>
              {item?.hot_value && item?.total_leads ? (
                <div
                  style={{
                    backgroundColor: theme.colors.secondary,
                    width: "100%",
                    height: "1px",
                  }}
                />
              ) : (
                <></>
              )}
              <div>
                {item?.hot_value && item?.total_leads ? (
                  `${((item.hot_value / item.total_leads) * 100).toFixed(2)}%`
                ) : (
                  <></>
                )}
              </div>
            </FlexColumn>
          ),
        },
        {
          label: "Potential",
          key: "potential_value",
          styling: { maxWidth: "100%" },
          mapper: (item) => (
            <FlexColumn
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              gap={"4px"}
            >
              <div>{item?.potential_value || <>-</>}</div>
              {item?.potential_value && item?.total_leads ? (
                <div
                  style={{
                    backgroundColor: theme.colors.secondary,
                    width: "100%",
                    height: "1px",
                  }}
                />
              ) : (
                <></>
              )}
              <div>
                {item?.potential_value && item?.total_leads ? (
                  `${((item.potential_value / item.total_leads) * 100).toFixed(
                    2
                  )}%`
                ) : (
                  <></>
                )}
              </div>
            </FlexColumn>
          ),
        },
        {
          label: "Booked",
          key: "booked_value",
          styling: { maxWidth: "100%" },
          mapper: (item) => (
            <FlexColumn
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              gap={"4px"}
            >
              <div>{item?.booked_value || <>-</>}</div>
              {item?.booked_value && item?.total_leads ? (
                <div
                  style={{
                    backgroundColor: theme.colors.secondary,
                    width: "100%",
                    height: "1px",
                  }}
                />
              ) : (
                <></>
              )}
              <div>
                {item?.booked_value && item?.total_leads ? (
                  `${((item.booked_value / item.total_leads) * 100).toFixed(
                    2
                  )}%`
                ) : (
                  <></>
                )}
              </div>
            </FlexColumn>
          ),
        },
      ]}
      data={data}
      headerDimensions={{ width: "100%", height: "72px" }}
      headerComponent={
        <FlexRow
          alignItems="center"
          justifyContent="flex-end"
          gap="5px"
          padding={{ top: "16px", bottom: "16px" }}
          dimensions={{ width: "100%" }}
        >
          <SearchSelect
            options={providerOptions}
            value={providerId}
            onChange={(value) => setProviderId(value ? value : undefined)}
            placeholder="Lead Provider"
            isClearable={true}
            isSearchable={true}
            transparent={true}
            iconColor={theme.font.color}
          />

          <Button
            styleType={ButtonTypes?.primary}
            dimensions={{ height: "46px" }}
            disabled={isLoading}
            onClick={() =>
              openModal("createdAtAgentStatisticsModal", DateRangeFilterModal, {
                setValue: setCreatedAtRange,
                value: createdAtRange,
                close: () => closeModal("createdAtAgentStatisticsModal"),
                title: <TitleXL>Created At Range</TitleXL>,
              })
            }
          >
            <FlexRow
              justifyContent="space-between"
              alignItems="center"
              gap="5px"
            >
              <Icon
                type={IconTypes?.calendar}
                size="20px"
                color={theme?.colors?.clean}
              />
              <FlexItem dimensions={{ flex: 1 }}>
                <Text color={theme?.colors?.clean} align="center">
                  {(!createdAtRange?.start || !createdAtRange?.end) && (
                    <>Created At Range</>
                  )}
                  {createdAtRange?.start && createdAtRange?.end && (
                    <>
                      {toReadableDateOnly(createdAtRange?.start)} -{" "}
                      {toReadableDateOnly(createdAtRange?.end)}
                    </>
                  )}
                </Text>
              </FlexItem>
            </FlexRow>
          </Button>
        </FlexRow>
      }
    />
  );
};
