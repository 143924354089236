import { Route } from "react-router-dom";

import { SANDBOX_ROUTES } from "./Sandbox";
import { Login } from "./Login";
import { AGENT_DASHBOARD_ROUTES } from "./Agent";
import { ADMIN_DASHBOARD_ROUTES } from "./Admin";
import { MapScreen } from "./Map";
import { GoogleOAuthProvider } from "./Provider";

export const AppRoutes = (
  <>
    {SANDBOX_ROUTES}
    <Route path="/login" Component={Login} />
    <Route path="/map" Component={MapScreen} />
    <Route path="/oauth/google" Component={GoogleOAuthProvider} />
    {AGENT_DASHBOARD_ROUTES}
    {ADMIN_DASHBOARD_ROUTES}
  </>
);
