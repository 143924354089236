import { AgentStatistics } from "../../../models/statistics";
import { httpGet } from "../../utilities";

export interface HttpAdminGetAgentStatisticsResponse {
  count: number;
  results: AgentStatistics[];
}

export const httpAdminGetAgentStatistics = async (
  offset?: number,
  limit?: number,
  order?: string[],
  createdAtStart?: string,
  createdAtEnd?: string,
  leadProviderId?: string | number
) => {
  const queryParams: Record<string, any> = {
    offset: offset,
    limit: limit,
    ordering: order,
  };

  if (createdAtStart) {
    queryParams.created_at__gte = createdAtStart;
  }

  if (createdAtEnd) {
    queryParams.created_at__lte = createdAtEnd;
  }

  if(leadProviderId) {
    queryParams.provider_id = leadProviderId; 
  }

  return httpGet<HttpAdminGetAgentStatisticsResponse | AgentStatistics[]>(
    `/admin/statistics/agent`,
    queryParams
  );
};
