import { User } from "../../../models/user";
import { httpGet } from "../../utilities";

export interface HttpAdminGetUsersResponse {
  count: number;
  results: User[];
}

export const httpAdminGetUsers = async (
  search?: string,
  offset?: number,
  limit?: number,
  order?: string[],
  filters?: any
) => {
  return httpGet<HttpAdminGetUsersResponse | User[]>(`/admin/users`, {
    search: search,
    offset: offset,
    limit: limit,
    ordering: order,
    ...filters,
  });
};
