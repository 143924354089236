import { useState } from "react";
import { Icon, IconTypes, Select } from "../../../../shared/components";
import { FlexColumn, FlexRow, Title } from "../../../../shared/styled";

interface State {
  color: string;
  type: string;
}

export const IconSection = () => {
  const [state, setState] = useState<State>({
    color: "black",
    type: IconTypes.addPerson,
  });

  return (
    <FlexRow dimensions={{ width: "100%", height: "100%" }}>
      <FlexColumn
        justifyContent="center"
        alignItems="center"
        dimensions={{ flex: 1 }}
      >
        <Icon type={state.type as any} color={state?.color} size="64px" />
      </FlexColumn>
      <FlexColumn gap="20px" dimensions={{ flex: 1 }}>
        <FlexColumn gap="5px">
          <Title>Color</Title>
          <input
            type="color"
            value={state?.color}
            onChange={(event) =>
              setState({ ...state, color: event?.currentTarget?.value })
            }
          />
        </FlexColumn>
        <FlexColumn gap="5px">
          <Title>Type</Title>
          <Select
            setValue={(value) => setState({ ...state, type: value })}
            closeOnSelectOption={true}
            options={Object.keys(IconTypes)?.map((item) => ({
              label: item,
              value: item,
            }))}
            value={state?.type}
          />
        </FlexColumn>
      </FlexColumn>
    </FlexRow>
  );
};
