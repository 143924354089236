export enum OperableType {
  inoperable,
  operable,
}

export interface VehicleSchema {
  id?: number;
  vehicle_inop?: OperableType;
  vehicle_make?: string;
  vehicle_model?: string;
  vehicle_type?: string | null;
  vehicle_model_year?: number;
  lead?: number;
  lead_id?: number;
}
