export interface LongSmsParams {
 first_name: string;
 vehicle: string;
 origin_city: string;
 origin_state: string;
 destination_city: string;
 destination_state: string;
 agent_name: string;
 agent_number: string;
 price: number;
}

export const LONG_SMS = `Hi FIRST_NAME, I see you want to transport your VEHICLE from ORIGIN_CITY, ORIGIN_STATE to DESTINATION_CITY, DESTINATION_STATE.
I have 20 trucks on that route and we run this route 3 times per week.
What’s included:
-full coverage insurance
-door to door service
-personal items allowed
-online tracking
-24/7 support
I sent an email with a quote of PRICE$ already.
What's your pickup schedule here?
        
AGENT_NAME ,
Transport Manager at Flix AT
AGENT_NUMBER
`;