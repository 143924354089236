import { SectionSchema } from "../types/sectionSchema";

import { SelectSection } from "./SelectSection";
import { SelectSectionDocumentation } from "./documentation";


export const SelectSectionSchema: SectionSchema = {
  component: SelectSection,
  documentation: SelectSectionDocumentation,
  label: "Select",
  id: "select"
}