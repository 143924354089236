import { Lead } from "../../models/lead";
import { httpGet } from "../utilities"

interface HttpGetLeadsResponse {
  count: number;
  results: Lead[];
}

export const httpGetLeads = async (
  offset: number | undefined,
  limit: number | undefined,
  order: string[],
  filters: { [key: string]: any }
) => {

  return httpGet<HttpGetLeadsResponse>(`/lead`, { offset: offset, limit: limit, ordering: order, ...filters })
}