import { useEffect, useState, useRef } from "react";
import { css, styled } from "styled-components";

import { colors, theme } from "../../constants";
import {
  Button,
  ButtonTypes,
  FlexColumn,
  FlexItem,
  FlexRow,
  Text,
  TitleXL,
} from "../../shared/styled";
import { PriorityMap } from "../../models/lead/priorities";
import { LeadFilters as LeadFiltersInterface } from "../../models/lead/types";
import {
  Icon,
  IconTypes,
} from "../../shared/components";
import { StyledBaseInput } from "../../shared/components/Input/StyledBaseInput";
import { useAuth, useModalProvider } from "../../contexts";
import { DateRangeFilterModal } from "../../modals";
import {
  toQueryParamDate,
  toQueryParamDateTime,
  toReadableDateOnly,
} from "../../utilities/datetime";
import { DateRangeValue } from "../../shared/components/DateRange/types";

const LeadFiltersWrapper = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;
  justify-content: space-between;

  box-sizing: border-box;
  padding: 16px 20px;

  * {
    transition: all 0.2s;
  }
`;

interface LeadFiltersProps {
  filters?: LeadFiltersInterface;
  setFilters?: (filters: LeadFiltersInterface) => void;
}

export const MyLeadFilters = ({ filters, setFilters }: LeadFiltersProps) => {
  const { currentUser } = useAuth();
  const { openModal, closeModal } = useModalProvider();

  const [priority, setPriority] = useState<number | undefined>();

  const [claimedByAgent, setClaimedByAgent] = useState<number | undefined>(currentUser?.id);

  const [search, setSearch] = useState<string>();

  const inputRef = useRef<HTMLInputElement | null>(null);
  const cursorPositionRef = useRef(0);

  const [createdAtRange, setCreatedAtRange] = useState<DateRangeValue>({});
  const [moveRange, setMoveRange] = useState<DateRangeValue>({});


  useEffect(() => {
    setFilters &&
      setFilters({
        ...filters,
        priority: priority,
        agent__id: claimedByAgent === -1 ? undefined : claimedByAgent,
        search: search,
        created_at__gte:
          createdAtRange?.start && createdAtRange?.end
            ? toQueryParamDateTime(createdAtRange?.start, false)
            : undefined,
        created_at__lte:
          createdAtRange?.start && createdAtRange?.end
            ? toQueryParamDateTime(createdAtRange?.end, true)
            : undefined,
        ship_date__gte:
          moveRange?.start && moveRange?.end
            ? toQueryParamDate(moveRange?.start)
            : undefined,
        ship_date__lte:
          moveRange?.start && moveRange?.end
            ? toQueryParamDate(moveRange?.end)
            : undefined,
      });
  }, [priority, claimedByAgent, search, createdAtRange, moveRange]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.setSelectionRange(cursorPositionRef.current, cursorPositionRef.current);
    }
  }, [search]);

  const handleInput = (event: React.FormEvent<HTMLInputElement>) => {
    const inputValue = (event.currentTarget as HTMLInputElement).value;
    const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9@.,\s]/g, '');

    cursorPositionRef.current = (event.currentTarget as HTMLInputElement).selectionStart || 0;

    setSearch(sanitizedValue);
  };


  return (
    <LeadFiltersWrapper>
      <FlexColumn gap="16px">
        <FlexRow margin={{ top: "auto" }} gap="10px">
          {Object.keys(PriorityMap).map((priorityKey) => (
            <Button
              border={{
                color:
                  priority !== undefined && `${priority}` === priorityKey
                    ? theme.font.color
                    : "transparent",
              }}
              background={PriorityMap[priorityKey as any]?.color}
              dimensions={{ width: "28px", height: "28px" }}
              onClick={() =>
                setPriority(
                  priority !== undefined && `${priority}` === priorityKey
                    ? undefined
                    : Number(priorityKey)
                )
              }
            />
          ))}
        </FlexRow>
      </FlexColumn>
      <FlexColumn gap="10px" dimensions={{ minWidth: "180px" }}>
        <StyledBaseInput
          placeholder="Search"
          dimensions={{ width: "100%", minHeight: "32px" }}
          type="text"
          value={search}
          onInput={handleInput}
          css={css`
    box-sizing: border-box;
  `} />
        <Button
          styleType={ButtonTypes?.primary}
          dimensions={{ height: "32px" }}
          onClick={() =>
            openModal("createdAtLeadFilterModal", DateRangeFilterModal, {
              setValue: setCreatedAtRange,
              value: createdAtRange,
              close: () => closeModal("createdAtLeadFilterModal"),
              title: <TitleXL>Created at Range</TitleXL>,
            })
          }
        >
          <FlexRow justifyContent="space-between" alignItems="center" gap="5px">
            <Icon
              type={IconTypes?.calendar}
              size="20px"
              color={colors?.white?.clean}
            />
            <FlexItem dimensions={{ flex: 1 }}>
              <Text color={colors?.white?.clean} align="center">
                {(!createdAtRange?.start || !createdAtRange?.end) && (
                  <>CREATED</>
                )}
                {createdAtRange?.start && createdAtRange?.end && (
                  <>
                    {toReadableDateOnly(createdAtRange?.start)} -{" "}
                    {toReadableDateOnly(createdAtRange?.end)}
                  </>
                )}
              </Text>
            </FlexItem>
          </FlexRow>
        </Button>
        <Button
          styleType={ButtonTypes?.primary}
          dimensions={{ height: "32px" }}
          onClick={() =>
            openModal("shipmentAtLeadFilterModal", DateRangeFilterModal, {
              setValue: setMoveRange,
              value: moveRange,
              close: () => closeModal("shipmentAtLeadFilterModal"),
              title: <TitleXL>Move Range</TitleXL>,
            })
          }
        >
          <FlexRow alignItems="center">
            <Icon
              type={IconTypes?.calendar}
              size="20px"
              color={colors?.white?.clean}
            />
            <FlexItem dimensions={{ flex: 1 }}>
              <Text align="center" color={colors?.white?.clean}>
                {(!moveRange?.start || !moveRange?.end) && <>MOVING</>}
                {moveRange?.start && moveRange?.end && (
                  <>
                    {toReadableDateOnly(moveRange?.start)} -{" "}
                    {toReadableDateOnly(moveRange?.end)}
                  </>
                )}
              </Text>
            </FlexItem>
          </FlexRow>
        </Button>
      </FlexColumn>
    </LeadFiltersWrapper>
  );
};
