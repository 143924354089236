
import CSS from 'csstype';
import styled from 'styled-components';

import { DimensionStyling, DimensionStylingProps, MarginStyling, MarginStylingProps, PaddingStyling, PaddingStylingProps } from '../../styling';


export interface FlexItemProps {
  flex?: number;
  padding?: PaddingStylingProps;
  dimensions?: DimensionStylingProps;
  margin?: MarginStylingProps;
  justifySelf?: CSS.Property.JustifySelf;
  alignSelf?: CSS.Property.AlignSelf;
  background?: CSS.Property.Background;
}

export const FlexItem = styled.div<FlexItemProps>`
  box-sizing: border-box;
  flex: ${({ flex }) => flex};

  justify-self: ${({justifySelf}) => justifySelf};
  align-self: ${({alignSelf}) => alignSelf};
  
  background: ${({background}) => background};

  ${({padding}) => PaddingStyling(padding ?? {})};
  ${({dimensions}) => DimensionStyling({...dimensions})};
  ${({margin}) => MarginStyling({...margin})};
`;
