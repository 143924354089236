import { colors } from "../../../constants";
import { Priorities } from "./enum";

interface PriorityMapItem {
  label: string;
  color: string;
  background: string;
}

export const PriorityMap: {[key: number]: PriorityMapItem} = {
  [Priorities.default]: {
    label: "Not set",
    color: colors.grey.geyser,
    background: colors.grey.geyserLight,
  },
  [Priorities.bad_lead]: {
    label: "Bad Lead",
    color: colors.pink.lavander,
    background: colors.pink.paleRose
  },
  [Priorities.future_lead]: {
    label: "Future Lead",
    color: colors.yellow.sweetCorn,
    background: colors.yellow.givry
  },
  [Priorities.hot_lead]: {
    label: "Hot Lead",
    color: colors.green.emerald,
    background: colors.green.aeroBlue
  },
  [Priorities.potential_lead]: {
    label: "Potential Lead",
    color: colors.orange.casablanca,
    background: colors.orange.frangipani
  },
  [Priorities.booked_lead]: {
    label: "Booked Lead",
    color: colors.blue.cornflower,
    background: colors.blue.frenchPass
  }
}