import { httpPost } from "../../utilities";

interface HttpExportLeadsResponse {
  file: string;
}

interface HttpExportLeadsFilters {
  created_at__gte?: Date;
  created_at__lte?: Date;
  ship_date__gte?: Date;
  ship_date__lte?: Date;
  priority__in?: number[];
  agent_id?: number;
}

export const httpExportLeads = async (filters: HttpExportLeadsFilters) => {
  return httpPost<HttpExportLeadsResponse>(`/admin/leads/export`, {...filters, });
};
