import React from "react";
import styled from "styled-components";

import { randomPick, randomRange } from "../../../utilities/random";

interface ParticleContainerStyleProps {
  bottom?: string;
  left?: string;
}

interface ParticleContainerProps extends ParticleContainerStyleProps {
  ParticleComponent: any;
  amount: number;
  animationVariation: string[];

  colorVariation?: string[];
  widthLimit?: {
    min: number;
    max: number;
  };
  heightLimit?: {
    min: number;
    max: number;
  };
  alphaLimit?: {
    min: number;
    max: number;
  };
  animationDelayLimit?: {
    min: number;
    max: number;
  };
}

const ParticleContainerStyle = styled.div<ParticleContainerStyleProps>`
  width: max-content;
  height: max-content;
  position: absolute;
  bottom: ${({ bottom }) => bottom ?? "0"};
  left: ${({ left }) => left ?? "0"};
`;

export const ParticleContainer = ({
  bottom,
  left,
  ParticleComponent,
  amount,
  colorVariation,
  widthLimit,
  heightLimit,
  alphaLimit,
  animationVariation,
  animationDelayLimit,
}: ParticleContainerProps) => {
  const [particles, setParticles] = React.useState<JSX.Element[]>([]);

  React.useState(() => {
    const tmpParticles: JSX.Element[] = [];
    for (let i = 0; i < amount; i++) {
      tmpParticles.push(
        <ParticleComponent
          {...{
            alpha: alphaLimit
              ? randomRange(alphaLimit.min, alphaLimit?.max)
              : 1,
            color:
              colorVariation && colorVariation?.length > 0
                ? colorVariation?.length == 1
                  ? colorVariation[0]
                  : randomPick(colorVariation)
                : "black",
            width: widthLimit
              ? randomRange(widthLimit.min, widthLimit?.max)
              : 8,
            height: heightLimit
              ? randomRange(heightLimit.min, heightLimit?.max)
              : 8,
            className: randomPick(animationVariation),
            animationDelay: animationDelayLimit
              ? `${randomRange(
                  animationDelayLimit.min,
                  animationDelayLimit?.max
                )}s`
              : "0s",
          }}
        />
      );
    }

    setParticles(tmpParticles);
  });

  return (
    <ParticleContainerStyle
      bottom={bottom}
      left={left}
      onAnimationEnd={(event) => {
        event.stopPropagation();
      }}
    >
      {particles}
    </ParticleContainerStyle>
  );
};
