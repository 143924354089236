import { LeadProviderStatistics } from "../../../models/statistics";
import { httpGet } from "../../utilities";

export const httpAdminGetLeadProviderStatistics = async (
  offset?: number,
  limit?: number,
  order?: string[],
  createdAtStart?: string,
  createdAtEnd?: string
) => {
  const queryParams: Record<string, any> = {
    offset: offset,
    limit: limit,
    ordering: order,
  };
  if (createdAtStart) queryParams.created_at__gte = createdAtStart;

  if (createdAtEnd) queryParams.created_at__lte = createdAtEnd;

  return httpGet<LeadProviderStatistics[]>(
    `/admin/statistics/provider`,
    queryParams
  );
};
