import React from "react";
import type CSS from "csstype";

import {
  BackgroundStylingProps,
  BorderStylingProps,
  DimensionStylingProps,
  MarginStylingProps,
  OverflowStylingProps,
  PaddingStylingProps,
} from "../../styling";

import { FlexContainer } from "./FlexContainer";

interface FlexRowProps {
  children: React.ReactNode;
  justifyContent?: CSS.Property.JustifyContent;
  alignItems?: CSS.Property.AlignItems;
  alignContent?: CSS.Property.AlignContent;
  gap?: CSS.Property.Gap;
  flexWrap?: CSS.Property.FlexWrap;
  dimensions?: DimensionStylingProps;
  overflow?: OverflowStylingProps | CSS.Property.Overflow;
  margin?: MarginStylingProps;
  padding?: PaddingStylingProps | CSS.Property.Padding;
  border?: BorderStylingProps;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLElement>;
  position?: CSS.Property.Position;
  background?: BackgroundStylingProps;
  onFocus?: () => void;
  onBlur?: () => void;
  onMouseLeave?: () => void;
  onMouseEnter?: () => void;
}

export const FlexRow = ({
  children,
  justifyContent,
  alignItems,
  alignContent,
  gap,
  dimensions,
  flexWrap,
  overflow,
  margin,
  padding,
  border,
  style,
  onClick,
  position,
  background,
  onFocus,
  onMouseEnter,
  onMouseLeave,
onBlur
}: FlexRowProps) => (
  <FlexContainer
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onFocus={onFocus}
    onBlur={onBlur}
    position={position}
    onClick={onClick}
    style={style}
    flexDirection="row"
    justifyContent={justifyContent}
    alignItems={alignItems}
    alignContent={alignContent}
    gap={gap}
    background={background}
    dimensions={dimensions}
    flexWrap={flexWrap}
    overflow={overflow}
    margin={margin}
    padding={padding}
    border={border}
  >
    {children}
  </FlexContainer>
);
