import type CSS from "csstype";
import { MouseEventHandler } from 'react';
import { useRef } from "react";

import styled from "styled-components";

export enum IconTypes {
  addPerson = "addPerson",
  arrowDown = "arrowDown",
  arrowLeft = "arrowLeft",
  arrowRight = "arrowRight",
  calendar = "calendar",
  call = "call",
  check = "check",
  checkmark = "checkmark",
  close = "close",
  dashboard = "dashboard",
  dashboardFilled = "dashboardFilled",
  doner = "doner",
  export = "export",
  eye = "eye",
  gear = "gear",
  group = "group",
  location = "location",
  money = "money",
  people = "people",
  plus = "plus",
  search = "search",
  trash = "trash",
  users = "users",
  car = "car",
  statistics = "statistics",
  emailSmsTemplate = "email-sms-template",
  filters = "filters"
}

interface IconStyleProps {
  color?: CSS.Property.Color;
  width?: CSS.Property.Width;
  height?: CSS.Property.Height;
  src?: string;
}

const IconStyle = styled.i<IconStyleProps>`
  display: block;
  width: ${({ width }) => width || '32px'};
  height: ${({ height }) => height || '32px'};

  &::before {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background: ${({ color }) => color || "black"};
    -webkit-mask-position-x: 0;
    -webkit-mask-position-y: 0;
    -webkit-mask-image: ${({ src }) => `url("${src}")`};
    mask-image: ${({ src }) => `url("${src}")`};
    mask-size: contain;
    mask-repeat: no-repeat;
    -webkit-mask-size: contain;
    -webkit-mask-repeat: no-repeat;
  }
`;

interface IconProps {
  type?: IconTypes;
  color?: CSS.Property.Color;
  size?: CSS.Property.Width;
  onClick?: MouseEventHandler<HTMLElement>;
}

const Icon = ({
  type,
  color,
  size,
  onClick,
}: IconProps) => {
  const ref = useRef<HTMLElement>(null);
  return (
    <IconStyle
      ref={ref}
      color={color}
      width={size}
      height={size}
      onClick={onClick}
      src={`/assets/icons/${type}.svg`}
    />
  );
};

export default Icon;
