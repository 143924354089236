import React from "react";
import styled from "styled-components";

import { Icon, IconTypes, ParticleContainer } from "../components";
import { SmokeParticle, SmokeParticleAnimations } from "./Smoke";

const CarAnimation = styled.i`
  position: absolute;
  width: max-content;
  height: max-content;
  z-index: 0;
  bottom: 0;
  transform-origin: right bottom;

  @-webkit-keyframes slide-right {
    0% {
      left: calc(0% - 64px);
    }
    100% {
      left: calc(100% - 20px - 28px);
    }
  }
  @keyframes slide-right {
    0% {
      left: calc(0% - 64px);
    }
    100% {
      left: calc(100% - 20px - 28px);
    }
  }

  @-webkit-keyframes crash {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(30deg);
    }
  }
  @keyframes crash {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(30deg);
    }
  }

  @keyframes crash {
    0% {
      transform: rotate(30deg);
    }
    100% {
      transform: rotate(0);
    }
  }

  @-webkit-keyframes crashed {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.8;
    }
    70% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes crashed {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.8;
    }
    70% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
    }
  }

  &.state-driving {
    -webkit-animation: slide-right 10s linear;
    animation: slide-right 10s linear;
  }

  &.state-crash-up {
    left: calc(100% - 20px - 28px);

    -webkit-animation: crash 0.5s;
    animation: crash 0.5s;
  }

  &.state-crashed {
    left: calc(100% - 20px - 28px);

    -webkit-animation: crashed 5s;
    animation: crashed 5s;
  }
`;

interface AnimatedCarProps {
  close?(): void;
}

const AnimatedCar = ({ close }: AnimatedCarProps) => {
  const [state, setState] = React.useState<string>("state-driving");

  return (
    <CarAnimation
      className={state}
      onAnimationEnd={(evt) => {
        if(!close) return;

        if (state === "state-driving") {
          setState("state-crash-up");
          return;
        }

        if (state === "state-crash-up") {
          setState("state-crashed");
          return;
        }
        
        close();
      }}
    >
      <Icon color="#0a3d99" type={IconTypes.car} />
      {state === "state-driving" && (
        <>
          <ParticleContainer
            bottom="10px"
            left="-3px"
            ParticleComponent={SmokeParticle}
            amount={10}
            animationVariation={SmokeParticleAnimations}
            colorVariation={[
              "#0a3d99",
              "#31579b",
              "#637799",
              "#a9b8d1",
              "#ced4df",
            ]}
            widthLimit={{ min: 4, max: 8 }}
            heightLimit={{ min: 4, max: 8 }}
            animationDelayLimit={{ min: 0.5, max: 1.5 }}
          />
        </>
      )}
      {state === "state-crashed" && (
        <>
          <ParticleContainer
            bottom="20px"
            left="20px"
            ParticleComponent={SmokeParticle}
            amount={5}
            animationVariation={SmokeParticleAnimations}
            colorVariation={[
              "#08317e",
              "#244275",
              "#435068",
              "#788294",
              "#9ea0a3",
            ]}
            widthLimit={{ min: 4, max: 8 }}
            heightLimit={{ min: 4, max: 8 }}
            animationDelayLimit={{ min: 0.5, max: 1.5 }}
          />
        </>
      )}
    </CarAnimation>
  );
};

export default AnimatedCar;
