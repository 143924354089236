import { useState } from "react";
import { theme } from "../../../../constants";
import { Icon, IconTypes, Select } from "../../../../shared/components";

const DUMMY_OPTIONS = [
  { label: "None", value: undefined },
  { label: "Plain String", value: "PLAIN_STRING" },
  {
    label: (
      <Icon type={IconTypes?.car} color={theme?.colors?.primary} size="32px" />
    ),
    value: "ICON",
  },
  { label: "This is disabled", value: "DISABLED", disabled: true },
];

export const SelectSection = () => {
  const [value, setValue] = useState<string>();

  return (
    <>
      <Select
        style={{
          valueWrapper: { dimensions: { width: "256px", height: "32px" } },
        }}
        placeholder="Select type"
        value={value}
        setValue={setValue}
        options={DUMMY_OPTIONS}
        closeOnSelectOption={false}
      />
    </>
  );
};
