export const toQueryParamDateTime = (datetime: Date, end = false): string => {
  const yearString = datetime.getFullYear();
  const monthString =
    datetime.getMonth() + 1 < 10
      ? `0${datetime.getMonth() + 1}`
      : datetime.getMonth() + 1;
  const dayString =
    datetime.getDate() < 10 ? `0${datetime.getDate()}` : datetime.getDate();
  const endString = !end ? "T00:00:00Z" : "T23:59:59Z";

  return `${yearString}-${monthString}-${dayString}${endString}`;
};
