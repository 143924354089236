import type CSS from 'csstype';

import React from 'react';

import {
  BackgroundStylingProps,
  DimensionStylingProps,
  MarginStylingProps,
  PaddingStylingProps,
  OverflowStylingProps,
  BorderStylingProps,
} from '../../styling';

import { FlexContainer } from './FlexContainer';
import { RuleSet } from 'styled-components';

export interface FlexColumnProps {
  children?: React.ReactNode;
  justifyContent?: CSS.Property.JustifyContent;
  alignItems?: CSS.Property.AlignItems;
  alignContent?: CSS.Property.AlignContent;
  gap?: CSS.Property.Gap;
  dimensions?: DimensionStylingProps;
  padding?: PaddingStylingProps | CSS.Property.Padding;
  margin?: MarginStylingProps;
  overflow?: OverflowStylingProps;
  background?: BackgroundStylingProps | CSS.Property.Background;
  border?: BorderStylingProps;
  style?: React.CSSProperties;
  id?: string;
  onClick?: () => void;
  css?: RuleSet<object>;
}

export const FlexColumn = ({
  children,
  justifyContent,
  alignItems,
  alignContent,
  gap,
  dimensions,
  padding,
  margin,
  overflow,
  background,
  style,
  border,
  id,
  onClick,
  css
}: FlexColumnProps) => (
  <FlexContainer
  css={css}
    onClick={onClick}
    id={id}
    flexDirection='column'
    style={style}
    justifyContent={justifyContent}
    alignItems={alignItems}
    alignContent={alignContent}
    gap={gap}
    dimensions={dimensions}
    padding={padding}
    margin={margin}
    overflow={overflow}
    background={background}
    border={border}
  >
    {children}
  </FlexContainer>
);
