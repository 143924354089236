import { SubmitHandler, useForm } from "react-hook-form";
import { colors, theme } from "../../../../../constants";
import { Icon, IconTypes } from "../../../../../shared/components";
import {
  Button,
  ButtonTypes,
  FlexColumn,
  FlexItem,
  FlexRow,
  Paragraph,
  Title,
  TitleXL,
} from "../../../../../shared/styled";
import { StyledBaseInput } from "../../../../../shared/components/Input/StyledBaseInput";
import { httpAdminUpdateUserAuth } from "../../../../../apis/admin";
import toast from "react-hot-toast";
import { User } from "../../../../../models/user";

interface FormData {
  email: string;
  emailConfirm: string;
  password: string;
  passwordConfirm: string;
}

interface UpdateUserAuthModalProps {
  user: User;
  close?: () => void;
}

export const UpdateUserAuthModal = ({
  user,
  close,
}: UpdateUserAuthModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const updateUserAuth = async (data: FormData) => {
    try {
      await httpAdminUpdateUserAuth(user?.id as number, {
        email: data.email,
        password: data.password,
      });
      toast.success("User auth updated",{
        style: {
          border: `1px solid ${theme.colors.success}`,
          padding: "16px",
          boxShadow: "none",
          borderRadius: "24px",
        },
        iconTheme: {
          primary: `${theme.colors.success}`,
          secondary: `${theme.colors.clean}`,
        },
      });
      close && close();
    } catch (err) {
      toast.error("Error updating user auth", {
        style: {
          border: `1px solid ${theme.colors.danger}`,
          padding: "16px",
          boxShadow: "none",
          borderRadius: "24px",
        },
        iconTheme: {
          primary: `${theme.colors.danger}`,
          secondary: `${theme.colors.clean}`,
        },
      });
      console.log(err);
    }
  };

  return (
    <FlexColumn
      background={colors?.white?.clean}
      dimensions={{ minWidth: "300px", minHeight: "300px" }}
      padding="24px"
      gap="20px"
      border={{ radius: "24px" }}
    >
      <FlexRow justifyContent="space-between">
        <FlexItem dimensions={{ flex: 1 }}>
          <TitleXL>Update user auth</TitleXL>
        </FlexItem>
        <Button onClick={() => close && close()}>
          <Icon
            size="16px"
            type={IconTypes.close}
            color={colors?.white?.clean}
          />
        </Button>
      </FlexRow>

      <form
        onSubmit={handleSubmit(updateUserAuth)}
        style={{ display: "flex", flexDirection: "column", gap: "10px" }}
      >
        <FlexColumn gap="8px" dimensions={{ width: "100%" }}>
          <Title text-align="left" color={theme.font.color}>
            Email
          </Title>
          <StyledBaseInput
            type="text"
            autoComplete="off"
            dimensions={{ width: "100%", height: "24px" }}
            placeholder="Enter email..."
            defaultValue={user?.email}
            {...register("email", {
              required: "This field is required",
              validate: (value, data) =>
                value === data.emailConfirm ? true : "Emails are not matching!",
            })}
          />
          {errors?.email?.message && (
            <Paragraph color={theme.colors.danger}>
              {errors.email.message}
            </Paragraph>
          )}
        </FlexColumn>
        <FlexColumn gap="8px" dimensions={{ width: "100%" }}>
          <Title text-align="left" color={theme.font.color}>
            Confirm Email
          </Title>
          <StyledBaseInput
            type="text"
            autoComplete="off"
            dimensions={{ width: "100%", height: "24px" }}
            placeholder="Confirm Email..."
            defaultValue={user?.email}
            {...register("emailConfirm", {
              required: "This field is required",
              validate: (value, data) =>
                value === data.email ? true : "Emails are not matching!",
            })}
          />
          {errors?.emailConfirm?.message && (
            <Paragraph color={theme.colors.danger}>
              {errors.emailConfirm.message}
            </Paragraph>
          )}
        </FlexColumn>
        <FlexColumn gap="8px" dimensions={{ width: "100%" }}>
          <Title text-align="left" color={theme.font.color}>
            Password
          </Title>
          <StyledBaseInput
            type="password"
            autoComplete="new-password"
            dimensions={{ width: "100%", height: "24px" }}
            placeholder="Enter password..."
            {...register("password", {
              required: "This field is required",
              validate: (value, data) =>
                value === data.passwordConfirm
                  ? true
                  : "Passwords are not matching!",
            })}
          />
          {errors.password?.message && (
            <Paragraph color={theme.colors.danger}>
              {errors.password.message}
            </Paragraph>
          )}
        </FlexColumn>
        <FlexColumn gap="8px" dimensions={{ width: "100%" }}>
          <Title text-align="left" color={theme.font.color}>
            Confirm Password
          </Title>
          <StyledBaseInput
            type="password"
            autoComplete="new-password"
            dimensions={{ width: "100%", height: "24px" }}
            placeholder="Confirm password..."
            {...register("passwordConfirm", {
              required: "This field is required",
              validate: (value, data) =>
                value === data.password ? true : "Passwords are not matching!",
            })}
          />
          {errors?.passwordConfirm?.message && (
            <Paragraph color={theme.colors.danger}>
              {errors.passwordConfirm.message}
            </Paragraph>
          )}
        </FlexColumn>
        <FlexRow justifyContent="flex-end" gap="10px">
          <Button
            onClick={() => close && close()}
            styleType={ButtonTypes.primaryReverse}
          >
            Cancel
          </Button>
          <Button type="submit" styleType={ButtonTypes.primary}>
            Submit
          </Button>
        </FlexRow>
      </form>
    </FlexColumn>
  );
};
