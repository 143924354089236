import { Map } from "../../../../shared/components/Map";

export const MapSection = () => {
  return (
    <Map
      originCoords={[-89.53055, 38.35657]}
      destinationCoords={[-87.85644, 37.52745]}
      styling={{ wrapper: { dimensions: { width: '100%', height: "100%" } } }}
    />
  );
};
