import { useEffect, useState } from "react";
import { FlexColumn, FlexRow } from "../../styled";
import {
 DECEMBER_INDEX,
 JANUARY_INDEX,
 MonthIndex,
 WEEK_DAYS_NAMES,
} from "./constants";
import { createDate, getMonthInfo, isDateInRange } from "./utilities";
import { useGlobalEvents } from "../../../contexts/GlobalEventProvider";

import { ArrowButton, DateButton, DateRangeWrapper, Value } from "./components";
import { DateRangeValue } from "./types";

interface DateRangeInterface {
 value?: DateRangeValue;
 setValue?: (value: DateRangeValue) => void;
}

const NUMBER_OF_BUTTONS = 42;

export const DateRange = ({ value, setValue }: DateRangeInterface) => {
 const { setKeyPressHandler } = useGlobalEvents();

 const [year, setYear] = useState<number>(new Date()?.getFullYear());
 const [month, setMonth] = useState<MonthIndex>(
  new Date()?.getMonth() as MonthIndex
 );

 const [isSelecting, setSelecting] = useState<boolean>(false);

 const [intervalValue, setInternalValue] = useState<DateRangeValue>({});

 const previusMonthInfo = getMonthInfo(
  (month - 1 < 0 ? 11 : month - 1) as MonthIndex,
  year
 );
 const monthInfo = getMonthInfo(month, year);

 const previusMonth = () => {
  if (month === 0) {
   setYear(year - 1);
   setMonth(DECEMBER_INDEX);
   return;
  }

  setMonth((month - 1) as MonthIndex);
 };

 const nextMonth = () => {
  if (month === 11) {
   setYear(year + 1);
   setMonth(JANUARY_INDEX);
   return;
  }

  setMonth((month + 1) as MonthIndex);
 };

 useEffect(() => {
  setKeyPressHandler &&
   setKeyPressHandler((keys) => {
    if (isSelecting && keys?.ArrowLeft) previusMonth();
    if (isSelecting && keys?.ArrowRight) nextMonth();
   });

  return () => {
   setKeyPressHandler && setKeyPressHandler(undefined);
  };
 }, [isSelecting, year, month]);

 return (
  <DateRangeWrapper>
   <FlexColumn gap="10px" padding={{ left: "24px", right: "24px" }}>
    <FlexRow justifyContent="space-between">
     <Value>{monthInfo?.name}</Value>
     <FlexRow gap="10px">
      <ArrowButton arrow="left" onClick={previusMonth} />
      <ArrowButton arrow="right" onClick={nextMonth} />
     </FlexRow>
    </FlexRow>
    <FlexRow justifyContent="space-between">
     <Value>{year}</Value>
     <FlexRow gap="10px">
      <ArrowButton arrow="left" onClick={() => setYear(year - 1)} />
      <ArrowButton arrow="right" onClick={() => setYear(year + 1)} />
     </FlexRow>
    </FlexRow>
   </FlexColumn>
   <FlexRow>
    {Object.keys(WEEK_DAYS_NAMES)?.map((key) => (
     <span style={{ width: "48px", textAlign: 'center' }}>
      {((WEEK_DAYS_NAMES as any)[key] as string)?.slice(0, 3)}
     </span>
    ))}
   </FlexRow>
   <FlexRow flexWrap="wrap" dimensions={{ width: `${48 * 7}px` }}>
    {Array(NUMBER_OF_BUTTONS)
     ?.fill(null)
     ?.map((_, index) => {
      const isInMonthRange =
       monthInfo?.startDay <= index &&
       index + 1 - monthInfo?.startDay <= monthInfo?.numberOfDays;

      const date = (() => {
       if (isInMonthRange)
        return createDate(year, month, index - monthInfo?.startDay + 1);

       // Case when index in previus month
       if (index < monthInfo?.startDay)
        return createDate(
         JANUARY_INDEX <= month - 1 ? year : year - 1,
         JANUARY_INDEX <= month - 1 ? month - 1 : DECEMBER_INDEX,
         previusMonthInfo?.numberOfDays -
         monthInfo?.startDay +
         index +
         1
        );

       // Case when index is in next month
       return createDate(
        month + 1 <= DECEMBER_INDEX ? year : year + 1,
        month + 1 <= DECEMBER_INDEX ? month + 1 : JANUARY_INDEX,
        index - monthInfo?.numberOfDays - monthInfo?.startDay + 1
       );
      })();

      const startDate =
       intervalValue?.start &&
        intervalValue?.end &&
        intervalValue?.start < intervalValue?.end
        ? intervalValue?.start
        : intervalValue?.end;
      const endDate =
       intervalValue?.start &&
        intervalValue?.end &&
        intervalValue?.start < intervalValue?.end
        ? intervalValue?.end
        : intervalValue?.start;

      const isInIntervalValueRange =
       intervalValue?.start &&
       isDateInRange(
        startDate as Date,
        endDate || (startDate as Date),
        date
       );

      const isValueInRange =
       value?.start &&
       value?.end &&
       isDateInRange(value?.start, value?.end, date);

      return (
       <DateButton
        dimensions={{ width: "48px", height: "48px" }}
        key={`${index}${date.toString()}`}
        isStart={intervalValue?.start === date || value?.start === date}
        isEnd={intervalValue?.end === date || value?.end === date}
        type={
         isInMonthRange
          ? "in"
          : index < monthInfo?.startDay
           ? "before"
           : "after"
        }
        isSelected={
         isSelecting ? isInIntervalValueRange : isValueInRange
        }
        isSelecting={isSelecting}
        onClick={() => {
         if (!isSelecting) {
          setInternalValue({ start: date, end: date });
          setSelecting(true);
          return;
         }

         setValue && setValue({ start: startDate, end: endDate });
         setInternalValue({ start: undefined, end: undefined });
         setSelecting(false);
        }}
        onMouseEnter={() =>
         isSelecting &&
         setInternalValue({ ...intervalValue, end: date })
        }
       >
        {/* Case when we are in month day index range */}
        {isInMonthRange && <>{index - monthInfo?.startDay + 1}</>}

        {!isInMonthRange && (
         <>
          {/* Case when we are in previus month range */}
          {index < monthInfo?.startDay && (
           <>
            {previusMonthInfo?.numberOfDays -
             monthInfo?.startDay +
             index +
             1}
           </>
          )}

          {/* Case when we are in next month range */}
          {monthInfo?.numberOfDays <
           index + 1 - monthInfo?.startDay && (
            <>
             {index -
              monthInfo?.numberOfDays -
              monthInfo?.startDay +
              1}
            </>
           )}
         </>
        )}
       </DateButton>
      );
     })}
   </FlexRow>
  </DateRangeWrapper>
 );
};
