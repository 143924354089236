import { httpPost } from "../../utilities";


export interface HttpAdminUpdateUserAuthData {
  email?: string;
  password?: string;
}

export const httpAdminUpdateUserAuth = async (id: string | number, data: HttpAdminUpdateUserAuthData) => {
  return httpPost(`/admin/users/${id}/auth`, data)
}
