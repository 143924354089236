import { httpGet } from "../utilities"

interface HttpGetTotalLeadsResponse {
  total: number;
}

export const httpGetTotalLeads = async (
  id: number,
) => {
  return httpGet<HttpGetTotalLeadsResponse>(`/lead/total/${id}`)
}
