import React from "react";
import type CSS from "csstype";

import {
    BackgroundStylingProps,
    BorderStylingProps,
    DimensionStylingProps,
    MarginStylingProps,
    OverflowStylingProps,
    PaddingStylingProps,
} from "../../styling";

import { FlexContainer } from "../flex-box";

interface FlexRowProps {
    children: React.ReactNode;
    justifyContent?: CSS.Property.JustifyContent;
    alignItems?: CSS.Property.AlignItems;
    alignContent?: CSS.Property.AlignContent;
    gap?: CSS.Property.Gap;
    flexWrap?: CSS.Property.FlexWrap;
    dimensions?: DimensionStylingProps;
    overflow?: OverflowStylingProps | CSS.Property.Overflow;
    margin?: MarginStylingProps;
    padding?: PaddingStylingProps | CSS.Property.Padding;
    border?: BorderStylingProps;
    style?: React.CSSProperties;
    onClick?: React.MouseEventHandler<HTMLElement>;
    position?: CSS.Property.Position;
    background?: BackgroundStylingProps;
    flexDirection?: CSS.Property.FlexDirection;
}

export const Card = ({
    children,
    justifyContent,
    alignItems,
    alignContent,
    gap,
    dimensions,
    flexWrap,
    overflow,
    margin,
    padding,
    border,
    style,
    onClick,
    position,
    background,
    flexDirection,
}: FlexRowProps) => (
    <FlexContainer
        position={position}
        onClick={onClick}
        style={style}
        flexDirection={flexDirection}
        justifyContent={justifyContent}
        alignItems={alignItems}
        alignContent={alignContent}
        gap={gap}
        background={background}
        dimensions={dimensions}
        flexWrap={flexWrap}
        overflow={overflow}
        margin={margin}
        padding={padding}
        border={border}
    >
        {children}
    </FlexContainer>
);
