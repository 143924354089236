import { useForm } from "react-hook-form";

import { theme } from "../../constants";
import {
  Button,
  FlexColumn,
  Text,
  TitleXL,
  TitleXXL,
} from "../../shared/styled";
import { StyledBaseInput } from "../../shared/components/Input/StyledBaseInput";
import { useAuth } from "../../contexts";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

interface LoginForm {
  email: string;
  password: string;
}

export const Login = () => {
  const { login, currentUser} = useAuth();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginForm>();

  const submit = async (data: LoginForm) => {
    await login(data.email, data.password);

  };

  useEffect(()=>{
    if(currentUser) navigate("/agent/leads");
  },[currentUser])

  return (
    <form onSubmit={handleSubmit(submit)}>
      <FlexColumn
        padding="16px"
        dimensions={{ width: "100vw", height: "100vh" }}
        gap="10px"
        justifyContent="center"
        alignItems="center"
      >
        <TitleXXL align="center" color={theme.colors.primary} size="60px">
          Log in
        </TitleXXL>
        <TitleXL align="center" color={theme.colors.primary} size="28px">
          Flix Auto Transport
        </TitleXL>

        <FlexColumn
          dimensions={{ maxWidth: "320px", width: "100%" }}
          gap="15px"
        >
          <StyledBaseInput
            placeholder="Enter your email"
            valid={!Object.keys(errors)?.includes("email")}
            border={{ radius: "10px" }}
            dimensions={{ height: "25px" }}
            type="text"
            {...register("email", { required: true, pattern: /[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$/i })}
          />
          <StyledBaseInput
            placeholder="Enter your password"
            border={{ radius: "10px" }}
            dimensions={{ height: "25px" }}
            type="password"
            valid={!Object.keys(errors)?.includes("password")}
            {...register("password", { required: true })}
          />
          <FlexColumn gap="5px">
            {Object.keys(errors).map((key) => {
              return (
                <Text
                  color={theme.colors.danger}
                  cursor="pointer"
                  onClick={() =>
                    ((errors as any)?.[key].ref as HTMLElement).focus()
                  }
                >
                  {(errors as any)?.[key]?.type === "required" && (
                    <>{key} is required</>
                  )}
                  {(errors as any)?.[key]?.type === "pattern" && (
                    <>{key} is invalid</>
                  )}
                </Text>
              );
            })}
          </FlexColumn>
          <Button
            type="submit"
            dimensions={{ height: "32px" }}
            border={{ radius: "8px" }}
            disabled={0 < Object.keys(errors)?.length}
          >
            Log In
          </Button>
        </FlexColumn>
      </FlexColumn>
    </form>
  );
};
