import { Lead } from "../models/lead";
import { LeadFilters } from "../models/lead/types";
import { User } from "../models/user";
import { Pagination } from "../types";
import {
  leadClaimAction,
  leadRoutesAction,
  leadRoutesAppendAction,
  leadUnclaimAction,
  leadUpdateAction,
  newCallEventAction,
  newEventAction,
  newLeadAction,
  newNoteAction,
} from "./actions";

import {
  ActionGeneric,
  LEAD_CLAIM_ACTION_TYPE,
  LEAD_ROUTES_ACTION_TYPE,
  LEAD_ROUTES_APPEND_ACTION_TYPE,
  LEAD_UNCLAIM_ACTION_TYPE,
  LEAD_UPDATE_ACTION_TYPE,
  NEW_CALL_EVENT_ACTION,
  NEW_EVENT_ACTION_TYPE,
  NEW_LEAD_ACTION_TYPE,
  NEW_NOTE_ACTION_TYPE,
} from "./types";

export const EVENTS = {
  [LEAD_CLAIM_ACTION_TYPE]: leadClaimAction,
  [LEAD_ROUTES_ACTION_TYPE]: leadRoutesAction,
  [LEAD_UNCLAIM_ACTION_TYPE]: leadUnclaimAction,
  [LEAD_UPDATE_ACTION_TYPE]: leadUpdateAction,
  [NEW_EVENT_ACTION_TYPE]: newEventAction,
  [NEW_LEAD_ACTION_TYPE]: newLeadAction,
  [NEW_NOTE_ACTION_TYPE]: newNoteAction,
  [LEAD_ROUTES_APPEND_ACTION_TYPE]: leadRoutesAppendAction,
  [NEW_CALL_EVENT_ACTION]: newCallEventAction,
};

export const websocketEventPiepeline = (
  currentUser: User,
  message: ActionGeneric<string, any>,
  setLeads: (leads: Lead[]) => void,
  leads: Lead[],
  filters?: LeadFilters,
  pagination?: Pagination,
  setPagination?: (pagination: Pagination) => void
) => {
  for (const rawKey in EVENTS) {
    const typedKey = rawKey as keyof typeof EVENTS;
    if (message?.type === typedKey) {
      EVENTS[typedKey](
        currentUser,
        message.data,
        setLeads,
        leads,
        filters,
        pagination,
        setPagination
      );
      return;
    }
  }
};
