import { Lead } from "../../../models/lead";

export const serializeLead = (lead: Lead) => ({
  "lead.id": `${lead.id}`,
  "lead.first_name": lead.first_name,
  "lead.last_name": lead.last_name,
  "lead.contact.email": lead.email,
  "lead.contact.phone": lead.phone,
  "lead.origin.city": lead.origin_city,
  "lead.origin.state": lead.origin_state,
  "lead.origin.postal_code": lead.origin_postal_code,
  "lead.destination.city": lead.destination_city,
  "lead.destination.state": lead.destination_state,
  "lead.destination.postal_code": lead.destination_postal_code,
  "lead.ship_date": !lead.ship_date
    ? "UNDEFINED"
    : (() => {
        let target = lead.ship_date;
        if (typeof lead.ship_date === "string")
          target = new Date(lead.ship_date);
        const typedTarget: Date = target as Date;

        return `${typedTarget.getDate()}-${
          typedTarget.getMonth() + 1
        }-${typedTarget.getFullYear()}`;
      })(),
  "lead.vehicle.make": lead.vehicles?.[0].vehicle_make,
  "lead.vehicle.model": lead.vehicles?.[0].vehicle_model,
  "lead.vehicle.year": `${lead.vehicles?.[0].vehicle_model_year}`,
  "lead.price": `${lead?.events_map?.latest_price?.pricing?.price}`,
});
