import { UIEvent, useEffect, useRef, useState } from "react";

import { Loading, Scroll } from "../../../shared/components";
import { Lead } from "../../../models/lead";
import { websocketConnect, websocketEventPiepeline } from "../../../realtime";
import { ActionGeneric } from "../../../realtime/types";
import { Pagination } from "../../../types";
import { httpGetLeads } from "../../../apis/leads";
import { LeadCard } from "./components";
import { useAuth } from "../../../contexts";
import { MyLeadFilters } from "../../components";
import { User } from "../../../models/user";
import { useStateRef } from "../../../hooks";

export const MyLeads = () => {
 const { currentUser } = useAuth();

 const [scrollTrigger, setScrollTrigger] = useState<UIEvent<HTMLDivElement, globalThis.UIEvent>>();

 const [leads, setLeads] = useState<Lead[]>();
 const [isLoadingRef, isLoading, setIsLoading] = useStateRef<boolean>(false);

 const [initialLoad, setInitialLoad] = useState<boolean>(true);

 const [filters, setFilters] = useState({});
 const [initialFilters, setInitialFilters] = useState({
  agent__id: currentUser?.id,
 });

 const [pagination, setPagination] = useState<Pagination>({
  offset: 0,
  limit: 10,
 });

 const rtConnectionInitiated = useRef<boolean>();
 const [rtConnection, setRtConnection] = useState<WebSocket>();
 const [action, setAction] = useState<ActionGeneric<string, any>>();

 // Fetch leads by filters
 const fetchLeads = async (reset = false) => {
  const currentPagination: Pagination = {
   offset: reset ? 0 : pagination?.offset,
   limit: pagination?.limit,
  };

  if (isLoadingRef.current) {
   return;
  }

  setIsLoading(true);
  try {
   let response;
   if (initialLoad) {
    response = await httpGetLeads(currentPagination?.offset, currentPagination?.limit, ["-created_at"], initialFilters);
   } else {
    response = await httpGetLeads(currentPagination?.offset, currentPagination?.limit, ["-created_at"], filters);
   }
   currentPagination.count = response?.data?.count;
   currentPagination.offset += currentPagination?.limit;

   if (reset) setLeads(response?.data.results);
   else setLeads([...(leads || []), ...response?.data.results]);
  } catch (err) {}

  setInitialLoad(false);
  setIsLoading(false);

  setPagination(currentPagination);
 };

 const realtimeCreate = () => {
  setRtConnection(websocketConnect(setAction));
  rtConnectionInitiated.current = true;
 };

 useEffect(() => {
  /* Handle realtime communication events */
  action &&
   websocketEventPiepeline(currentUser as User, action, setLeads, leads || [], filters, pagination, setPagination);
 }, [action]);

 useEffect(() => {
  !rtConnectionInitiated.current && realtimeCreate();
  return () => {
   /* Close realtime connection */
   rtConnection && rtConnection?.close(200);
  };
 }, []);

 useEffect(() => {
  fetchLeads(true);
 }, [filters]);

 return (
  <>
   <MyLeadFilters filters={filters} setFilters={setFilters} />
   <Scroll
    wrapperStyle={{ dimensions: { flex: 1 } }}
    isLoading={isLoading}
    loadNext={fetchLeads}
    LoadingComponent={Loading}
    onScrollHandler={(event) => event && setScrollTrigger(event)}
   >
    {(leads || [])?.map((lead) => (
     <LeadCard key={`lead-card-${lead?.id}`} data={lead} scrollData={scrollTrigger} />
    ))}
   </Scroll>
  </>
 );
};
