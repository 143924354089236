import { SectionSchema } from "../types/sectionSchema";
import { DateRangeSection } from "./DateRange";

import { DateRangeDocumentation } from "./documentation";


export const DateRangeSchema: SectionSchema = {
  component: DateRangeSection,
  documentation: DateRangeDocumentation,
  label: "DateRange",
  id: "dateRange"
}