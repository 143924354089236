import { httpMicroservicePushMessage } from "./microservicePushMessage";

export const httpMsPushEmail = async (
  subject: string,
  content: string,
  sender_email: string,
  sender_name: string,
  receivers: string[],
  cc_email: string[],
  reply_to: string[]
) => {
  return httpMicroservicePushMessage("tasks/email/send_email.py:send_email", {
    subject,
    content,
    sender_email,
    sender_name,
    receivers,
    cc_email,
    reply_to,
    tags: [],
  });
};
