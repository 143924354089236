import { Lead } from "../../models/lead";
import { LeadFilters } from "../../models/lead/types";
import { User } from "../../models/user";
import { Pagination } from "../../types";
import { LeadUpdateAction } from "../types";

export const leadUpdateAction = (
  currentUser: User,
  data: LeadUpdateAction["data"],
  setLeads: (leads: Lead[]) => void,
  leads: Lead[],
  //TODO Create Interface for filters
  filters?: LeadFilters,
  pagination?: Pagination,
  setPagination?: (pagination: Pagination) => void
) => {
  if (Object.keys(filters || {})?.length) {
    /* Dont appned if does not belong to agent */
    if (filters?.agent__id && filters?.agent__id !== currentUser?.id) return;
    if (
      ![null, undefined].includes(filters?.priority as any) &&
      data?.priority !== filters?.priority
    )
      return;
    if(filters?.search && !`${data?.first_name}${data?.last_name}${data?.email}${data.phone}`.includes(filters?.search))
      return;
  }

  setLeads(
    leads.map((lead) => {
      if (data.id !== lead.id) return lead;

      return {
        ...lead,
        ...data,
      };
    })
  );
};
