export const SUNDAY_INDEX = 0;
export const MONDAY_INDEX = 1;
export const TUESDAY_INDEX = 2;
export const WEDNESDAY_INDEX = 3;
export const THURSDAY_INDEX = 4;
export const FRIDAY_INDEX = 5;
export const SATURDAY_INDEX = 6;

export const WEEK_DAYS_NAMES = {
  [MONDAY_INDEX]: "Monday",
  [TUESDAY_INDEX]: "Tuesday",
  [WEDNESDAY_INDEX]: "Wednesday",
  [THURSDAY_INDEX]: "Thursday",
  [FRIDAY_INDEX]: "Friday",
  [SATURDAY_INDEX]: "Saturday",
  [SUNDAY_INDEX]: "Sunday"
};