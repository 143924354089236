import styled from "styled-components";

import { ParticleStyle } from "../components";

export const SmokeParticleAnimations = [
  "animate-variation-1",
  "animate-variation-2",
  "animate-variation-3",
];

export const SmokeParticle = styled(ParticleStyle)`
  border-radius: 50%;
  position: absolute;

  @-webkit-keyframes smoke-up-variation-1 {
    0% {
      transform: translateX(0) translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateX(-20px) translateY(-30px);
      width: 0px;
      height: 0px;
    }
  }
  @keyframes smoke-up-variation-1 {
    0% {
      transform: rotate(30deg);
    }
    100% {
      opacity: 0;
      transform: translateX(-20px) translateY(-30px);
      width: 0px;
      height: 0px;
    }
  }

  @-webkit-keyframes smoke-up-variation-2 {
    0% {
      transform: translateX(0) translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateX(-20px) translateY(-15px);
      width: 0px;
      height: 0px;
    }
  }
  @keyframes smoke-up-variation-2 {
    0% {
      transform: rotate(30deg);
    }
    100% {
      opacity: 0;
      transform: translateX(-20px) translateY(-15px);
      width: 0px;
      height: 0px;
    }
  }

  @-webkit-keyframes smoke-up-variation-3 {
    0% {
      transform: translateX(0) translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateX(-10px) translateY(-30px);
      width: 0px;
      height: 0px;
    }
  }
  @keyframes smoke-up-variation-3 {
    0% {
      transform: rotate(30deg);
    }
    100% {
      opacity: 0;
      transform: translateX(-10px) translateY(-30px);
      width: 0px;
      height: 0px;
    }
  }

  &.animate-variation-1 {
    animation-name: smoke-up-variation-1;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
  }

  &.animate-variation-2 {
    animation-name: smoke-up-variation-2;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
  }

  &.animate-variation-3 {
    animation-name: smoke-up-variation-3;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
  }
`;
