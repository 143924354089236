import { LongEmailProps } from "./email/longEmail";
import { ShortEmailProps } from "./email/shortEmail";
import { LongSmsParams } from "./sms/longSms";
import { ShortSmsProps } from "./sms/shortSms";

export const injectMessagingValues = (
  template: string,
  data: LongEmailProps | ShortEmailProps | LongSmsParams | ShortSmsProps
) => {
  let txt = template;
  for (let key in data) {
    const templateKey = key.toLocaleUpperCase();
    //@ts-ignore
    const value = data[key];
    txt = txt.replace(templateKey, value);
  }
  return txt;
};
