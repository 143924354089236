import { httpPost } from "../../utilities";


export interface HttpAdminUpdateUserData {
  first_name: string;
  last_name: string;
  email?: string;
  password?: string;
  assign_agent_roles: number[];
  phone_number: string;
  is_admin?: boolean;
  is_marketing?: boolean;
}

export const httpAdminUpdateUser = async (id: string | number, data: HttpAdminUpdateUserData) => {
  return httpPost(`/admin/users/${id}`, data)
}