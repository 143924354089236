import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FlexRow,
  FlexColumn,
  Button,
  ButtonTypes,
  Text,
  TitleXL,
  Badge,
} from "../../../shared/styled";
import { Scroll, Icon, IconTypes, Select } from "../../../shared/components";
import { Table } from "./components/Table";
import { LeadFilters } from "./components/LeadFilters";
import { Lead } from "../../../models/lead";
import { PriorityMap } from "../../../models/lead/priorities";
import { httpGetLeads } from "../../../apis/leads";
import { httpExportLeads } from "../../../apis/admin/lead";
import { toReadableDateOnly } from "../../../utilities/datetime";
import { parsePhoneNumber } from "../../../models/lead/utilities";
import { theme, colors } from "../../../constants";
import { Toaster, toast } from "react-hot-toast";

export const Leads = () => {
  const navigate = useNavigate();

  const [leads, setLeads] = useState<Lead[]>();
  const [isLoading, setLoading] = useState<boolean>(false);

  const [filters, setFilters] = useState<any>({});
  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);
  const [total, setTotal] = useState<number>();

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null);

  const [downloadingCsv, setDownloadingCsv] = useState<boolean>(false);
  // const [selectedFields, setSelectedFields] = useState<string[]>([]);

  const options = [
    { label: "View Details", value: "expand" },
    { label: "Edit", value: "route,/admin/manage/:email" },
    { label: "View Notes", value: "notes" },
    { label: "Delete", value: "action,delete", textColor: "#CC3434" },
  ];

  const priorities: { [key: number]: string } = {};

  for (const key in PriorityMap) {
    if (PriorityMap.hasOwnProperty(key)) {
      priorities[key] = PriorityMap[key].label;
    }
  }

  const fetchLeads = async (reset = false) => {
    setLoading(true);
    try {
      const response = await httpGetLeads(
        offset,
        limit,
        ["-created_at"],
        filters
      );

      if (reset) setLeads(response?.data.results);
      else setLeads([...(leads || []), ...response?.data.results]);

      setTotal(response?.data.count);
    } catch (err) { }
    setLoading(false);
  };

  // Initial lead fetch
  useEffect(() => {
    fetchLeads(true);
  }, [filters, limit, offset]);

  const handleExportClick = async () => {
    try {
      const filtersToUse = filters;
      if ("priority__in" in filters) {
        filtersToUse.priority__in = `${filters.priority__in}`
          .split(",")
          .filter(item => item && !isNaN(Number(item)))
          .map(item => Number(item))
      }

      const response = await httpExportLeads(filtersToUse);
      if (response) {
        toast.success("Successfully exported leads.", {
          style: {
            border: `1px solid ${theme.colors.success}`,
            padding: "16px",
            boxShadow: "none",
            borderRadius: "24px",
          },
          iconTheme: {
            primary: `${theme.colors.success}`,
            secondary: `${theme.colors.clean}`,
          },
        });
        window.open(
          `${window.location.origin}/${response.data.file}`,
          "_blank"
        );
      } else {
        toast.error("Failed to export leads.", {
          style: {
            border: `1px solid ${theme.colors.danger}`,
            padding: "16px",
            boxShadow: "none",
            borderRadius: "24px",
          },
          iconTheme: {
            primary: `${theme.colors.danger}`,
            secondary: `${theme.colors.clean}`,
          },
        });
      }
    } catch (err) {
      console.log(err);
      toast.error("Failed to prepare document: ", {
        style: {
          border: `1px solid ${theme.colors.danger}`,
          padding: "16px",
          boxShadow: "none",
          borderRadius: "24px",
        },
        iconTheme: {
          primary: `${theme.colors.danger}`,
          secondary: `${theme.colors.clean}`,
        },
      });
    }
  };

  //Saving for later
  // const handleSelectedFields = (fields: string[]) => {
  //   setSelectedFields(fields);
  // };

  useEffect(() => {
    const closeExpandedRow = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setIsExpanded(false);
        setExpandedRowIndex(null);
      }
    };
    document.addEventListener("keydown", closeExpandedRow);
    return () => document.removeEventListener("keydown", closeExpandedRow);
  }, [isExpanded]);

  return (
    <>
      <Toaster position="bottom-left" reverseOrder={false} />
      <Scroll wrapperStyle={{ dimensions: { flex: 1 } }}>
        <FlexColumn>
          <FlexRow
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "1rem",
              boxShadow: `0px 1px 3px 0px ${theme.shadow.color}`,
              borderRadius: "12px 12px 12px 12px",
            }}
            padding={"24px"}
          >
            <FlexColumn gap="24px">
              <TitleXL weight="bolder" size="24px">
                Bulk Action
              </TitleXL>
              <FlexRow gap="16px">
                <Button
                  border={{ radius: "8px" }}
                  styleType={ButtonTypes?.primary}
                  dimensions={{ height: "40px", width: "100%" }}
                  disabled={downloadingCsv}
                  padding={{ left: "16px", right: "16px" }}
                  onClick={() => handleExportClick()}
                // openModal("SelectedFieldsModal", SelectedFieldsModal, {
                //   selectedFieldsCallback: handleSelectedFields,
                //   value: selectedFields,
                //   title: "Select Fields to Export",
                //   exportLeads: handleExportClick,
                // })}
                >
                  <FlexRow gap="8px" alignItems="center">
                    <Icon
                      type={IconTypes.export}
                      color={colors.white.clean}
                      size="18px"
                    />
                    <Text color={colors.white.clean} size={"14px"}>
                      Export Leads
                    </Text>
                  </FlexRow>
                </Button>
                <Button
                  border={{ radius: "8px" }}
                  styleType={ButtonTypes?.primary}
                  disabled={true}
                  dimensions={{ height: "40px", width: "100%" }}
                  padding={{ left: "16px", right: "16px" }}
                >
                  <FlexRow gap="8px" alignItems="center">
                    <Icon
                      type={IconTypes.trash}
                      color={colors.white.clean}
                      size="18px"
                    />
                    <Text color={colors.white.clean} size={"14px"}>
                      Delete Leads
                    </Text>
                  </FlexRow>
                </Button>
              </FlexRow>
            </FlexColumn>
          </FlexRow>
          <Table<Lead>
            header={(value) => true}
            isExpandable={true}
            isExpanded={isExpanded}
            expandedRowIndex={expandedRowIndex}
            loading={isLoading}
            tableShadow={`0px 4px 4px 0px ${theme.shadow.color}`}
            pagination={{
              limit: limit,
              setLimit: setLimit,
              offset: offset,
              setOffset: setOffset,
              total: total,
            }}
            columns={[
              {
                label: "Created At",
                key: "created_at",
                styling: { maxWidth: "fit-content" },
                mapper: (item) =>
                  toReadableDateOnly(item?.created_at) || <>-</>,
              },
              {
                label: "Name",
                key: "id",
                styling: { maxWidth: "100%" },
                mapper: (item) =>
                  `${item?.first_name ?? ""} ${item?.last_name ?? ""}` || (
                    <>-</>
                  ),
              },
              {
                label: "Email",
                key: "email",
                styling: { maxWidth: "100%" },
                mapper: (item) => item?.email || <>-</>,
              },
              {
                label: "Phone",
                key: "phone",
                styling: { maxWidth: "100%" },
                mapper: (item) =>
                  parsePhoneNumber(item?.phone as string) || <>-</>,
              },
              {
                label: "Status",
                key: "status",
                styling: { maxWidth: "100%" },
                mapper: (item) => {
                  if (item?.priority === null || item?.priority === undefined)
                    return (
                      <Badge dimensions={{ width: "60%" }}>Not Claimed</Badge>
                    );
                  return (
                    <Badge dimensions={{ width: "60%" }}>
                      {priorities[item?.priority] !== undefined
                        ? priorities[item?.priority]
                        : "Unknown"}
                    </Badge>
                  );
                },
              },
              {
                label: "Claimed By",
                key: "agent",
                styling: { maxWidth: "100%" },
                mapper: (item) =>
                  item?.agent ?
                    <>
                      {item?.agent?.first_name} {item?.agent?.last_name}
                    </>
                    :
                    <>Not Claimed</>
              },
              {
                label: "Actions",
                styling: {
                  maxWidth: "100%",
                },
                mapper: (item) => (
                  <>
                    <Select
                      transparent={true}
                      placeholder={
                        <Icon
                          type={IconTypes.doner}
                          color={theme.colors.disabled}
                          size="24px"
                        />
                      }
                      options={options}
                      closeOnSelectOption={true}
                      enableHoverEffect={true}
                      justifyContent="center"
                      onTop={true}
                      hasCarrot={false}
                      style={{
                        valueWrapper: {
                          dimensions: { width: "120px", height: "32px" },
                        },
                      }}
                      setValue={(value: string) => {
                        const [type, data] = value?.split(",");
                        if (type === "expand") {
                          if (item?.id === undefined) return;
                          setExpandedRowIndex(item?.id);
                          setIsExpanded(true);
                          return;
                        }
                        if (type === "route") {
                          if (item?.email === undefined) return;
                          const routeData = data.includes(":email")
                            ? data.replace(":email", item?.email)
                            : data;
                          navigate(routeData);
                          return;
                        }
                        if (data === "delete") {
                          if (item?.id !== undefined) {
                            setLeads((prev) => {
                              return (
                                prev?.filter(
                                  (prevItem) => prevItem.id !== item.id
                                ) ?? []
                              );
                            });
                          }
                          return;
                        }
                      }}
                    />
                  </>
                ),
              },
            ]}
            data={leads}
            headerDimensions={{ width: "100%", height: "fit-content" }}
            headerComponent={
              <LeadFilters filters={filters} setFilters={setFilters} />
            }
          />
        </FlexColumn>
      </Scroll>
    </>
  );
};
