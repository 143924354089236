import { useForm } from "react-hook-form";

import { FlexColumn, FlexRow, Text, Title } from "../../../../shared/styled";
import { StyledBaseInput } from "../../../../shared/components/Input/StyledBaseInput";

interface FormData {
  stringValue: string;
  numberValue: number;
  checkboxValue: boolean;
  dateValue: Date;
}

export const FormSection = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormData>();

  const formValues = watch();

  return (
    <FlexRow dimensions={{ width: "100%" }} gap="20px">
      <FlexColumn gap="10px" dimensions={{ flex: 1 }}>
        <StyledBaseInput type="text" {...register("stringValue")} />
        <StyledBaseInput type="number" {...register("numberValue")} />
        <StyledBaseInput type="checkbox" {...register("checkboxValue")} />
        <StyledBaseInput type="date" {...register("dateValue")} />
      </FlexColumn>
      <FlexColumn dimensions={{ width: "256px" }}>
        {Object.keys(formValues).map((key) => {
          return (
            <FlexRow gap="10px" justifyContent="space-between">
              <Title>{key}:</Title>
              <Text>{`${(formValues as any)[key]}`}</Text>
            </FlexRow>
          );
        })}
      </FlexColumn>
    </FlexRow>
  );
};
