import { Route, useNavigate } from "react-router-dom";

import { AdminDashboard } from "./AdminDashboard";
import { Overview, Users, Leads, Statistics, EditUser } from "./screens";
import { useEffect } from "react";
import { ManageTemplate, Templates } from "./screens/templates";

export const ADMIN_DASHBOARD_ROUTES = (
  <Route path="/admin" Component={AdminDashboard}>
    <Route path="/admin/overview" Component={Overview} />
    <Route path="/admin/users" Component={Users} />
    <Route path="/admin/leads" Component={Leads} />
    <Route path="/admin/statistics" Component={Statistics} />
    <Route path="/admin/manage/:emailFromUrl" Component={EditUser} />
    <Route path="/admin/manage" Component={EditUser} />
    <Route path="/admin/templates" Component={Templates} />
    <Route path="/admin/templates/:id" Component={ManageTemplate} />
    <Route
      path="/admin/*"
      // Redirect handler in 404 case
      Component={() => {
        const navigate = useNavigate();

        useEffect(() => {
          navigate("/admin/overview");
        }, []);

        return <></>;
      }}
    />
  </Route>
);
