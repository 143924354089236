import { styled } from "styled-components";

import { FlexColumn, FlexRow, Title, TitleXL } from "../../shared/styled";
import { SANDBOX_SECTIONS } from "./sections";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { SectionSchema } from "./sections/types/sectionSchema";

const SandboxDocumentationStyled = styled.pre`
  white-space: pre;
  font-family: "Courier New", monospace;
  background-color: #f7f7f7;
  padding: 10px;

  code {
    white-space: pre;
  }
`;

export const Sandbox = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [component, setComponent] = useState<SectionSchema>();

  const handleSetComponent = () => {
    const pathSection = location.pathname.split("/")[3];
    const currentSection = SANDBOX_SECTIONS.find(
      (section) => section?.id === pathSection
    );

    if (!currentSection) {
      navigate(`/dev/sandbox/${SANDBOX_SECTIONS[0].id}`);
      return;
    }

    setComponent(currentSection);
  };

  useEffect(() => {
    handleSetComponent();
  }, [location.pathname]);

  return (
    <FlexColumn
      padding="32px"
      dimensions={{ height: "100vh", width: "100vw" }}
      gap="10px"
    >
      <FlexRow gap="10px">
        <TitleXL>Component:</TitleXL>
        <TitleXL weight="bold">{component?.label || "UNKNOWN"}</TitleXL>
      </FlexRow>
      <FlexColumn>
        <SandboxDocumentationStyled>
        <Title>Documentation:</Title>
          <code>{component?.documentation || "No docs"}</code>
        </SandboxDocumentationStyled>
      </FlexColumn>

      <FlexColumn
        justifyContent="center"
        alignItems="center"
        dimensions={{ width: "100%", flex: 1 }}
      >
        <Outlet />
      </FlexColumn>
    </FlexColumn>
  );
};
