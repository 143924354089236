import { SectionSchema } from "../types/sectionSchema";

import { IconSection } from "./IconSection";
import { IconSectionDocumentation } from "./documentation";


export const IconSectionSchema: SectionSchema = {
  component: IconSection,
  documentation: IconSectionDocumentation,
  label: "Icon",
  id: "icon"
}