import { SectionSchema } from "../types/sectionSchema";

import { InputSection } from "./InputSection";
import { InputSectionDocumentation } from "./documentation";


export const InputSectionSchema: SectionSchema = {
  component: InputSection,
  documentation: InputSectionDocumentation,
  label: "Input",
  id: "input"
}