import { Provider } from "../../../models/provider";
import { User } from "../../../models/user";
import { httpGet } from "../../utilities";

export interface HttpGetProvidersResponse {
  count: number;
  results: Provider[];
}

export const httpGetProviders = async (
  search?: string,
  offset?: number,
  limit?: number,
  order?: string[],
  filters?: any
) => {
  return httpGet<HttpGetProvidersResponse | Provider[]>(`/admin/providers`, {
    search: search,
    offset: offset,
    limit: limit,
    ordering: order,
    ...filters,
  });
};
