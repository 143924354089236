import { useState } from "react";

import { DateRange } from "../../../../shared/components";
import { DateRangeValue } from "../../../../shared/components/DateRange/types";

import {
  FlexColumn,
  FlexRow,
  Text,
  Title,
  TitleXL,
} from "../../../../shared/styled";

export const DateRangeSection = () => {
  const [value, setValue] = useState<DateRangeValue>({});

  return (
    <FlexRow dimensions={{ width: "100%" }} gap="20px">
      <FlexColumn justifyContent="center" alignItems="center">
        <DateRange value={value} setValue={setValue} />
      </FlexColumn>
      <FlexColumn gap="10px" alignItems="center">
        <TitleXL>States</TitleXL>
        <FlexColumn>
          <Title>Date Range</Title>
          <Text>
            {value?.start?.toString() || "Unset"} - {value?.end?.toString() || "Unset"}
          </Text>
        </FlexColumn>
      </FlexColumn>
    </FlexRow>
  );
};
