import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth, useModalProvider } from "../../../../contexts";
import { Table } from "../components/Table";
import { User } from "../../../../models/user";
import {
  Scroll,
  Icon,
  IconTypes,
  Select,
  Toggle,
  BaseInput,
} from "../../../../shared/components";
import {
  HttpAdminGetUsersResponse,
  httpAdminGetUsers,
  httpAdminDeactivateUser,
  httpAdminActivateUser,
  httpAdminDeleteUser,
} from "../../../../apis/admin/user";
import {
  FlexRow,
  Badge,
  FlexContainer,
  FlexColumn,
  Button,
  Text,
} from "../../../../shared/styled";
import { theme, colors } from "../../../../constants";
import { UpdateUserAuthModal } from "./modals";

export const Users = () => {
  const navigate = useNavigate();
  const { openModal, closeModal } = useModalProvider();

  const [data, setData] = useState<User[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  const [search, setSearch] = useState<string>("");
  const [order, setOrder] = useState<string[]>([]);
  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);
  const [total, setTotal] = useState<number>();

  const fetch = async (reset = false) => {
    setLoading(true);
    try {
      const response = await httpAdminGetUsers(search, offset, limit, order);

      if (reset)
        setData((response?.data as HttpAdminGetUsersResponse)?.results);
      setTotal((response?.data as HttpAdminGetUsersResponse)?.count || 0);
    } catch (err) {}
    setLoading(false);
  };

  const options = [
    { label: "Edit", value: "route,/admin/manage/:email" },
    { label: "Update auth", value: "action,updateAuthModal" },
    { label: "Delete", value: "action,delete", textColor: "#CC3434" },
  ];

  useEffect(() => {
    fetch(true);
  }, [order, limit, offset]);

  return (
    <>
      <Scroll wrapperStyle={{ dimensions: { flex: 1 } }}>
        <FlexContainer
          dimensions={{ width: "100%" }}
          padding={{ left: "32px", right: "32px", top: "32px" }}
        >
          <Table<User>
            header={(value) => true}
            loading={isLoading}
            tableShadow={`0px 4px 4px 0px ${theme.shadow.color}`}
            pagination={{
              limit: limit,
              setLimit: setLimit,
              offset: offset,
              setOffset: setOffset,
              total: total,
            }}
            orders={{
              orderableFields: ["id", "first_name", "is_disabled", "email"],
              setOrder: setOrder,
              value: order,
            }}
            columns={[
              {
                label: "No",
                key: "id",
                styling: { maxWidth: "fit-content" },
                mapper: (item) => item?.id || <>-</>,
              },
              {
                label: "Name",
                key: "first_name",
                styling: { maxWidth: "100%" },
                mapper: (item) => `${item?.first_name} ${item?.last_name}`,
              },
              {
                label: "Email",
                key: "email",
                styling: { maxWidth: "100%" },
                mapper: (item) => item?.email || <>-</>,
              },
              {
                label: "Status",
                key: "is_disabled",
                styling: { maxWidth: "100%" },
                mapper: (item) => (
                  <Toggle
                    checked={!item?.is_disabled}
                    onChange={async () => {
                      if (item?.id !== undefined) {
                        try {
                          if (item?.is_disabled) {
                            await httpAdminActivateUser(item.id);
                          } else {
                            await httpAdminDeactivateUser(item.id);
                          }
                          await fetch(true);
                        } catch (error) {
                          console.error("An error occurred:", error);
                        }
                      }
                    }}
                    backgroundColorChecked="#2CD06E"
                    backgroundColorUnchecked="#E0E0E0"
                    width={28}
                    height={16}
                    sliderHeight={12}
                    sliderWidth={12}
                    translate={9}
                  />
                ),
              },
              {
                label: "Roles",
                key: "roles",
                styling: { maxWidth: "100%" },
                mapper: (item) => (
                  <FlexRow gap="5px">
                    {item?.roles?.map((role) => (
                      <Badge>{role}</Badge>
                    ))}
                    {item?.agent_roles?.map((agentRole) => (
                      <Badge>{agentRole.name}</Badge>
                    ))}
                  </FlexRow>
                ),
              },
              {
                label: "Actions",
                styling: {
                  maxWidth: "100%",
                },
                mapper: (item) => (
                  <>
                    <Select
                      transparent={true}
                      placeholder={
                        <Icon
                          type={IconTypes.doner}
                          color={theme.colors.disabled}
                          size="18px"
                        />
                      }
                      hasCarrot={false}
                      options={options}
                      closeOnSelectOption={true}
                      enableHoverEffect={true}
                      justifyContent="center"
                      onTop={true}
                      style={{
                        valueWrapper: {
                          dimensions: { width: "120px", height: "32px" },
                        },
                      }}
                      setValue={(value: string) => {
                        const [type, data] = value?.split(",");
                        if (type === "route") {
                          if (item?.email === undefined) return;
                          const routeData = data.includes(":email")
                            ? data.replace(":email", item?.email)
                            : data;
                          navigate(routeData);
                          return;
                        }

                        if (data === "updateAuthModal") {
                          openModal("update-user-auth", UpdateUserAuthModal, {
                            close: () => closeModal("update-user-auth"),
                            user: item,
                          });
                          return;
                        }

                        if (data === "delete") {
                          if (item?.id !== undefined) {
                            httpAdminDeleteUser(item.id);
                            setData((prev) =>
                              prev.filter((prevItem) => prevItem.id !== item.id)
                            );
                          }
                          return;
                        }
                      }}
                    />
                  </>
                ),
              },
            ]}
            data={data}
            headerDimensions={{ width: "100%", height: "120px" }}
            headerComponent={
              <FlexColumn
                alignItems="flex-end"
                gap="5px"
                padding={{ top: "16px", bottom: "16px" }}
                dimensions={{ width: "20%" }}
              >
                <BaseInput
                  placeholder="Search..."
                  value={search}
                  onChange={async (e) => {
                    setSearch(e.target.value);
                    await fetch(true);
                  }}
                  dimensions={{ width: "100%", height: "32px" }}
                />
                <Button
                  background={colors.white.clean}
                  border={{
                    color: theme.colors.disabled,
                    width: "1px",
                    radius: "8px",
                  }}
                  justifyContent="center"
                  alignItems="center"
                  padding={{ left: "16px", right: "16px" }}
                  onClick={() => navigate("/admin/manage")}
                  dimensions={{ width: "fit-content", height: "32px" }}
                >
                  <FlexRow gap="10px">
                    <Icon
                      type={IconTypes.addPerson}
                      color={theme.colors.secondary}
                      size="16px"
                    />
                    <Text
                      color={theme.colors.secondary}
                      size="14px"
                      weight={500}
                    >
                      Add User
                    </Text>
                  </FlexRow>
                </Button>
              </FlexColumn>
            }
          />
        </FlexContainer>
      </Scroll>
    </>
  );
};
