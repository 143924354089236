import { useCallback, useEffect, useMemo, useState } from "react";
import { useModalProvider } from "../../../../../contexts";
import { getLastMonday } from "../utilities";
import { DateRangeValue } from "../../../../../shared/components/DateRange/types";
import { AgentStatistics } from "../../../../../models/statistics";
import { ColumnItem, Table } from "../../components/Table";
import { theme } from "../../../../../constants";
import {
  Button,
  ButtonTypes,
  FlexColumn,
  FlexItem,
  FlexRow,
  Text,
  TitleXL,
} from "../../../../../shared/styled";
import { DateRangeFilterModal } from "../../../../../modals";
import { Icon, IconTypes } from "../../../../../shared/components";
import {
  toQueryParamDateTime,
  toReadableDateOnly,
} from "../../../../../utilities/datetime";
import {
  httpAdminGetAgentProviderStatistics,
  httpAdminGetAgentProviderStatisticsResponseItem,
} from "../../../../../apis/admin";
import toast from "react-hot-toast";

export const AgentProviderTable = () => {
  const { openModal, closeModal } = useModalProvider();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [order, setOrder] = useState<string[]>([]);

  const [createdAtRange, setCreatedAtRange] = useState<DateRangeValue>({
    start: getLastMonday(),
    end: new Date(),
  });

  const [data, setData] =
    useState<httpAdminGetAgentProviderStatisticsResponseItem[]>();

  const fetchData = useCallback(async () => {
    setLoading(true);
    if (!createdAtRange?.start || !createdAtRange?.end) {
      setLoading(false);
      return;
    }
    try {
      const { data: freshData } = await httpAdminGetAgentProviderStatistics(
        createdAtRange?.start && createdAtRange?.end
          ? toQueryParamDateTime(createdAtRange.start)
          : undefined,
        createdAtRange?.start && createdAtRange?.end
          ? toQueryParamDateTime(createdAtRange.end, true)
          : undefined
      );

      setData(freshData);

      setLoading(false);
    } catch (error) {
      toast.error("Error fetching Agents", {
        style: {
          border: `1px solid ${theme.colors.danger}`,
          padding: "16px",
          boxShadow: "none",
          borderRadius: "24px",
        },
        iconTheme: {
          primary: `${theme.colors.danger}`,
          secondary: `${theme.colors.clean}`,
        },
      });
      console.error(error);
    }
  }, [createdAtRange]);

  useEffect(() => {
    fetchData();
  }, [order, createdAtRange]);

  const tableHeaderLables = useMemo(() => {
    if (!data) return undefined;
    const labelData: httpAdminGetAgentProviderStatisticsResponseItem = {
      user: { first_name: "Agent Name", last_name: "", id: 0 },
      providers: {},
    };
    new Set(data.map((item) => Object.keys(item.providers)).flat()).forEach(
      (provider) => (labelData.providers[provider] = provider)
    );
    return labelData;
  }, [data]);

  const tableColumns = useMemo(() => {
    if (!data) return [];

    const columns: ColumnItem<httpAdminGetAgentProviderStatisticsResponseItem>[] =
      [
        {
          label: "Agent Name",
          key: "first_name",
          styling: { minWidth: "200px", maxWidth: "200px" },
          mapper: (item) => (
            <FlexItem dimensions={{ minWidth: "200px", maxWidth: "200px" }}>
                {item?.user?.first_name ===  "Agent Name" && <Text weight="bold">{item?.user.first_name}</Text>}
                {item?.user?.first_name !==  "Agent Name" && <>{item?.user.first_name} {item?.user.last_name}</>}
            </FlexItem>
          ),
        },
      ];

    Array.from(
      new Set(data.map((item) => Object.keys(item.providers)).flat())
    ).forEach((provider) => {
      columns.push({
        label: provider,
        key: "provider",
        styling: { minWidth: "100px", maxWidth: "100px", flex: undefined },
        mapper: (item) => (
          <FlexItem dimensions={{ minWidth: "100px", maxWidth: "100px" }} style={{overflow: 'hidden'}}>
            {item.providers?.[provider] === provider && <Text weight="bold">{provider}</Text>}
            {item.providers?.[provider] !== provider && <>{item.providers?.[provider] ?? 0}</>}
          </FlexItem>
        ),
      });
    });

    return columns;
  }, [data]);

  return (
    <Table<httpAdminGetAgentProviderStatisticsResponseItem>
      loading={isLoading || !data}
      header={(value) => true}
      title="Agent by Providers Statistics"
      tableShadow={`0px 4px 4px 0px ${theme.shadow.color}`}
      columns={tableColumns}
      data={[
        ...(tableHeaderLables ? [tableHeaderLables] : []),
        ...(data ?? []),
      ]}
      headerDimensions={{ width: "100%", height: "72px" }}
      hideHeaderLabeles={true}
      headerComponent={
        <FlexColumn
          alignItems="flex-end"
          justifyContent="center"
          gap="5px"
          padding={{ top: "16px", bottom: "16px" }}
          dimensions={{ width: "20%" }}
        >
          <Button
            styleType={ButtonTypes?.primary}
            dimensions={{ height: "32px" }}
            disabled={isLoading}
            onClick={() =>
              openModal("createdAtAgentStatisticsModal", DateRangeFilterModal, {
                setValue: setCreatedAtRange,
                value: createdAtRange,
                close: () => closeModal("createdAtAgentStatisticsModal"),
                title: <TitleXL>Created At Range</TitleXL>,
              })
            }
          >
            <FlexRow
              justifyContent="space-between"
              alignItems="center"
              gap="5px"
            >
              <Icon
                type={IconTypes?.calendar}
                size="20px"
                color={theme?.colors?.clean}
              />
              <FlexItem dimensions={{ flex: 1 }}>
                <Text color={theme?.colors?.clean} align="center">
                  {(!createdAtRange?.start || !createdAtRange?.end) && (
                    <>Created At Range</>
                  )}
                  {createdAtRange?.start && createdAtRange?.end && (
                    <>
                      {toReadableDateOnly(createdAtRange?.start)} -{" "}
                      {toReadableDateOnly(createdAtRange?.end)}
                    </>
                  )}
                </Text>
              </FlexItem>
            </FlexRow>
          </Button>
        </FlexColumn>
      }
    />
  );
};
