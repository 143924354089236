//@ts-ignore
import mapboxgl from "!mapbox-gl";

import { REACT_APP_MAPBOX_ACCESS_TOKEN } from "../../envs";

export const getMapBoxInstance = (
 containerNodeId: string,
 origin?: [number, number],
 destination?: [number, number]
) => {
 //@ts-ignore
 //mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

 if (mapboxgl.accessToken !== REACT_APP_MAPBOX_ACCESS_TOKEN) mapboxgl.accessToken = REACT_APP_MAPBOX_ACCESS_TOKEN;

 const map = new mapboxgl.Map({
  container: containerNodeId,
  // Choose from Mapbox's core styles, or make your own style with Mapbox Studio
  style: "mapbox://styles/mike-flixat/cludgosn900g901r5asn04fmm",
  center: origin,
  zoom: 6,
 });

 const directions = new (window as any).MapboxDirections({
  accessToken: REACT_APP_MAPBOX_ACCESS_TOKEN,
  unit: "imperial",
  profile: "mapbox/driving",
  interactive: false,
 });

 map.addControl(directions, "top-left");

 origin && directions.actions.setOriginFromCoordinates(origin);
 destination && setTimeout(() => directions.actions.setDestinationFromCoordinates(destination), 800);

 return map;
};
