import { ReactNode } from "react";
import { FlexContainer } from "../../shared/styled";

export const TableHeader = ({ children }: { children?: ReactNode }) => {
  return (
    <FlexContainer
      dimensions={{ width: "100%", minHeight: "50px" }}
      background={"#E4ECFB"}
      padding="32px"
      justifyContent="flex-end"
    >
      {children}
    </FlexContainer>
  );
};
