import { useSearchParams } from "react-router-dom";
import { Map } from "../../shared/components/Map";
import { FlexColumn } from "../../shared/styled";

export const MapScreen = () => {
 const [params] = useSearchParams();

 const originLatitude = params.get("origin_latitude");
 const originLongitude = params.get("origin_longitude");
 const destinationLatitude = params.get("destination_latitude");
 const destinationLongitude = params.get("destination_longitude");

 return (
  <FlexColumn dimensions={{ width: "100vw", height: "100vh" }}>
   <Map
    styling={{
     wrapper: { dimensions: { width: "u100%", height: "100%" } },
    }}
    originCoords={[Number(originLongitude), Number(originLatitude)]}
    destinationCoords={[Number(destinationLongitude), Number(destinationLatitude)]}
    hideControles={false}
   />
  </FlexColumn>
 );
};
