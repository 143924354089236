export interface ShortEmailProps {
 first_name: string;
 vehicle: string;
 agent_name: string;
 agent_number: string;
 first_name_two: string;
}

export const SHORT_EMAIL = `Hello FIRST_NAME,
We talked about your VEHICLE shipment. 

I wanted to follow up, we got ONLY 2 SPOTS LEFT on our trailer. Do you still need the service?

We include all of the taxes, all of the tolls, as well as door-to-door delivery and 24/7 support. 
On top of that, you are allowed to have up to 100 lbs of items in the trunk of your car, completely free of charge.
FIRST_NAME_TWO, does this work for you?

AGENT_NAME,
Transport Manager
AGENT_NUMBER - my direct phone number
(512) 583-6611 - main company line
Flix Auto Transport
`;