import { httpGet } from "../../utilities";


export interface httpAdminGetAgentProviderStatisticsResponseItem {
  user: {
    id: number;
    first_name: string;
    last_name: string;
  };
  providers: {
    [providerCode: string]: number | string;
  }
}

export const httpAdminGetAgentProviderStatistics = async (
  createdAtStart?: string,
  createdAtEnd?: string,
) => {
  const queryParams: Record<string, any> = {};

  if (createdAtStart) {
    queryParams.created_at__gte = createdAtStart;
  }

  if (createdAtEnd) {
    queryParams.created_at__lte = createdAtEnd;
  }

  return httpGet<httpAdminGetAgentProviderStatisticsResponseItem[]>(
    `/admin/statistics/agent/provider`,
    queryParams
  );
};
