import { useEffect, useRef } from "react";
import {
  httpAuthorizeGoogleProvider,
  httpCallbackGoogleProvider,
} from "../../apis/google";

export const GoogleOAuthProvider = () => {
  const inProgress = useRef<boolean>(false);

  const startFlow = async () => {
    if (inProgress.current) return;
    inProgress.current = true;
    const { data } = await httpAuthorizeGoogleProvider();
    const { auth_url: authUrl } = data;
    window.location.href = authUrl;
  };

  const finalizeFlow = async (code: string) => {
    try {
      const { data } = await httpCallbackGoogleProvider(code);
      console.log("data", data);
    } catch (error) {
      console.error("Error during Google callback:", error);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    if (code) {
      // Send the authorization code to the backend to get tokens
      finalizeFlow(code);
    } else {
      startFlow();
    }
  }, []);

  return <>
    Connecting google account to the crm...
  </>;
};
