import { ButtonSectionSchema } from "./ButtonSection";
import { DateRangeSchema } from "./DateRangeSection";
import { FormSectionSchema } from "./FormSection";
import { IconSectionSchema } from "./IconSection";
import { InputSectionSchema } from "./InputSection";
import { MapSectionSchema } from "./MapSection";
import { SelectSectionSchema } from "./SelectSection";

export const SANDBOX_SECTIONS = [
  InputSectionSchema,
  FormSectionSchema,
  ButtonSectionSchema,
  SelectSectionSchema,
  IconSectionSchema,
  DateRangeSchema,
  MapSectionSchema
]