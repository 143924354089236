import { useEffect, useRef } from "react";
import styled, { css } from "styled-components";

import { getMapBoxInstance } from "../../../utilities/mapbox";
import { CHARSET_CLETTERS, CHARSET_LLETTERS, CHARSET_NUMBERS, randomString } from "../../../utilities/random";

import { DimensionStyling, DimensionStylingProps } from "../../styling";

interface WrapperProps {
 dimensions?: DimensionStylingProps;
 hideControles?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
 ${({ dimensions }) => DimensionStyling(dimensions)};

 ${({ hideControles }) =>
  hideControles
   ? css`
      .mapboxgl-control-container {
       display: none;
      }
     `
   : ""}

 .mapbox-directions-instructions {
  display: none;
 }
`;

interface MapProps {
 styling?: {
  wrapper?: WrapperProps;
 };
 originCoords?: [number, number];
 destinationCoords?: [number, number];
 hideControles?: boolean;
}

export const Map = ({ styling, originCoords, destinationCoords, hideControles }: MapProps) => {
 const componentId = useRef<string>(randomString(`${CHARSET_LLETTERS}${CHARSET_NUMBERS}${CHARSET_CLETTERS}`, 16));
 const mapBoxRef = useRef<ReturnType<typeof getMapBoxInstance>>();

 const initializeMap = () => {
  if (mapBoxRef.current) return;
  mapBoxRef.current = getMapBoxInstance(componentId.current, originCoords, destinationCoords);
 };

 useEffect(() => {
  initializeMap();
 }, [originCoords, destinationCoords]);

 return (
  <Wrapper
   id={componentId?.current}
   hideControles={hideControles === undefined ? true : hideControles}
   {...styling?.wrapper}
  />
 );
};
