import { Outlet } from "react-router-dom";
import { FlexColumn, FlexRow } from "../../shared/styled";
import { IconTypes } from "../../shared/components";
import { Content, Header, Navbar } from "../components";
import { useMemo } from "react";
import { useAuth } from "../../contexts";

const SUPER_ADMIN_ROLE = "System Super Administrator";
const ADMIN_ROLE = "Administrator";
const MARKETING_ROLE = "Marketing";

const NavbarItems = [
  {
    path: "/admin/overview",
    icon: IconTypes.dashboardFilled,
    name: "Overview",
    id: "overview",
    oneOfRoles: [SUPER_ADMIN_ROLE, ADMIN_ROLE, MARKETING_ROLE],
  },
  {
    path: "/admin/users",
    icon: IconTypes.users,
    name: "Users",
    id: "users",
    oneOfRoles: [SUPER_ADMIN_ROLE, ADMIN_ROLE],
  },
  {
    path: "/admin/leads",
    icon: IconTypes.people,
    name: "Leads",
    id: "leads",
    oneOfRoles: [SUPER_ADMIN_ROLE, ADMIN_ROLE, MARKETING_ROLE],
  },
  {
    path: "/admin/statistics",
    icon: IconTypes.statistics,
    name: "Statistics",
    id: "statistics",
    oneOfRoles: [SUPER_ADMIN_ROLE, ADMIN_ROLE],
  },
  {
    path: "/admin/templates",
    icon: IconTypes.emailSmsTemplate,
    name: "Templates",
    id: "templates",
    oneOfRoles: [SUPER_ADMIN_ROLE, ADMIN_ROLE, MARKETING_ROLE],
  },
];

export const AdminDashboard = () => {
  const { currentUser } = useAuth();
  const renderRoutes = useMemo(() => {
    if (currentUser === undefined) return [];

    return NavbarItems.map((route, index) => {
      const oneOfRolesAccess = route.oneOfRoles
        .map((role) => (currentUser.roles ?? []).includes(role))
        .includes(true);

      return oneOfRolesAccess ? index : null;
    })
      .filter((value) => value !== null)
      .map((value) => NavbarItems[value as number]);
  }, [currentUser]);

  return (
    <FlexRow dimensions={{ width: "100vw", height: "100vh" }} overflow="hidden">
      <Navbar routes={renderRoutes} />
      <FlexColumn dimensions={{ flex: 1 }} padding="10px" gap="10px">
        <Header />
        <Content>
          <Outlet />
        </Content>
      </FlexColumn>
    </FlexRow>
  );
};
