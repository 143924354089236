import { styled } from "styled-components";

import { colors } from "../../../../constants";

export const DateRangeWrapper = styled.div`
  background: ${colors?.white?.clean};
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
