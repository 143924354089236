import { ReactNode } from "react";

import { colors } from "../constants";
import { DateRange, Icon, IconTypes } from "../shared/components";
import { DateRangeValue } from "../shared/components/DateRange/types";
import { Button, FlexColumn, FlexItem, FlexRow } from "../shared/styled";

interface DateRangeFilterModal {
 setValue?: (value: DateRangeValue) => void;
 value?: DateRangeValue;
 close?: () => void;
 title?: ReactNode;
}

export const DateRangeFilterModal = ({
 setValue,
 value,
 close,
 title,
}: DateRangeFilterModal) => {
 const submit = (data: DateRangeValue) => {
  setValue && data && setValue(data);
  close && close();
 };

 const clear = () => {
  setValue && setValue({});
  close && close();
 }

 return (
  <FlexColumn
   background={colors?.white?.clean}
   dimensions={{ minWidth: "300px", minHeight: "300px" }}
   padding="24px"
   gap="20px"
   border={{ radius: "24px" }}
  >
   <FlexRow justifyContent="space-between">
    <FlexItem dimensions={{ flex: 1 }}>{title}</FlexItem>
    <Button onClick={() => close && close()}>
     <Icon size="16px" type={IconTypes.close} color={colors?.white?.clean} />
    </Button>
   </FlexRow>
   <DateRange setValue={submit} value={value} />
   <FlexRow justifyContent="flex-end">
    <Button disabled={!value?.start || !value?.end} onClick={clear}>Clear</Button>
   </FlexRow>
  </FlexColumn>
 );
};
