import { Route, useNavigate } from "react-router-dom";

import { AgentDashboard } from "./AgentDashboard";
import { Leads, MyLeads, MapSubscreen } from "./screens";
import { useEffect } from "react";

export const AGENT_DASHBOARD_ROUTES = (
 <Route path="/agent" Component={AgentDashboard}>
  <Route path="/agent/leads" Component={Leads} />
  <Route path="/agent/myleads" Component={MyLeads} />
  <Route path="/agent/map" Component={MapSubscreen} />
  <Route
   path="/agent/*"
   // Redirect handler in 404 case
   Component={() => {
    const navigate = useNavigate();

    useEffect(() => {
     navigate("/agent/leads");
    }, []);

    return <></>;
   }}
  />
 </Route>
);
