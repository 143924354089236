import type CSS from "csstype";
import { RuleSet, css, styled } from "styled-components";
import { Property } from 'csstype';

import {
 BackgroundStyling,
 BackgroundStylingProps,
 BorderStyling,
 BorderStylingProps,
 DimensionStyling,
 DimensionStylingProps,
 PaddingStyling,
 PaddingStylingProps
} from "../../styling";
import { theme } from "../../../constants";

export enum ButtonTypes {
 primary = "primary",
 primaryReverse = "primary-reverse",
 clean = "clean",
 cleanReverse = "clean-reverse",
 naked = "naked",
}

interface ButtonProps {
 dimensions?: DimensionStylingProps;
 border?: BorderStylingProps | CSS.Property.Border;
 background?: BackgroundStylingProps | CSS.Property.Background;

 alignItems?: Property.AlignItems;
 justifyContent?: Property.JustifyContent;
 gap?: Property.Gap;
 padding?: PaddingStylingProps | Property.Padding;

 styleType?: ButtonTypes;

 css?: RuleSet<object>;
}

const ButtonTypeStyles = {
 [ButtonTypes.primary]: css`
    border: solid 2px transparent;
    background: ${theme.colors.primary};
    color: ${theme.colors.clean};
    font-weight: ${theme.font.weight.bold};

    &:disabled {
      background: ${theme.colors.disabled};
      cursor: unset;
    }
  `,
 [ButtonTypes.primaryReverse]: css`
    border: solid 2px ${theme.colors.primary};
    background: ${theme.colors.clean};
    color: ${theme.colors.secondary};
    font-weight: ${theme.font.weight.bold};

    &:disabled {
      border-color: ${theme.colors.disabled};
      color: ${theme.colors.disabled};
      cursor: unset;
    }
  `,
 [ButtonTypes.clean]: css`
    border: solid 2px ${theme.colors.clean};
    background: ${theme.colors.clean};
    color: ${theme.colors.secondary};
    font-weight: ${theme.font.weight.bold};

    &:disabled {
      border-color: ${theme.colors.disabled};
      color: ${theme.colors.disabled};
      cursor: unset;
    }
  `,
 [ButtonTypes.cleanReverse]: css`
    border: solid 2px ${theme.colors.secondary};
    background: ${theme.colors.secondary};
    color: ${theme.colors.clean};
    font-weight: ${theme.font.weight.bold};

    &:disabled {
      border-color: ${theme.colors.disabled};
      color: ${theme.colors.disabled};
      cursor: unset;
    }
  `,
 [ButtonTypes.naked]: css`
    border: none;
    background: none;
    color: ${theme.colors.secondary};
    font-weight: ${theme.font.weight.bold};

    &:disabled {
      cursor: unset;
    }
  `,
};

export const Button = styled.button<ButtonProps>`

  ${({ padding }) => PaddingStyling(padding) || PaddingStyling({ top: "4px", bottom: "4px", left: "4px", right: "4px" })};

  font-size: ${theme.font.size.x}px;
  border-radius: 4px;

  align-items: ${({ alignItems }) => alignItems ?? undefined};
  justify-content: ${({ justifyContent }) => justifyContent ?? undefined};

  gap: ${({ gap }) => gap ?? undefined};
  ${({ padding }) => PaddingStyling(padding)};

  cursor: pointer;
  outline: none;

  ${({ styleType }) => ButtonTypeStyles[styleType || ButtonTypes.primary]}

  
  ${({ dimensions }) => DimensionStyling(dimensions)};
  ${({ border }) => BorderStyling(border)};
  ${({ background }) => BackgroundStyling(background)};

  ${({ css }) => css};
`;
