import type CSS from "csstype";
import { ReactNode, useEffect, useState } from "react";
import { RuleSet, css, styled } from "styled-components";
import { Property } from 'csstype';

import { useGlobalEvents } from "../../../contexts/GlobalEventProvider";
import { colors, theme } from "../../../constants";
import { Icon, IconTypes } from "../Icon";
import {
  DimensionStyling,
  DimensionStylingProps,
  PaddingStyling,
  PaddingStylingProps,
} from "../../styling";

const StyledSelectWrapper = styled.div`
  position: relative;
`;

interface StyleSelectValueWrapperProps {
  transparent?: boolean;
  alignItems?: Property.AlignItems;
  justifyContent?: Property.JustifyContent;
  disabled?: boolean;
  dimensions?: DimensionStylingProps;
  css?: RuleSet<object>;
  padding?: PaddingStylingProps | Property.Padding;
}

const StyleSelectValueWrapper = styled.div<StyleSelectValueWrapperProps>`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;

  border-radius: 4px;
  border: solid 1px ${({ transparent }) => (transparent ? "" : theme.colors.primary)};
  padding: 4px 16px;

  gap: 8px;

  color: ${colors?.white?.clean};
  background: ${({ transparent }) => (transparent ? "" : theme.colors.primary)};

  font-size: ${theme.font.size.x}px;
  font-weight: ${theme.font.weight.bold};

  align-items: ${({ alignItems }) => alignItems ?? "center"};
  justify-content: ${({ justifyContent }) => justifyContent ?? "space-between"};

  transition: all 0.5s;

  cursor: ${({ disabled }) => (!disabled ? "pointer" : undefined)};

  ${({ dimensions }) => DimensionStyling(dimensions)};
  ${({ padding }) => PaddingStyling(padding)};

  ${({ css }) => css};
`;

// TODO Implement local search feature
// const StyledSelectInput = styled.input``;

interface StyledSelectOptionsContainerProps {
  onTop?: boolean;
  css?: RuleSet<object>;
}

const StyledSelectOptionsContainer = styled.div<StyledSelectOptionsContainerProps>`
  width: fit-content;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 5px;
  background: ${colors?.white?.clean};
  box-sizing: border-box;
  padding: 8px;
  box-shadow: 0px 0px 6px ${theme.shadow.color};
  border-radius: 6px;
  z-index: 1;

  z-index: ${({ onTop }) => (onTop ? 1 : 0)};

  top: calc(100% + 10px);

  transition: all 0.5s;

  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: ${theme.colors.primary} ${colors.white.clean};

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: ${colors.white.clean};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.primary};
    border-radius: 10px;
  }

  ${({ css }) => css};

  max-height: 256px;
  overflow-y: auto;
`;

interface StyledSelectOptionWrapperProps {
  enableHoverEffect?: boolean;
  textColor?: string;
  css?: RuleSet<object>;
  isSelected?: boolean;
  padding?: PaddingStylingProps | CSS.Property.Padding;
  dimensions?: DimensionStylingProps;
}

const StyledSelectOptionWrapper = styled.div<StyledSelectOptionWrapperProps>`
  transition: all 0.2s;
  cursor: pointer;

  color: ${({ isSelected, textColor }) =>
    isSelected ? colors?.white?.clean : textColor || theme.font.color};
  background: ${({ isSelected }) =>
    isSelected ? theme.colors.primary : "transparent"};
  padding: 4px;
  border-radius: 6px;

  ${({ enableHoverEffect }) =>
    enableHoverEffect &&
    `
    &:hover {    
      background: ${colors?.grey?.gallery};
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.562);
      border-radius: 4px; 
    }
  `}
  

  ${({ dimensions }) => DimensionStyling(dimensions)};
  ${({ padding }) => PaddingStyling(padding)};

  ${({ css }) => css};
`;


export interface SelectOption {
  textColor?: string;
  label?: ReactNode;
  value?: any;
  searchValue?: string;
  disabled?: boolean;
}

interface SelectProps {
  options?: SelectOption[];

  //TODO Use 'defaultValue' for ReactForm adaptation
  defaultValue?: any;
  value?: any;
  setValue?: (value: any) => void;

  placeholder?: ReactNode;

  iconColor?: string;
  transparent?: boolean;
  justifyContent?: Property.JustifyContent;
  alignItems?: Property.AlignItems;
  onTop?: boolean;
  enableHoverEffect?: boolean;

  isClearable?: boolean;
  isSearchable?: boolean;
  isMultiselect?: boolean;
  closeOnSelectOption?: boolean;


  disabled?: boolean;
  hasCarrot?: boolean;

  style?: {
    valueWrapper?: StyleSelectValueWrapperProps;
    optionWrapper?: StyledSelectOptionWrapperProps;
    optionsContainer?: StyledSelectOptionsContainerProps;
  };
}

interface SelectState {
  isCollapsed?: boolean;
  mouseIn?: boolean;
}

export const Select = ({
  setValue,
  value,
  placeholder,
  transparent,
  justifyContent,
  alignItems,
  enableHoverEffect,
  onTop,
  options,
  closeOnSelectOption,
  style,
  disabled,
  hasCarrot = true,
  iconColor = colors.white.clean,
}: SelectProps) => {
  const { mouseClick } = useGlobalEvents();

  const [state, setState] = useState<SelectState>({
    isCollapsed: false,
    mouseIn: false,
  });

  useEffect(() => {
    if (!state?.mouseIn) setState({ ...state, isCollapsed: false });
  }, [mouseClick]);

  const selectedItem = (options || [])?.find(
    (option) => option?.value === value
  );

  return (
    <StyledSelectWrapper
      onMouseEnter={() => setState({ ...state, mouseIn: true })}
      onMouseLeave={() => setState({ ...state, mouseIn: false })}
    >
      <StyleSelectValueWrapper
        transparent={transparent}
        justifyContent={justifyContent}
        alignItems={alignItems}
        onClick={() => {
          !disabled && setState({ ...state, isCollapsed: !state?.isCollapsed });
        }}
        {...style?.valueWrapper}
      >
        {/* TODO Implement local search */}
        {/* {isSearchable && <StyledSelectInput />} */}
        {[undefined, null].includes(value) && placeholder}
        {![undefined, null].includes(value) && selectedItem?.label}
        {hasCarrot && (
          <Icon
            type={IconTypes.arrowDown}
            size="12px"
            color={iconColor}
          />
        )}

        {/* TODO Implement clearability feature */}
        {/* {isClearable} */}
      </StyleSelectValueWrapper>
      {state?.isCollapsed && (
        <StyledSelectOptionsContainer
          onTop={onTop}
          {...style?.optionsContainer}>
          {(options || [])?.map((option, index) => {
            return (
              <StyledSelectOptionWrapper
                className={
                  selectedItem?.value === option?.value ? "selected" : ""
                }
                textColor={option?.textColor}
                key={`option-${index} `}
                enableHoverEffect={enableHoverEffect}
                onClick={() =>
                  !option?.disabled &&
                  ((setValue && setValue(option?.value)) ||
                    (closeOnSelectOption &&
                      setState({ ...state, isCollapsed: false })))
                }
                isSelected={selectedItem?.value === option?.value}
                {...style?.optionWrapper}
              >
                {option?.label}
              </StyledSelectOptionWrapper>
            );
          })}

        </StyledSelectOptionsContainer>
      )}
    </StyledSelectWrapper>
  );
};
