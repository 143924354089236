import { Template } from "../../models/template";
import { httpGet } from "../utilities"

export const httpTemplateList = async (
  offset: number | undefined,
  limit: number | undefined,
  order: string[],
  filters: { [key: string]: any }
) => {

  return httpGet<Template[]>(`/template`, { offset: offset, limit: limit, ordering: order, ...filters })
}