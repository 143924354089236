import { css, styled } from "styled-components";
import { DimensionStyling, DimensionStylingProps } from "../../../styling";
import { colors, theme } from "../../../../constants";

interface DateButtonProps {
 dimensions?: DimensionStylingProps;
 type?: "before" | "in" | "after";
 isStart?: boolean;
 isEnd?: boolean;
 isSelecting?: boolean;
 isSelected?: boolean;
}

export const DateButton = styled.button<DateButtonProps>`
  transition: all 0.2s;

  cursor: pointer;
  border: none;

  background: ${({ type }) =>
  type === "in" ? colors?.white?.clean : colors?.grey.geyserLight};

  ${({ isEnd }) =>
  isEnd
   ? css`
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          font-weight: bold;
        `
   : ""};

  ${({ isStart }) =>
  isStart
   ? css`
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          font-weight: bold;
        `
   : ""};

  ${({ dimensions }) => DimensionStyling(dimensions)};

  ${({ isSelected }) =>
  isSelected
   ? css`
          background: ${theme.colors.primary};
        `
   : ""}

  &:hover {
    ${({ isSelected }) => {
  return css`
        background: ${isSelected
    ? colors?.blue?.cornflower
    : colors?.grey?.gallery};
      `;
 }}
  }
`;
