import { BrowserRouter, Routes } from "react-router-dom";
import { AuthProvider, ModalProvider } from "./contexts";

import AppRoutes from "./screens";
import { GlobalEventProvider } from "./contexts/GlobalEventProvider";
import { GlobalDataProvider } from "./contexts/GlobalDataProvider";

const App = () => {
  return (
    <GlobalEventProvider>
      <ModalProvider>
        <BrowserRouter>
          <AuthProvider>
            <GlobalDataProvider>
              <Routes>{AppRoutes}</Routes>
            </GlobalDataProvider>
          </AuthProvider>
        </BrowserRouter>
      </ModalProvider>
    </GlobalEventProvider>
  );
};

export default App;
