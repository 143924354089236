import { User } from "../../models/user"

import { httpPost } from "../utilities"

interface HttpAuthLoginData {
  email: string;
  password: string;
}
interface HttpAuthLoginResponse extends User {}

export const httpAuthLogin = async (data: HttpAuthLoginData) => {
  return httpPost<HttpAuthLoginResponse>('/auth', data);
}
