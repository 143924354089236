import { colors } from "./colors";

export const theme = {
  colors: {
    warning: colors.orange.sunshade,
    primary: colors.blue.dodger,
    secondary: colors.black.shipGray,
    success: colors.green.shamrock,
    danger: colors.red.alizarinCrimson,
    clean: colors.white.wildSand,
    disabled: colors.grey.silver
  },
  font: {
    color: colors.black.shipGray,
    size: {
      sx: 12,
      x: 14,
      xl: 16,
      xxl: 20
    },
    weight: {
      normal: 500,
      bold: 600
    }
  },
  border: {
    color: colors.grey.silver,
    invalid: colors.red.alizarinCrimson
  },
  shadow: {
    color:  colors.grey.noble
  },
  lead: {
    normal: colors.blue.dodger, 
    high: 'rgb(79 103 145)'
  }
}