import { css, styled } from "styled-components";
import { colors, theme } from "../../constants";
import { useAuth } from "../../contexts";
import { getAvatarUrl } from "../../utilities/avatar";
import { FlexRow, Image, Text, Title, Button, ButtonTypes } from "../../shared/styled";
import { Icon, IconTypes, Select } from "../../shared/components";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactNode } from "react";

const HeaderStyled = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;

  padding: 4px;

  background: ${theme.colors.clean};

  border-radius: 8px;

  box-shadow: 0px 0px 5px ${theme.shadow.color};
  width: 100%;

  height: 60px;

  align-items: center;
`;

const selectStyle = css`
  background: transparent;
  border: none;
`;

const selectStyleOptions = css`
  min-width: 160px;
  z-index: 1;
  right: 0;
`;

interface HeaderProps {
  customLeft?: ReactNode;
}

export const Header = ({customLeft}:HeaderProps) => {
 const { currentUser, logout } = useAuth();
 const navigate = useNavigate();
 const location = useLocation();

 const avatarUrl = getAvatarUrl(
  currentUser?.first_name || "Unknown",
  currentUser?.last_name || "Unknown",
  `${currentUser?.id}`
 );

 const isAdmin = currentUser?.roles?.find((role) => role?.includes("Admin"))
  ? true
  : false;

 const shouldRenderButton = location.pathname.startsWith("/admin/manage");

 const originalName = `${currentUser?.first_name} ${currentUser?.last_name}`;
 const userName = originalName.toUpperCase();

 const originalRoles = (currentUser?.roles || [])?.join(", ");
 const role = originalRoles.toUpperCase();

 const options =
  location.pathname.startsWith("/admin") && isAdmin
   ? [
    { value: "route,/agent/leads", label: <>Navigate to Agent</> },
    {
     value: "action,logout",
     label: <Text color={theme.colors.danger}>Logout</Text>,
    },
   ]
   : [
    ...(isAdmin
     ? [
      {
       value: "route,/admin/overview",
       label: <>Navigate to Admin</>,
      },
     ]
     : []),
    {
     value: "action,logout",
     label: <Text color={theme.colors.danger}>Logout</Text>,
    },
   ];

 return (
  <HeaderStyled>
   {shouldRenderButton && (
    <Button
     styleType={ButtonTypes.naked}
     padding={{ left: "8px", right: "8px", top: "4px", bottom: "4px" }}
     justifyContent="center"
     alignItems="center"
     dimensions={{ width: "auto", height: "40px" }}
     border={{ radius: "8px" }}
     onClick={() => navigate("/admin/users")}
    >
     <FlexRow gap="8px">
      <Icon
       type={IconTypes.arrowLeft}
       size="16px"
       color={theme.colors.secondary} />
      <Text color={theme.colors.secondary}>Back</Text>
     </FlexRow>
    </Button>
   )}
   {customLeft}
   <FlexRow
    margin={{ left: "auto" }}
    dimensions={{ height: "50px" }}
    alignItems="center"
    gap="10px"
   >
    <FlexRow alignItems="center" gap="10px">
     <Image
      padding="2px"
      background={colors.white.clean}
      border={{
       radius: "50%",
       width: "2px",
       color: theme.colors.primary,
      }}
      src={avatarUrl}
      dimensions={{ width: "42px", height: "42px" }}
     />
     <div>
      <Title color={theme.colors.secondary}>
       {userName}
      </Title>
      <Text size={`${theme.font.size.sx}px`}>
       {role}
      </Text>
     </div>
    </FlexRow>
    <Select
     closeOnSelectOption={true}
     setValue={(value: string) => {
      const [type, data] = value?.split(",");
      if (type === "route") {
       navigate(data);
       return;
      }

      if (data === "logout") {
       logout();
       return;
      }
     }}
     options={[...options]}
     placeholder={
      <Icon type={IconTypes.gear} size="32px" color={theme.font.color} />
     }
     style={{
      valueWrapper: { css: selectStyle },
      optionsContainer: { css: selectStyleOptions },
     }}
     hasCarrot={false}
    />
   </FlexRow>
  </HeaderStyled>
 );
};