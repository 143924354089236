import { Scroll } from "../../../../shared/components";
import { FlexColumn } from "../../../../shared/styled";
import { AgentProviderTable, AgentTable, ProviderTable } from "./segments";

export const Statistics = () => {
  return (
    <>
      <Scroll wrapperStyle={{ dimensions: { flex: 1 } }}>
        <FlexColumn
          dimensions={{ width: "100%" }}
          padding={{ left: "32px", right: "32px", top: "64px" }}
          gap="64px"
        >
          <ProviderTable />
          <AgentTable />
          <AgentProviderTable />
        </FlexColumn>
      </Scroll>
    </>
  );
};
