import { theme } from "../../../../constants";

export const EMAIL_TEMPLATER_TEXT_VARIABLE = {
  name: "system_variable",
  text: "Variable",
  icon: "typography",
  onAction: (data: { text: string }, callback: (value: string) => void) => {
    console.log(data);

    if (data.text.includes("flag-template-variable")) {
      callback(data.text.replace(/<\/?[^>]+(>|$)/g, "").replaceAll(".", " "));
      return;
    }

    callback(
      `<span class="flag-template-variable" style="background: ${
        theme.colors.primary
      };padding: 2px 0; color: white; border-radius: 2px;">${data.text
        .trim()
        .replaceAll(" ", ".")}</span>`
    );
  },
};
