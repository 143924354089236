import { useEffect, useState } from "react";
import { useAuth } from "../../../contexts";
import { Table } from "./components/Table";
import { Icon, IconTypes, Toggle } from "../../../shared/components";
import { User } from "../../../models/user";
// import {
// TODO import overview api
// } from "../../../apis/admin/overview";
import { httpAuthGetCurrentUser } from "../../../apis/auth/getCurrentUser";
import { FlexRow, Badge, FlexColumn, TitleXXL, TitleXL, Text, Card } from "../../../shared/styled";
import { theme, colors } from "../../../constants";

export const Overview = () => {
    const { currentUser } = useAuth();

    const [userData, setUserData] = useState<User>();
    const [data, setData] = useState<User[]>([]);

    const fetch = async () => {
        try {
            const response = await httpAuthGetCurrentUser();
            setUserData(response?.data);
        } catch (err) { }
    };

    useEffect(() => {
        fetch();
    }, []);

    // TODO set up fetch
    // const fetch = async (reset = false) => {
    //     //setLoading(true);
    //     try {
    //         const response = await httpAdminGetUsers(search, offset, limit, order);

    //         if (reset)
    //             setData((response?.data as HttpAdminGetUsersResponse)?.results);
    //     } catch (err) { }
    //     setLoading(false);
    // };

    // useEffect(() => {
    //     fetch(true);
    // }, [order, limit, offset]);

    return (
        <FlexColumn dimensions={{ width: "100%" }} padding={{ left: "32px", right: "32px", top: "64px" }} gap="64px">
            <FlexRow justifyContent="start" dimensions={{ width: "100%" }}>
                <FlexColumn gap="8px">
                    <TitleXXL>Hi {userData?.first_name} {userData?.last_name}</TitleXXL>
                    <Text>Welcome back to Flix Admin dashboard</Text>
                </FlexColumn>
            </FlexRow>
            <FlexColumn dimensions={{ width: "100%" }} gap="32px">
                <FlexRow dimensions={{ width: "100%", height: "160px" }} gap="16px" justifyContent="space-between">
                    <Card
                        flexDirection="row"
                        gap="80px"
                        background={{ color: `${colors.white.clean}` }}
                        dimensions={{ width: "100%", height: "90%" }}
                        padding={{ top: "32px", bottom: "32px", left: "32px", right: "32px" }}
                        style={{ boxShadow: `0px 1px 3px 0px ${theme.shadow.color}`, borderRadius: "12px" }}>
                        <FlexColumn gap="16px" dimensions={{ width: "100%", height: "100%" }}>
                            <TitleXXL>154</TitleXXL>
                            <Text>Received Leads</Text>
                        </FlexColumn>
                        <Icon type={IconTypes.people} color={theme.colors.primary} size="48px" />
                    </Card>
                    <Card
                        flexDirection="row"
                        gap="80px"
                        background={{ color: `${colors.white.clean}` }}
                        dimensions={{ width: "100%", height: "90%" }}
                        padding={{ top: "32px", bottom: "32px", left: "32px", right: "32px" }}
                        style={{ boxShadow: `0px 1px 3px 0px ${theme.shadow.color}`, borderRadius: "12px" }}>
                        <FlexColumn gap="16px" dimensions={{ width: "100%", height: "100%" }}>
                            <TitleXXL>92</TitleXXL>
                            <Text>Calls</Text>
                        </FlexColumn>
                        <Icon type={IconTypes.call} color={theme.colors.primary} size="48px" />
                    </Card>
                    <Card
                        flexDirection="row"
                        gap="80px"
                        background={{ color: `${colors.white.clean}` }}
                        dimensions={{ width: "100%", height: "90%" }}
                        padding={{ top: "32px", bottom: "32px", left: "32px", right: "32px" }}
                        style={{ boxShadow: `0px 1px 3px 0px ${theme.shadow.color}`, borderRadius: "12px" }}>
                        <FlexColumn gap="16px" dimensions={{ width: "100%", height: "100%" }}>
                            <TitleXXL>16</TitleXXL>
                            <Text>Booked Leads</Text>
                        </FlexColumn>
                        <Icon type={IconTypes.people} color={theme.colors.primary} size="48px" />
                    </Card>
                    <Card
                        flexDirection="row"
                        gap="80px"
                        background={{ color: `${colors.white.clean}` }}
                        dimensions={{ width: "100%", height: "90%" }}
                        padding={{ top: "32px", bottom: "32px", left: "32px", right: "32px" }}
                        style={{ boxShadow: `0px 1px 3px 0px ${theme.shadow.color}`, borderRadius: "12px" }}>
                        <FlexColumn gap="16px" dimensions={{ width: "100%", height: "100%" }}>
                            <TitleXXL>$1.42k</TitleXXL>
                            <Text>Expected Revenue</Text>
                        </FlexColumn>
                        <Icon type={IconTypes.money} color={theme.colors.primary} size="48px" />
                    </Card>
                </FlexRow>
                <Table <User>
                    header={(value) => true}
                    tableShadow={`0px 1px 3px 0px ${theme.shadow.color}`}
                    columns={[
                        {
                            label: "No",
                            key: "id",
                            styling: { maxWidth: "100%" },
                            mapper: (item) => item?.id || <>-</>,
                        },
                        {
                            label: "Agent",
                            key: "id",
                            styling: { maxWidth: "100%" },
                            mapper: (item) => `${item?.first_name} ${item?.last_name}`,
                        },
                        {
                            label: "Leads",
                            key: "leads",
                            styling: { maxWidth: "100%" },
                            mapper: (item) => item?.email || <>-</>,
                        },
                        {
                            label: "Calls",
                            key: "calls",
                            styling: { maxWidth: "100%" },
                            mapper: (item) => (
                                <FlexRow gap="5px">
                                    {item?.roles?.map((role) => (
                                        <Badge>{role}</Badge>
                                    ))}
                                    {item?.agent_roles?.map((agentRole) => (
                                        <Badge>{agentRole.name}</Badge>
                                    ))}
                                </FlexRow>
                            ),
                        },
                        {
                            label: "Booked Leads",
                            key: "booked_leads",
                            styling: { maxWidth: "100%" },
                            //mapper: (item) => item?.status || <>-</>,
                        },
                        {
                            label: "Average Response",
                            key: "average_response",
                            styling: { maxWidth: "100%" },
                            //mapper: (item) => item?.status || <>-</>,
                        },
                        {
                            label: "Rate",
                            key: "rate",
                            styling: { maxWidth: "100%" },
                            //mapper: (item) => item?.status || <>-</>,
                        },
                    ]}
                    data={data}
                    headerBackground={colors.white.clean}
                    headerDimensions={{ width: "100%", height: "60px" }}
                    justifyHeaderContent="start"
                    headerComponent={
                        <FlexColumn gap="8px" justifyContent="flex" padding={{ top: "32px", bottom: "32px" }}>
                            <TitleXL>Performance</TitleXL>
                            <Text>Today</Text>
                        </FlexColumn>
                    } />
            </FlexColumn>
        </FlexColumn >
    )
};