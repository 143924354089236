import { useState } from "react";
import { styled } from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { colors, theme } from "../../constants";
import { Icon, IconTypes } from "../../shared/components";
import { FlexColumn, FlexRow, Text } from "../../shared/styled";
import AnimatedCar from "../../shared/particles/Car";

interface NavbarProps {
 collapsed?: boolean;
}

const NavbarStyle = styled.div<NavbarProps>`
 width: ${({ collapsed }) => (!collapsed ? "100px" : "200px")};
 height: 100vh;

 display: flex;
 flex-direction: column;

 transition: 0.5s all;

 background: ${theme.colors.primary};

 box-sizing: border-box;
 padding: 4px;
`;

interface NavbarItemProp {
 selected?: boolean;
 collapsed?: boolean;
}

const NavbarItem = styled.div<NavbarItemProp>`
 transition: all 0.5s;

 background: ${({ selected }) => (selected ? colors.white.clean : theme.colors.primary)};
 color: ${({ selected }) => (selected ? theme.colors.primary : colors.white.clean)};

 display: flex;
 flex-direction: row;

 box-sizing: border-box;
 width: 100%;
 height: 48px;

 justify-content: ${({ collapsed }) => (collapsed ? "space-between" : "center")};
 align-items: center;
 padding: 4px 16px;

 border-radius: 12px;
 cursor: pointer;
`;

export interface NavbarRoute {
 path: string;
 icon?: IconTypes;
 name?: string;
 id?: string;
}

interface NavbarProps {
 routes?: NavbarRoute[];
}

export const Navbar = ({ routes }: NavbarProps) => {
 const location = useLocation();
 const navigate = useNavigate();

 const [isCollapsed, setCollapsed] = useState<boolean>(false);
 const [isHovering, setHovering] = useState<boolean>(false);

 const selectedRoute = (routes || [])?.find((route) => route?.path === location?.pathname);

 return (
  <NavbarStyle collapsed={isCollapsed} onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
   <FlexRow
    padding="4px"
    onClick={() => setCollapsed(!isCollapsed)}
    dimensions={{ width: "100%" }}
    justifyContent={isCollapsed ? "space-between" : "space-around"}
    style={{
     cursor: "pointer",
     position: "relative",
     transition: "0.5s all",
    }}
   >
    {isCollapsed && !isHovering && <AnimatedCar close={() => setCollapsed(false)} />}
    <img src="/assets/icons/flix-white-navbar-part-01.svg" style={{ zIndex: 1 }} />
    <img src="/assets/icons/flix-white-navbar-part-02.svg" style={{ zIndex: 1 }} />
   </FlexRow>
   <FlexColumn gap="10px" margin={{ top: "40px" }} padding="4px">
    {(routes || [])?.map((route) => (
     <NavbarItem
      selected={selectedRoute?.id === route?.id}
      collapsed={isCollapsed}
      onClick={() => navigate(route?.path)}
     >
      {route?.icon && (
       <Icon
        size="28px"
        type={route?.icon}
        color={selectedRoute?.id === route?.id ? theme.colors.primary : colors?.white?.clean}
       />
      )}
      {isCollapsed && route?.name && (
       <Text
        color={selectedRoute?.id === route?.id ? theme.colors.primary : colors?.white?.clean}
        size={`${theme.font.size.xl}px`}
        weight="bold"
       >
        {route?.name?.toUpperCase()}
       </Text>
      )}
     </NavbarItem>
    ))}
   </FlexColumn>
  </NavbarStyle>
 );
};
