import { User } from "../../models/user"

import { httpGet } from "../utilities"


interface HttpAuthGetCurrentUserResponse extends User {}

export const httpAuthGetCurrentUser = async () => {
  return httpGet<HttpAuthGetCurrentUserResponse>('/user/current')
}
