import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Template, TEMPLATE_TYPE_EMAIL } from "../models/template";
import { useAuth } from "./AuthProvider";
import { httpTemplateList } from "../apis/templates";
import toast from "react-hot-toast";

interface GlobalDataContextInterface {
  emailTemplates: Template[];
}

const GlobalDataContext = createContext<GlobalDataContextInterface>({
  emailTemplates: [],
});

interface GlobalDataProviderInterface {
  children?: React.ReactNode;
}

export function GlobalDataProvider({ children }: GlobalDataProviderInterface) {
  const { currentUser } = useAuth();

  const isLoadingTemplates = useRef<boolean>(false);
  const [emailTemplates, setEmailTemplates] =
    useState<GlobalDataContextInterface["emailTemplates"]>();

  const loadEmailTemplates = useCallback(() => {
    if (!currentUser || isLoadingTemplates.current || emailTemplates) return;
    isLoadingTemplates.current = true;

    httpTemplateList(undefined, undefined, ["name"], {
      type: TEMPLATE_TYPE_EMAIL,
    })
      .then((res) => res.data)
      .then((data) => setEmailTemplates(data))
      .catch(() => toast.error("Failed to load email templates!"))
      .finally(() => {
        isLoadingTemplates.current = false;
      });
  }, [emailTemplates, currentUser]);

  useEffect(() => {
    loadEmailTemplates();
  }, [currentUser]);

  return (
    <GlobalDataContext.Provider
      value={{
        emailTemplates: emailTemplates ?? [],
      }}
    >
      {children}
    </GlobalDataContext.Provider>
  );
}

export function useGlobalData() {
  return useContext(GlobalDataContext);
}
