import { Lead } from "../../models/lead";
import { User } from "../../models/user";
import { Pagination } from "../../types";
import { LeadUnclaimAction } from "../types";

export const leadUnclaimAction = (
  currentUser: User,
  data: LeadUnclaimAction["data"],
  setLeads: (leads: Lead[]) => void,
  leads: Lead[],
  //TODO Create Interface for filters
  filters?: any,
  pagination?: Pagination,
  setPagination?: (pagination: Pagination) => void
) => {
  setLeads(
    leads.map((lead) => {
      if (Number(data.id) !== lead.id) return lead;

      return {
        ...lead,
        agent: null,
      };
    })
  );
};
