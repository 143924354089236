import { useEffect, useState, useRef, useMemo } from "react";
import { css, styled } from "styled-components";

import { colors, theme } from "../../constants";
import {
  Badge,
  Button,
  ButtonTypes,
  FlexColumn,
  FlexItem,
  FlexRow,
  Image,
  Text,
  TitleXL,
} from "../../shared/styled";
import { PriorityMap } from "../../models/lead/priorities";
import { LeadFilters as LeadFiltersInterface } from "../../models/lead/types";
import { httpGetTotalLeads } from "../../apis/leads";
import { Icon, IconTypes } from "../../shared/components";
import {
  SearchSelect,
  SelectOption,
} from "../../shared/components/Select/SearchSelect";
import { StyledBaseInput } from "../../shared/components/Input/StyledBaseInput";
import { useAuth, useModalProvider } from "../../contexts";
import { httpAdminGetUsers } from "../../apis/admin";
import { User } from "../../models/user";
import { getAvatarUrl } from "../../utilities/avatar";
import { DateRangeFilterModal } from "../../modals";
import {
  toQueryParamDate,
  toQueryParamDateTime,
  toReadableDateOnly,
} from "../../utilities/datetime";
import { DateRangeValue } from "../../shared/components/DateRange/types";
import { useSearchParams } from "react-router-dom";

interface LeadFilterWrapperProps {
  $isVisible?: boolean;
}

const LeadFiltersWrapper = styled.div<LeadFilterWrapperProps>`
  display: flex;
  flex-direction: row;

  width: 100%;
  height: ${({ $isVisible }) => ($isVisible ? "140px" : "0px")};
  justify-content: space-between;

  box-sizing: border-box;
  padding: ${({ $isVisible }) => ($isVisible ? "16px 20px" : "0px 20px")};

  * {
    transition: all 0.2s;
  }

  transition: all 0.5s;

  overflow: ${({ $isVisible }) => ($isVisible ? "visible" : "hidden")};
`;

interface LeadFiltersProps {
  filters?: LeadFiltersInterface;
  setFilters?: (filters: LeadFiltersInterface) => void;
}

export const LeadFilters = ({ filters, setFilters }: LeadFiltersProps) => {
  const { currentUser } = useAuth();
  const { openModal, closeModal } = useModalProvider();
  const [searchParams] = useSearchParams();

  const [allUsers, setAllUsers] = useState<User[]>();
  const [priority, setPriority] = useState<number[] | undefined>([]);
  const [claimedByAgent, setClaimedByAgent] = useState<number>();

  const [search, setSearch] = useState<string>();

  const inputRef = useRef<HTMLInputElement | null>(null);
  const cursorPositionRef = useRef(0);

  const [totalLeads, setTotalLeads] = useState<number>();

  const [createdAtRange, setCreatedAtRange] = useState<DateRangeValue>({});
  const [moveRange, setMoveRange] = useState<DateRangeValue>({});

  const isAdmin = (currentUser?.roles ?? []).some((role) =>
    role?.includes("Admin")
  );

  const fetchUsers = async () => {
    if (!isAdmin) return;

    try {
      const response = await httpAdminGetUsers(
        undefined,
        undefined,
        undefined,
        ["first_name", "last_name"],
        { is_disabled: false, is_active: true, is_deleted: false }
      );
      setAllUsers(response?.data as User[]);
    } catch (err) {
      //TODO Error handling;
      console.log("LeadFilters.fetchUsers", err);
    }
  };

  const fetchData = async () => {
    try {
      if (filters?.agent__id) {
        const response = await httpGetTotalLeads(filters?.agent__id);
        setTotalLeads(response?.data?.total);
      }
    } catch (error) {
      console.error("Error fetching total leads:", error);
    }
  };

  useEffect(() => {
    if (setFilters) {
      let updatedFilters = {
        agent__id: claimedByAgent === -1 ? undefined : claimedByAgent,
        search: search,
        created_at__gte:
          createdAtRange?.start && createdAtRange?.end
            ? toQueryParamDateTime(createdAtRange?.start, false)
            : undefined,
        created_at__lte:
          createdAtRange?.start && createdAtRange?.end
            ? toQueryParamDateTime(createdAtRange?.end, true)
            : undefined,
        ship_date__gte:
          moveRange?.start && moveRange?.end
            ? toQueryParamDate(moveRange?.start)
            : undefined,
        ship_date__lte:
          moveRange?.start && moveRange?.end
            ? toQueryParamDate(moveRange?.end)
            : undefined,
      };

      if (priority && priority.length > 0) {
        updatedFilters = {
          ...updatedFilters,
          ...(priority.length === 1
            ? { priority: priority[0] }
            : { status__in: priority.join(",") }),
        };
      }

      if (filters?.agent__id) {
        fetchData();
      }

      setFilters(updatedFilters);
    }
  }, [priority, claimedByAgent, search, createdAtRange, moveRange]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.setSelectionRange(
        cursorPositionRef.current,
        cursorPositionRef.current
      );
    }
  }, [search]);

  const handleInput = (event: React.FormEvent<HTMLInputElement>) => {
    let inputValue = (event.currentTarget as HTMLInputElement).value;
    if (search?.length !== inputValue?.length) {
      if (!Number.isNaN(Number(inputValue.replaceAll(" ", "").replaceAll("(","").replaceAll(")","").replaceAll("-",""))))
        inputValue = inputValue.replaceAll(" ", "");
    }

    const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9@.,\s]/g, "");

    cursorPositionRef.current =
      (event.currentTarget as HTMLInputElement).selectionStart || 0;

    setSearch(sanitizedValue);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const isVisible = searchParams.get("showFilters") === "true";
  const selectSearchValues: SelectOption[] = useMemo(() => {
    return (allUsers ?? [])?.map((user) => {
      const avatarUrl = getAvatarUrl(
        user?.first_name || "Unknown",
        user?.last_name || "Unknown",
        `${user?.id}`
      );

      return {
        value: user?.id,
        searchValue: `${user?.first_name} ${user?.last_name}`,
        label: (
          <FlexRow
            alignItems="center"
            justifyContent="space-between"
            key={`agentOption-${user?.id}`}
            gap="5px"
            dimensions={{ width: "100%" }}
          >
            <Image
              padding="2px"
              background={colors.white.clean}
              border={{
                radius: "50%",
                width: "2px",
                color: theme.colors.primary,
              }}
              src={avatarUrl}
              dimensions={{ width: "20px", height: "20px" }}
            />
            <Text>
              {user?.first_name} {user?.last_name}
            </Text>
          </FlexRow>
        ),
      };
    });
  }, [allUsers, filters?.agent__id]);

  return (
    <LeadFiltersWrapper $isVisible={isVisible}>
      <FlexColumn gap="16px">
        <FlexRow gap="5px">
          {isAdmin && (
            <FlexRow dimensions={{ maxWidth: "200px" }}>
              <SearchSelect
                options={selectSearchValues}
                value={claimedByAgent}
                onChange={(value) => setClaimedByAgent(value?.value as number)}
                placeholder="SELECT AGENT"
                isClearable={true}
                isSearchable={true}
                transparent={true}
                iconColor={theme.colors.primary}
              />
            </FlexRow>
          )}
          <Button
            styleType={
              !filters?.agent__id__isnull
                ? ButtonTypes.primary
                : ButtonTypes.primaryReverse
            }
            onClick={() =>
              setFilters &&
              setFilters({
                ...filters,
                agent__id__isnull: filters?.agent__id__isnull
                  ? undefined
                  : true,
              })
            }
          >
            <Text
              color={
                !filters?.agent__id__isnull
                  ? theme.colors.clean
                  : theme.colors.primary
              }
            >
              ONLY FREE
            </Text>
          </Button>
        </FlexRow>
        <FlexColumn gap="16px">
          <FlexRow gap="10px">
            {Object.keys(PriorityMap).map((priorityKey) => (
              <Button
                border={{
                  color:
                    priority && priority.includes(Number(priorityKey))
                      ? theme.font.color
                      : "white",
                }}
                background={PriorityMap[priorityKey as any]?.color}
                dimensions={{ width: "28px", height: "28px" }}
                onClick={() => {
                  if (priority && priority.includes(Number(priorityKey))) {
                    setPriority(
                      priority.filter((p) => p !== Number(priorityKey))
                    );
                  } else {
                    setPriority([...(priority || []), Number(priorityKey)]);
                  }
                }}
              />
            ))}
          </FlexRow>
        </FlexColumn>
      </FlexColumn>
      <FlexColumn gap="10px" dimensions={{ minWidth: "180px" }}>
        <StyledBaseInput
          placeholder="Search"
          dimensions={{ width: "100%", minHeight: "32px" }}
          type="text"
          value={search}
          onInput={handleInput}
          css={css`
            box-sizing: border-box;
          `}
        />
        <Button
          styleType={ButtonTypes?.primary}
          dimensions={{ height: "32px" }}
          onClick={() =>
            openModal("createdAtLeadFilterModal", DateRangeFilterModal, {
              setValue: setCreatedAtRange,
              value: createdAtRange,
              close: () => closeModal("createdAtLeadFilterModal"),
              title: <TitleXL>Created at Range</TitleXL>,
            })
          }
        >
          <FlexRow justifyContent="space-between" alignItems="center" gap="5px">
            <Icon
              type={IconTypes?.calendar}
              size="20px"
              color={colors?.white?.clean}
            />
            <FlexItem dimensions={{ flex: 1 }}>
              <Text color={colors?.white?.clean} align="center">
                {(!createdAtRange?.start || !createdAtRange?.end) && (
                  <>CREATED</>
                )}
                {createdAtRange?.start && createdAtRange?.end && (
                  <>
                    {toReadableDateOnly(createdAtRange?.start)} -{" "}
                    {toReadableDateOnly(createdAtRange?.end)}
                  </>
                )}
              </Text>
            </FlexItem>
          </FlexRow>
        </Button>
        <Button
          styleType={ButtonTypes?.primary}
          dimensions={{ height: "32px" }}
          onClick={() =>
            openModal("shipmentAtLeadFilterModal", DateRangeFilterModal, {
              setValue: setMoveRange,
              value: moveRange,
              close: () => closeModal("shipmentAtLeadFilterModal"),
              title: <TitleXL>Move Range</TitleXL>,
            })
          }
        >
          <FlexRow alignItems="center">
            <Icon
              type={IconTypes?.calendar}
              size="20px"
              color={colors?.white?.clean}
            />
            <FlexItem dimensions={{ flex: 1 }}>
              <Text align="center" color={colors?.white?.clean}>
                {(!moveRange?.start || !moveRange?.end) && <>MOVING</>}
                {moveRange?.start && moveRange?.end && (
                  <>
                    {toReadableDateOnly(moveRange?.start)} -{" "}
                    {toReadableDateOnly(moveRange?.end)}
                  </>
                )}
              </Text>
            </FlexItem>
          </FlexRow>
        </Button>
      </FlexColumn>
    </LeadFiltersWrapper>
  );
};
