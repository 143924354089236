import { EMAIL_TEMPLATES } from "../../../templates/email";
import { SMS_TEMPLATES } from "../../../templates/sms";

export const EMAIL_TEMPLATE_OPTIONS = Object.keys(EMAIL_TEMPLATES).map(
  (templateKey) => ({
    value: templateKey,
    label:
      templateKey
        ?.toLocaleLowerCase()
        ?.split("_")
        .join(" ")
        .slice(0, 1)
        .toUpperCase() +
      templateKey?.toLocaleLowerCase()?.split("_").join(" ").slice(1),
  })
);

export const SMS_TEMPLATE_OPTIONS = Object.keys(SMS_TEMPLATES).map(
  (templateKey) => ({
    value: templateKey,
    label:
      templateKey
        ?.toLocaleLowerCase()
        ?.split("_")
        .join(" ")
        .slice(0, 1)
        .toUpperCase() +
      templateKey?.toLocaleLowerCase()?.split("_").join(" ").slice(1),
  })
);
