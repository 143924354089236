import { useEffect, useState, useRef } from "react";
import { css, styled } from "styled-components";

import { theme, colors } from "../../../../../constants";
import {
  Button,
  ButtonTypes,
  FlexColumn,
  FlexItem,
  FlexRow,
  Image,
  Text,
  TitleXL,
} from "../../../../../shared/styled";
import {
  Icon,
  IconTypes,
  LoadingImage,
  Select,
  SelectOption,
} from "../../../../../shared/components";
import { StyledBaseInput } from "../../../../../shared/components/Input/StyledBaseInput";
import { useAuth, useModalProvider } from "../../../../../contexts";
import { httpAdminGetUsers } from "../../../../../apis/admin";
import { getAvatarUrl } from "../../../../../utilities/avatar";
import { User } from "../../../../../models/user";
import { LeadFilters as LeadFiltersInterface } from "../../../../../models/lead/types";
import { PriorityMap } from "../../../../../models/lead/priorities";
import { DateRangeFilterModal } from "../../../../../modals";
import {
  toQueryParamDate,
  toQueryParamDateTime,
  toReadableDateOnly,
} from "../../../../../utilities/datetime";
import { DateRangeValue } from "../../../../../shared/components/DateRange/types";

const LeadFiltersWrapper = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;
  justify-content: space-between;

  box-sizing: border-box;
  padding: 16px 20px;

  * {
    transition: all 0.2s;
  }
`;

interface LeadFiltersProps {
  filters?: LeadFiltersInterface;
  setFilters?: (filters: LeadFiltersInterface) => void;
}

export const LeadFilters = ({ filters, setFilters }: LeadFiltersProps) => {
  const { currentUser } = useAuth();
  const { openModal, closeModal } = useModalProvider();

  const [allUsers, setAllUsers] = useState<SelectOption[]>();
  const [priority, setPriority] = useState<number[] | undefined>([]);
  const [claimedByAgent, setClaimedByAgent] = useState<number>();

  const [search, setSearch] = useState<string>();

  const inputRef = useRef<HTMLInputElement | null>(null);
  const cursorPositionRef = useRef(0);

  const [createdAtRange, setCreatedAtRange] = useState<DateRangeValue>({});
  const [moveRange, setMoveRange] = useState<DateRangeValue>({});

  const isAdmin = currentUser?.roles?.find((role) => role?.includes("Admin"))
    ? true
    : false;

  const fetchUsers = async () => {
    if (!isAdmin) return;

    try {
      const response = await httpAdminGetUsers();
      setAllUsers([
        { value: -1, label: "Any Agent" },
        ...(response?.data as User[])?.map((user) => {
          const avatarUrl = getAvatarUrl(
            user?.first_name || "Unknown",
            user?.last_name || "Unknown",
            `${user?.id}`
          );

          return {
            value: user?.id,
            label: (
              <FlexRow alignItems="center" justifyContent="space-between">
                <Image
                  padding="2px"
                  background={colors.white.clean}
                  border={{
                    radius: "50%",
                    width: "2px",
                    color: theme.colors.primary,
                  }}
                  src={avatarUrl}
                  dimensions={{ width: "20px", height: "20px" }}
                />
                <Text>
                  {user?.first_name} {user?.last_name}
                </Text>
              </FlexRow>
            ),
          };
        }),
      ]);
    } catch (err) {
      //TODO Error handling;
      console.log("LeadFilters.fetchUsers", err);
    }
  };

  useEffect(() => {
    if (!setFilters) return;

    let updatedFilters: any = {
      agent__id: claimedByAgent === -1 ? undefined : claimedByAgent,
      search: search,
      created_at__gte:
        createdAtRange?.start && createdAtRange?.end
          ? toQueryParamDateTime(createdAtRange?.start, false)
          : undefined,
      created_at__lte:
        createdAtRange?.start && createdAtRange?.end
          ? toQueryParamDateTime(createdAtRange?.end, true)
          : undefined,
      ship_date__gte:
        moveRange?.start && moveRange?.end
          ? toQueryParamDate(moveRange?.start)
          : undefined,
      ship_date__lte:
        moveRange?.start && moveRange?.end
          ? toQueryParamDate(moveRange?.end)
          : undefined,
    };

    if (priority && 0 < priority.length) {
      updatedFilters.priority__in = priority.join(",")
    }

    setFilters(updatedFilters);

  }, [priority, claimedByAgent, search, createdAtRange, moveRange]);


  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.setSelectionRange(cursorPositionRef.current, cursorPositionRef.current);
    }
  }, [search]);

  const handleInput = (event: React.FormEvent<HTMLInputElement>) => {
    const inputValue = (event.currentTarget as HTMLInputElement).value;
    const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9@.,\s]/g, '');

    cursorPositionRef.current = (event.currentTarget as HTMLInputElement).selectionStart || 0;

    setSearch(sanitizedValue);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <LeadFiltersWrapper>
      <FlexColumn gap="16px">
        <FlexRow gap="10px">
          {Object.keys(PriorityMap).map((priorityKey) => (
            <Button
              border={{
                color: priority && priority.includes(Number(priorityKey))
                  ? theme.font.color
                  : "white",
              }}
              background={PriorityMap[priorityKey as any]?.color}
              dimensions={{ width: "28px", height: "28px" }}
              onClick={() => {
                if (priority && priority.includes(Number(priorityKey))) {
                  setPriority(priority.filter((p) => p !== Number(priorityKey)));
                } else {
                  setPriority([...(priority || []), Number(priorityKey)]);
                }
              }}
            />
          ))}
        </FlexRow>
        <FlexRow>
          {isAdmin && (
            <Select
              style={{
                valueWrapper: {
                  dimensions: { width: "300px", minHeight: "32px" },
                  css: css`
                    background: ${!allUsers
                      ? theme?.colors?.clean
                      : colors?.white?.clean};
                  `,
                },
                optionWrapper: {
                  enableHoverEffect: true,
                }
              }}
              iconColor={colors?.grey?.tundora}
              placeholder={
                <FlexRow
                  justifyContent="flex-start"
                  alignItems="center"
                  dimensions={{ width: "100%" }}
                >
                  {!allUsers && (
                    <LoadingImage
                      src="/assets/images/loading.svg"
                      dimensions={{ width: "16px", height: "16px" }}
                    />
                  )}
                  {allUsers && <Text color={colors?.grey?.silverChalice} align="left">Claimed By</Text>}
                </FlexRow>
              }
              options={allUsers}
              value={claimedByAgent}
              setValue={(value) => setClaimedByAgent(value)}
              closeOnSelectOption={true}
            />
          )}
        </FlexRow>
      </FlexColumn>
      <FlexColumn gap="10px" dimensions={{ minWidth: "280px" }}>
        <StyledBaseInput
          placeholder="Search"
          dimensions={{ width: "100%", minHeight: "32px" }}
          type="text"
          value={search}
          onInput={handleInput}
          css={css`
    box-sizing: border-box;
  `} />
        <FlexRow justifyContent="space-between" alignItems="center" gap="8px">
          <Button
            border={{ color: colors?.grey?.geyser, radius: "8px" }}
            styleType={ButtonTypes?.clean}
            dimensions={{ height: "32px", width: "100%" }}
            onClick={() =>
              openModal("createdAtLeadFilterModal", DateRangeFilterModal, {
                setValue: setCreatedAtRange,
                value: createdAtRange,
                close: () => closeModal("createdAtLeadFilterModal"),
                title: <TitleXL>Created at Range</TitleXL>,
              })
            }
          >
            <FlexRow justifyContent="space-between" alignItems="center" gap="5px">
              <Icon
                type={IconTypes?.calendar}
                size="20px"
                color={colors?.grey?.tundora}
              />
              <FlexItem dimensions={{ flex: 1 }}>
                <Text align="center" color={colors?.grey?.tundora} weight="lighter">
                  {(!createdAtRange?.start || !createdAtRange?.end) && (
                    <>Created Range</>
                  )}
                  {createdAtRange?.start && createdAtRange?.end && (
                    <>
                      {toReadableDateOnly(createdAtRange?.start)} -{" "}
                      {toReadableDateOnly(createdAtRange?.end)}
                    </>
                  )}
                </Text>
              </FlexItem>
            </FlexRow>
          </Button>
          <Button
            styleType={ButtonTypes?.clean}
            border={{ color: colors?.grey?.geyser, radius: "8px" }}
            dimensions={{ height: "32px", width: "100%" }}
            onClick={() =>
              openModal("shipmentAtLeadFilterModal", DateRangeFilterModal, {
                setValue: setMoveRange,
                value: moveRange,
                close: () => closeModal("shipmentAtLeadFilterModal"),
                title: <TitleXL>Move Range</TitleXL>,
              })
            }
          >
            <FlexRow alignItems="center">
              <Icon
                type={IconTypes?.calendar}
                size="20px"
                color={colors?.grey?.tundora}
              />
              <FlexItem dimensions={{ flex: 1 }}>
                <Text align="center" color={colors?.grey?.tundora} weight="lighter">
                  {(!moveRange?.start || !moveRange?.end) && <>Moving Range</>}
                  {moveRange?.start && moveRange?.end && (
                    <>
                      {toReadableDateOnly(moveRange?.start)} -{" "}
                      {toReadableDateOnly(moveRange?.end)}
                    </>
                  )}
                </Text>
              </FlexItem>
            </FlexRow>
          </Button>
        </FlexRow>
      </FlexColumn>
    </LeadFiltersWrapper>
  );
};
