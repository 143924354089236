import { ActionGeneric } from "./types";

export const websocketConnect = (
  setAction: (action: ActionGeneric<string, any>) => void
) => {
  try {
    const liveConnection = new WebSocket(
      process.env.REACT_APP_WEBSOCKET_LISTENER as string
    );

    liveConnection.onmessage = (event) => {
      setAction(JSON.parse(event.data));
    };

    liveConnection.onerror = (error) => {
      console.log("ERROR IN RT MODULE", error);
      liveConnection.close()
    }

    return liveConnection;
  } catch (err) {
    console.log("LIVE CONNECTION FAILED:", err);
  }
};
