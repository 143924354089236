import { SectionSchema } from "../types/sectionSchema";

import { MapSection } from "./MapSection";
import { MapSectionDocumentation } from "./documentation";

export const MapSectionSchema: SectionSchema = {
  component: MapSection,
  documentation: MapSectionDocumentation,
  label: "Map",
  id: "map"
}