import styled from "styled-components";
import { colors, theme } from "../../../../../constants";
import { Lead } from "../../../../../models/lead";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import HTMLReactParser, { domToReact } from "html-react-parser";
import Draggable from "react-draggable";
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css"; // Include this for basic resizable styles
import { Icon, IconTypes, Loading } from "../../../../../shared/components";
import { ParsedTemplate, Template } from "../../../../../models/template";
import {
  Button,
  ButtonTypes,
  FlexColumn,
  FlexRow,
  Title,
} from "../../../../../shared/styled";
import {
  StyledBaseInput,
  StyledBaseTextArea,
} from "../../../../../shared/components/Input/StyledBaseInput";
import { useAuth } from "../../../../../contexts";
import { httpMsPushEmail } from "../../../../../apis/microservice";
import {
  serializeAgent,
  serializeLead,
  templateByContext,
} from "../../../../../utilities/templating";
import { useGlobalData } from "../../../../../contexts/GlobalDataProvider";

const PopupWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: "fit-content";
`;

const LeadTemplateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;

  width: 100%;
  height: 100%;

  gap: 5px;

  background: ${colors.white.clean};
  border-radius: 5px;

  box-sizing: border-box;

  box-shadow: 0px 0px 4px ${theme.shadow.color};

  overflow-x: hidden;
`;

const LeadTemplateWrapperHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background: ${theme.colors.primary};
  padding: 5px;

  overflow-x: hidden;
  min-height: 42px;

  cursor: move;
  margin-bottom: 10px;
  box-sizing: border-box;
`;

const LeadTemplateWrapperBody = styled.div`
  display: flex;
  flex-direction: flex-start;
  justify-content: center;

  background: ${theme.colors.clean};
  padding: 5px;

  flex: 1;

  max-height: 500px;
  overflow-y: auto;
  box-sizing: border-box;
`;

interface LeadEmailCardProps {
  close(): void;
  template: number;
  lead: Lead;
}

export const LeadEmailCard = ({
  close,
  template: templateId,
  lead,
}: LeadEmailCardProps) => {
  const { currentUser } = useAuth();
  const { emailTemplates } = useGlobalData();

  const popupWrapperRef = useRef<HTMLDivElement>(null);
  const leadTemplateWrapperRef = useRef<HTMLDivElement>(null);

  const contextToUse = useMemo(
    () => ({
      ...(currentUser ? serializeAgent(currentUser) : {}),
      ...serializeLead(lead),
    }),
    [lead, currentUser]
  );

  const template = useMemo(
    () => emailTemplates.find((item) => item.id === templateId),
    [emailTemplates, templateId]
  );
  if (!template) close();

  const templateToUse = useMemo(
    () => ({
      cc: templateByContext(template?.cc ?? "", contextToUse),
      name: template?.name ?? "",
      reply_to: templateByContext(template?.reply_to ?? "", contextToUse),
      html: templateByContext(template?.html_content ?? "", contextToUse),
      subject: templateByContext(template?.subject ?? "", contextToUse),
    }),
    [lead, template]
  );

  const [templateHTML, _] = useState<string>(templateToUse?.html ?? "");

  const [templateState, setTemplateState] = useState<
    Partial<Omit<ParsedTemplate, "html">>
  >({
    cc: templateToUse?.cc,
    name: templateToUse?.name,
    reply_to: templateToUse?.reply_to,
    subject: templateToUse?.subject,
  });

  const initiazedEditorRef = useRef<boolean>(false);

  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const handleInitialization = () => {
    if (leadTemplateWrapperRef.current && !initiazedEditorRef.current) {
      const replaceTextNodesWithSpans = (node: HTMLElement) => {
        const walker = document.createTreeWalker(
          node,
          NodeFilter.SHOW_TEXT,
          null
        );
        let currentNode;

        while ((currentNode = walker.nextNode())) {
          const span = document.createElement("span");
          span.textContent = currentNode.textContent;
          currentNode.parentNode?.replaceChild(span, currentNode);
        }
      };

      // Run on the whole document or specify a root node
      replaceTextNodesWithSpans(leadTemplateWrapperRef.current);

      const textualElements = leadTemplateWrapperRef.current.querySelectorAll(
        "span,button,p,h1,h2,h3,h4,h5,h6,a"
      );
      textualElements.forEach((item) =>
        item.setAttribute("contentEditable", "true")
      );

      initiazedEditorRef.current = true;
    }
  };

  const handleSubmit = useCallback(async () => {
    if (isSubmitting) return;
    setSubmitting(true);
    httpMsPushEmail(
      templateState?.subject ?? "",
      leadTemplateWrapperRef.current?.innerHTML ?? "",
      currentUser?.email ?? "",
      `${currentUser?.first_name}`,
      [lead?.email ?? ""],
      (templateState?.cc ?? "").split("\n"),
      [currentUser?.email ?? ""]
    );

    setSubmitting(false);
    close();
  }, [isSubmitting, templateState]);

  useEffect(() => {
    handleInitialization();
  }, []);

  return (
    <Draggable
      handle=".draggable-handle"
      bounds={{
        top: 0,
        left: 0,
        right: window.innerWidth,
        bottom: window.innerHeight,
      }}
      defaultPosition={{
        y: window.innerHeight / 2 - ((2 / 3) * window.innerHeight) / 2,
        x: window.innerWidth / 2 - window.innerWidth / 2 / 2,
      }}
      onStart={() => {
        const allPopups = document.querySelectorAll(".popup-windows");
        let highestZIndex = 100;

        allPopups.forEach((popup) => {
          const zIndex = parseInt(
            window.getComputedStyle(popup).zIndex || "0",
            10
          );
          if (zIndex > highestZIndex) {
            highestZIndex = zIndex;
          }
        });

        if (popupWrapperRef.current) {
          popupWrapperRef.current.style.zIndex = (highestZIndex + 1).toString();
        }
      }}
    >
      <PopupWrapper
        ref={popupWrapperRef}
        className="popup-windows"
        style={{ zIndex: "100" }}
      >
        <ResizableBox
          width={window.innerWidth / 2}
          height={(2 / 3) * window.innerHeight}
          minConstraints={[300, 200]}
          maxConstraints={[window.innerWidth, window.innerHeight]}
        >
          <LeadTemplateWrapper>
            <LeadTemplateWrapperHeader className="draggable-handle">
              {/* Optional draggable handle */}
              <Button
                styleType={ButtonTypes.clean}
                onClick={() => {
                  !isSubmitting && handleSubmit();
                }}
              >
                {isSubmitting && <Loading />}
                {!isSubmitting && <>Send</>}
              </Button>
              <span style={{ cursor: "pointer" }}>
                <Icon
                  onClick={close}
                  type={IconTypes.close}
                  color={colors.white.clean}
                />
              </span>
            </LeadTemplateWrapperHeader>

            <FlexColumn gap="10px">
              <FlexRow
                dimensions={{ width: "100%", height: "100%" }}
                padding={{ top: "16px", left: "40px", right: "40px" }}
                gap="100px"
              >
                <FlexColumn
                  gap="10px"
                  dimensions={{ width: "100%", height: "100%" }}
                >
                  <Title text-align="left" color={theme.font.color}>
                    Reply To
                  </Title>
                  <StyledBaseInput
                    type="text"
                    dimensions={{ width: "100%", height: "24px" }}
                    placeholder="Enter reply to email address..."
                    value={templateState.reply_to ?? ""}
                    onChange={(event) =>
                      setTemplateState({
                        ...templateState,
                        reply_to: event.target.value,
                      })
                    }
                  />
                </FlexColumn>
                <FlexColumn
                  gap="10px"
                  dimensions={{ width: "100%", height: "100%" }}
                >
                  <Title text-align="left" color={theme.font.color}>
                    Subject
                  </Title>
                  <StyledBaseInput
                    type="text"
                    dimensions={{ width: "100%", height: "24px" }}
                    placeholder="Enter mail subject..."
                    value={templateState.subject ?? ""}
                    onChange={(event) =>
                      setTemplateState({
                        ...templateState,
                        subject: event.target.value,
                      })
                    }
                  />
                </FlexColumn>
              </FlexRow>

              <FlexRow
                dimensions={{ width: "100%", height: "100%" }}
                padding={{ top: "16px", left: "40px", right: "40px" }}
                gap="100px"
              >
                <FlexColumn
                  gap="10px"
                  dimensions={{ width: "100%", height: "100%" }}
                >
                  <Title text-align="left" color={theme.font.color}>
                    CC List
                  </Title>
                  <StyledBaseTextArea
                    dimensions={{ width: "100%", height: "128px" }}
                    placeholder="Enter cc list..."
                    value={templateState.cc ?? ""}
                    onChange={(event) =>
                      setTemplateState({
                        ...templateState,
                        cc: event.target.value,
                      })
                    }
                  />
                </FlexColumn>
              </FlexRow>
            </FlexColumn>

            <LeadTemplateWrapperBody ref={leadTemplateWrapperRef}>
              {HTMLReactParser(templateHTML)}
            </LeadTemplateWrapperBody>
          </LeadTemplateWrapper>
        </ResizableBox>
      </PopupWrapper>
    </Draggable>
  );
};
