import { ReactNode } from "react";
import Select, { createFilter } from "react-select";
import { colors, theme } from "../../../constants";
import { Property } from "csstype";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";

export interface SelectOption {
  textColor?: string;
  label?: ReactNode;
  value?: any;
  searchValue: string;
  disabled?: boolean;
}

interface SelectProps {
  options?: SelectOption[];
  value?: any;
  defaultValue?: any;
  onChange?: (selectedOption: SelectOption | null) => void;
  placeholder?: string;
  isClearable?: boolean;
  isSearchable?: boolean;
  isDisabled?: boolean;
  iconColor?: string;
  transparent?: boolean;
  justifyContent?: Property.JustifyContent;
  alignItems?: Property.AlignItems;
  onTop?: boolean;
}

export const SearchSelect = ({
  options = [],
  defaultValue,
  onChange,
  placeholder = "Select...",
  isClearable = true,
  isSearchable = true,
  isDisabled = false,
  iconColor = colors.white.clean,
  transparent = false,
  justifyContent = "flex-start",
  alignItems = "center",
  onTop = false,
}: SelectProps) => {
  const handleChange = (selectedOption: SelectOption | null) => {
    if (onChange) {
      onChange(selectedOption);
    }
  };

  return (
    <Select
      options={options}
      defaultValue={defaultValue}
      onChange={handleChange}
      placeholder={placeholder}
      isClearable={isClearable}
      isSearchable={isSearchable}
      isDisabled={isDisabled}
      filterOption={createFilter({
        ignoreCase: true,
        ignoreAccents: true,
        trim: true,
        matchFrom:'any',
        stringify: (option: FilterOptionOption<SelectOption>) => option.data.searchValue 
      })}
      styles={{
        container: (provided) => ({
          ...provided,
          position: "relative",
        }),
        placeholder: (provided) => ({
          ...provided,
          color: theme.font.color,
        }),
        clearIndicator: (provided) => ({
          ...provided,
          color: colors.white.clean,
        }),
        control: (provided) => ({
          ...provided,
          borderRadius: "4px",
          border: transparent ? `1px solid ${theme.colors.primary}` : "none",
          padding: "4px 16px",
          color: colors.white.clean,
          background: transparent ? "" : theme.colors.primary,
          fontSize: `${theme.font.size.x}px`,
          fontWeight: theme.font.weight.bold,
          alignItems: alignItems,
          justifyContent: justifyContent,
          cursor: isDisabled ? "not-allowed" : "pointer",
          transition: "all 0.5s",
          "&:hover": {
            borderColor: isDisabled ? "" : theme.colors.primary,
          },
        }),
        indicatorSeparator: () => ({
          display: "none",
        }),
        singleValue: (provided) => ({
          ...provided,
          color: colors.white.clean,
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          color: iconColor,
        }),
        menu: (provided) => ({
          ...provided,
          width: "fit-content",
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          background: colors.white.clean,
          borderRadius: "6px",
          boxShadow: `0px 0px 6px ${theme.shadow.color}`,
          zIndex: onTop ? 1 : "unset",
          top: "calc(100% + 10px)",
          transition: "all 0.5s",
          overflowY: "auto",
          scrollbarWidth: "thin",
          scrollbarColor: `${theme.colors.primary} ${colors.white.clean}`,
          "&::-webkit-scrollbar": {
            width: "12px",
          },
          "&::-webkit-scrollbar-track": {
            background: colors.white.clean,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.colors.primary,
            borderRadius: "10px",
          },
        }),
        option: (provided, { isSelected }) => ({
          ...provided,
          color: isSelected ? colors.white.clean : theme.font.color,
          background: isSelected ? theme.colors.primary : "transparent",
          padding: "4px",
          borderRadius: "6px",
          cursor: "pointer",
          transition: "all 0.2s",
          "&:hover": {
            background: colors.white.clean,
            boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.562)",
            borderRadius: "4px",
          },
        }),
      }}
    />
  );
};
