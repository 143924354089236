import type CSS from "csstype";
import { styled } from "styled-components";
import { DimensionStyling, DimensionStylingProps } from "../../styling";

const LoadingWrapperStyled = styled.div`
  width: 100%;
  height: 32px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

interface LoadingImageStyling {
  dimensions?: DimensionStylingProps;
}

export const LoadingImage = styled.img<LoadingImageStyling>`
  user-select: none;

  ${({ dimensions }) =>
    DimensionStyling({ width: "32px", height: "32px", ...dimensions })}
`;

interface LoadingProps extends LoadingImageStyling {}

export const Loading = ({ dimensions }: LoadingProps) => {
  return (
    <LoadingWrapperStyled>
      <LoadingImage dimensions={dimensions} src="/assets/images/loading.svg" />
    </LoadingWrapperStyled>
  );
};
