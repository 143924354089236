import { PricingSchema } from "./pricing";

export enum EventTypes {
  first_call = 0,
  pricing = 1,
}

export interface Event {
  id?: number;

  lead?: number;
  type?: EventTypes;
  created_at: Date | string;
  lead_id?: number;
  user?: { id?: number; first_name?: string; last_name?: string };

  pricing?: PricingSchema | null;
}
