import { useState, useRef, useEffect, useCallback } from 'react';

export function useStateRef<Type>(initialValue: Type): [React.MutableRefObject<Type>, Type, React.Dispatch<React.SetStateAction<Type>>] {
  const [state, setState] = useState<Type>(initialValue);
  const stateRef = useRef<Type>(state);

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  const setStateWithLimit = useCallback(
    (value: React.SetStateAction<Type>) => {
      if (typeof value === 'function') {
        setState((prevState) => {
          const newValue = (value as (prevState: Type) => Type)(prevState);
          stateRef.current = newValue;
          return newValue;
        });
      } else {
        setState(value);
        stateRef.current = value;
      }
    },
    [setState]
  );

  return [stateRef, state, setStateWithLimit];
}
