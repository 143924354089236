import { ReactNode, Fragment } from "react";
import { css, styled } from "styled-components";
import { theme, colors } from "../../../../../constants";
import { FlexColumn, FlexRow, TitleXXL } from "../../../../../shared/styled";
import {
  Select,
  Icon,
  IconTypes,
  Loading,
} from "../../../../../shared/components";
import { Button, ButtonTypes, Text } from "../../../../../shared/styled";
import { OperableType } from "../../../../../models/lead/vehicle";
import { toReadableDate } from "../../../../../utilities/datetime";
import { LIMIT_OPTIONS } from "./constants";
import { parseStyleDict } from "../../../../../utilities/styling";

const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const TableRowInner = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 16px;

  &:hover {
    border-radius: 16px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.13);
  }
`;

interface TableCellInterface {
  isHeaderCell?: boolean;
  isClicable?: boolean;
  styling?: any;
}

const TableCell = styled.div<TableCellInterface>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${theme.font.color};
  width: 100%;

  font-weight: ${({ isHeaderCell }) =>
    isHeaderCell ? theme.font.weight.bold : theme.font.weight.normal};
  font-size: ${({ isHeaderCell }) =>
    `${isHeaderCell ? theme.font.size.xl : theme.font.size.x}px`};
  user-select: ${({ isHeaderCell }) => (isHeaderCell ? "none" : undefined)};

  height: 64px;
  padding: 6px;
  box-sizing: border-box;

  cursor: ${({ isClicable }) => (isClicable ? "pointer" : undefined)};
  white-space: nowrap;
  ${({ styling }) => parseStyleDict({ ...styling })};
`;

const selectStyle = css`
  background: transparent;
  border: none;
  color: ${theme.colors.secondary};
  gap: 4px;
`;

const selectStyleOptions = css`
  min-width: 160px;
  z-index: 1;
  right: 0;
`;

export interface ColumnItem<T> {
  mapper?: (item: T) => ReactNode;
  label?: ReactNode;
  key?: string;
  styling?: {
    maxWidth?: string;
    flex?: string | number;
    minWidth?: string;
  };
}

export const TableHeader = ({
  headerComponent,
  headerDimensions,
  headerBackground,
  justifyHeaderContent,
}: {
  headerComponent: ReactNode;
  headerDimensions?: { width?: string; height?: string };
  headerBackground?: string;
  justifyHeaderContent?: string;
}) => (
  <FlexRow
    style={{ borderRadius: "16px 16px 0px 0px" }}
    justifyContent={justifyHeaderContent || "flex-end"}
    gap="20px"
    dimensions={headerDimensions}
    background={{ color: headerBackground || "#E4ECFB" }}
    padding={{ top: "4px", bottom: "4px", left: "32px", right: "32px" }}
  >
    {headerComponent}
  </FlexRow>
);

interface TableProps<T> {
  data?: T[];
  columns?: ColumnItem<T>[];
  orders?: {
    orderableFields?: string[] | "all";
    setOrder: (values: string[]) => void;
    value: string[];
  };
  pagination?: {
    setLimit?: (value: number) => void;
    setOffset?: (value: number) => void;
    offset?: number;
    limit?: number;
    total?: number;
  };
  header?: (value: boolean) => true | false;
  headerComponent?: ReactNode;
  headerDimensions?: { width?: string; height?: string };
  headerBackground?: string;
  justifyHeaderContent?: string;
  tableShadow?: string;
  footerShadow?: string;
  loading?: boolean;

  title?: string;
  isExpandable?: boolean;
  isExpanded?: boolean;
  expandedRowIndex?: number | null;
  setExpandedRowIndex?: (value: number | null) => void;
  hideHeaderLabeles?: boolean;
}

const CaretUp = () => <>&#9650;</>;
const CaretDown = () => <>&#9660;</>;

export const Table = <T,>({
  data,
  columns,
  orders,
  pagination,
  header,
  headerComponent,
  headerDimensions,
  headerBackground,
  justifyHeaderContent,
  tableShadow,
  footerShadow,
  loading,
  title,
  isExpandable,
  isExpanded,
  expandedRowIndex,
  hideHeaderLabeles,
}: TableProps<T>) => {
  const order = (field?: string, column?: ColumnItem<T>) => {
    if (!orders?.orderableFields || !field) return;
    const { orderableFields, setOrder, value } = orders;

    if (
      !(orderableFields === "all" || (orderableFields || [])?.includes(field))
    )
      return;

    const ascExists = value?.includes(field);
    const descExists = value?.includes(`-${field}`);
    setOrder(
      (() => {
        if (!ascExists && !descExists) return [...value, field];
        if (ascExists)
          return [...value?.filter((key) => key !== field), `-${field}`];

        return value?.filter((key) => key !== `-${field}`);
      })()
    );
  };

  const numberOfPages =
    Math.round((pagination?.total || 0) / (pagination?.limit || 1)) +
    ((pagination?.total || 0) % (pagination?.limit || 1) === 0 ? 0 : 1);
  const currentPage = Math.round(
    (pagination?.offset || 0) / (pagination?.limit || 1)
  );

  return (
    <FlexColumn
      dimensions={{ width: "100%" }}
      gap="10px"
      background={{ color: "white" }}
      style={{ boxShadow: tableShadow, borderRadius: "16px 16px 16px 16px" }}
    >
      {header && (
        <TableHeader
          headerComponent={headerComponent}
          headerDimensions={headerDimensions}
          headerBackground={headerBackground}
          justifyHeaderContent={justifyHeaderContent}
        />
      )}

      {loading && <Loading />}
      {!loading && (
        <>
          {title && (
            <FlexColumn
              justifyContent="center"
              alignItems="center"
              gap="10px"
              dimensions={{ width: "fit-content", height: "100%" }}
              padding={{
                top: "16px",
                bottom: "16px",
                left: "64px",
                right: "32px",
              }}
            >
              <TitleXXL>{title}</TitleXXL>
            </FlexColumn>
          )}

          <FlexColumn
            dimensions={{ width: "100%" }}
            padding={{ left: "32px", right: "32px" }}
          >
            {!hideHeaderLabeles && (
              <TableRow>
                {columns?.map((column) => {
                  const headerClicable =
                    !!column?.key &&
                    (orders?.orderableFields === "all" ||
                      (orders?.orderableFields || [])?.includes(column?.key));

                  return (
                    <TableCell
                      key={column.key}
                      isClicable={headerClicable}
                      onClick={() => order(column?.key, column)}
                      isHeaderCell={true}
                      style={column.styling}
                    >
                      <FlexRow
                        justifyContent="center"
                        alignItems="center"
                        gap="10px"
                        dimensions={{ width: "100%", height: "100%" }}
                        style={column.styling}
                      >
                        {column?.label}
                        {column?.key &&
                          (orders?.value || [])?.includes(column?.key) && (
                            <CaretUp />
                          )}
                        {column?.key &&
                          (orders?.value || [])?.includes(
                            `-${column?.key}`
                          ) && <CaretDown />}
                      </FlexRow>
                    </TableCell>
                  );
                })}
              </TableRow>
            )}
            {data?.map((item: any) => (
              <Fragment key={item?.id}>
                <TableRowInner>
                  {columns?.map((column) => (
                    <TableCell key={column.key}>
                      {column?.mapper
                        ? column.mapper(item)
                        : (item as any)[column.key as keyof T]}
                    </TableCell>
                  ))}
                </TableRowInner>
                {isExpandable &&
                  isExpanded &&
                  expandedRowIndex === item?.id && (
                    //TODO turn this into a component
                    <FlexRow padding={{ left: "64px", right: "64px" }}>
                      <FlexRow
                        dimensions={{ width: "100%" }}
                        background={{ color: `${colors.white.gallery}` }}
                        border={{ radius: "16px" }}
                        padding={{
                          top: "16px",
                          bottom: "16px",
                          left: "32px",
                          right: "32px",
                        }}
                      >
                        <FlexColumn
                          dimensions={{ flex: 1 }}
                          justifyContent="space-between"
                          gap="8px"
                        >
                          <FlexColumn gap="4px">
                            <Text
                              size={`${theme.font.size.xxl}px`}
                              color={`${theme.colors.primary}`}
                            >
                              Origin City
                            </Text>
                            <Text size={`${theme.font.size.xxl}px`}>
                              {item?.origin_city}
                            </Text>
                          </FlexColumn>
                          <FlexColumn gap="4px">
                            <Text
                              size={`${theme.font.size.xxl}px`}
                              color={`${theme.colors.primary}`}
                            >
                              Origin Country
                            </Text>
                            <Text size={`${theme.font.size.xxl}px`}>
                              {item?.origin_country}
                            </Text>
                          </FlexColumn>
                          <FlexColumn gap="4px">
                            <Text
                              size={`${theme.font.size.xxl}px`}
                              color={`${theme.colors.primary}`}
                            >
                              Origin Postal Code
                            </Text>
                            <Text size={`${theme.font.size.xxl}px`}>
                              {item?.origin_postal_code}
                            </Text>
                          </FlexColumn>
                          <FlexColumn gap="4px">
                            <Text
                              size={`${theme.font.size.xxl}px`}
                              color={`${theme.colors.primary}`}
                            >
                              Origin Postal State
                            </Text>
                            <Text size={`${theme.font.size.xxl}px`}>
                              {item?.origin_state}
                            </Text>
                          </FlexColumn>
                        </FlexColumn>
                        <FlexColumn
                          dimensions={{ flex: 1 }}
                          justifyContent="space-between"
                          gap="8px"
                        >
                          <FlexColumn gap="4px">
                            <Text
                              size={`${theme.font.size.xxl}px`}
                              color={`${theme.colors.primary}`}
                            >
                              Destination City
                            </Text>
                            <Text size={`${theme.font.size.xxl}px`}>
                              {item?.destination_city}
                            </Text>
                          </FlexColumn>
                          <FlexColumn gap="4px">
                            <Text
                              size={`${theme.font.size.xxl}px`}
                              color={`${theme.colors.primary}`}
                            >
                              Destination Country
                            </Text>
                            <Text size={`${theme.font.size.xxl}px`}>
                              {item?.destination_country}
                            </Text>
                          </FlexColumn>
                          <FlexColumn gap="4px">
                            <Text
                              size={`${theme.font.size.xxl}px`}
                              color={`${theme.colors.primary}`}
                            >
                              Destination Postal Code
                            </Text>
                            <Text size={`${theme.font.size.xxl}px`}>
                              {item?.destination_postal_code}
                            </Text>
                          </FlexColumn>
                          <FlexColumn gap="4px">
                            <Text
                              size={`${theme.font.size.xxl}px`}
                              color={`${theme.colors.primary}`}
                            >
                              Destination Postal State
                            </Text>
                            <Text size={`${theme.font.size.xxl}px`}>
                              {item?.destination_state}
                            </Text>
                          </FlexColumn>
                        </FlexColumn>
                        <FlexColumn
                          dimensions={{ flex: 1 }}
                          justifyContent="space-between"
                        >
                          <FlexColumn>
                            <Text
                              size={`${theme.font.size.xxl}px`}
                              color={`${theme.colors.primary}`}
                            >
                              Proposed Price
                            </Text>
                            <Text size={`${theme.font.size.xxl}px`}>
                              {item?.events_map?.latest_price?.pricing?.price} $
                            </Text>
                          </FlexColumn>
                          <FlexColumn gap="8px">
                            <FlexColumn gap="4px">
                              <Text
                                size={`${theme.font.size.xxl}px`}
                                color={`${theme.colors.primary}`}
                              >
                                Vehicle Type and Condition
                              </Text>
                              <Text size={`${theme.font.size.xxl}px`}>
                                {item?.vehicles?.[0]?.vehicle_type},{" "}
                                {item?.vehicles?.[0]?.vehicle_inop ===
                                OperableType?.inoperable ? (
                                  <>Inoperable</>
                                ) : (
                                  <>Operable</>
                                )}
                              </Text>
                            </FlexColumn>
                            <FlexColumn gap="4px">
                              <Text
                                size={`${theme.font.size.xxl}px`}
                                color={`${theme.colors.primary}`}
                              >
                                Vehicle
                              </Text>
                              <Text size={`${theme.font.size.xxl}px`}>
                                {item?.vehicles?.[0]?.vehicle_make}{" "}
                                {item?.vehicles?.[0]?.vehicle_model}{" "}
                                {item?.vehicles?.[0]?.vehicle_model_year}
                              </Text>
                            </FlexColumn>
                          </FlexColumn>
                        </FlexColumn>
                        <FlexColumn
                          dimensions={{ flex: 1 }}
                          justifyContent="space-between"
                        >
                          {item?.notes?.length > 0 && (
                            <FlexColumn gap="8px">
                              <FlexColumn
                                dimensions={{ maxHeight: "250px" }}
                                gap="16px"
                                overflow={{
                                  x: "hidden",
                                  y: "scroll",
                                }}
                              >
                                {item?.notes?.map((note: any) => (
                                  <FlexColumn
                                    gap="8px"
                                    dimensions={{ width: "100%" }}
                                    border={{ radius: "6px" }}
                                    background={{
                                      color: `${colors.blue.light}`,
                                    }}
                                    style={{
                                      boxShadow:
                                        "inset 0px -2px 4px rgba(0, 0, 0, 0.25)",
                                    }}
                                    padding={{
                                      top: "16px",
                                      bottom: "16px",
                                      left: "8px",
                                      right: "8px",
                                    }}
                                  >
                                    <Text
                                      size={`${theme.font.size.x}px`}
                                      color={`${theme.colors.primary}`}
                                    >
                                      {note?.agent?.first_name}{" "}
                                      {note?.agent?.last_name}
                                    </Text>
                                    <Text size={`${theme.font.size.xxl}px`}>
                                      {note?.content}
                                    </Text>
                                    <div
                                      style={{
                                        height: "1px",
                                        width: "100%",
                                        backgroundColor: "#C7E7FF",
                                      }}
                                    />
                                    <Text
                                      size={`${theme.font.size.x}px`}
                                      color={`${theme.colors.primary}`}
                                    >
                                      {toReadableDate(note?.created_at as Date)}
                                    </Text>
                                  </FlexColumn>
                                ))}
                              </FlexColumn>
                            </FlexColumn>
                          )}
                        </FlexColumn>
                      </FlexRow>
                    </FlexRow>
                  )}
              </Fragment>
            ))}
          </FlexColumn>
        </>
      )}

      {pagination && (
        <FlexRow
          style={{ boxShadow: footerShadow, borderRadius: "0px 0px 16px 16px" }}
          justifyContent="flex-end"
          gap="20px"
          dimensions={{ height: "60px" }}
          background={{ color: "#E4ECFB" }}
          padding={{ top: "4px", bottom: "4px", left: "32px", right: "32px" }}
        >
          {pagination?.setLimit && pagination?.limit && (
            <FlexRow gap="5px" justifyContent="center" alignItems="center">
              <Text>Rows per page:</Text>
              <Select
                justifyContent="center"
                alignItems="center"
                iconColor={theme.colors.secondary}
                setValue={(value) =>
                  pagination?.setLimit && pagination?.setLimit(Number(value))
                }
                style={{
                  valueWrapper: { css: selectStyle },
                  optionsContainer: { css: selectStyleOptions },
                }}
                value={pagination?.limit}
                options={LIMIT_OPTIONS}
              />
            </FlexRow>
          )}
          {pagination?.total &&
            (pagination?.offset || pagination?.offset === 0) &&
            pagination?.setOffset &&
            pagination?.limit && (
              <FlexRow gap="5px" justifyContent="center">
                {currentPage !== 0 && (
                  <Button
                    styleType={ButtonTypes.naked}
                    onClick={() =>
                      pagination?.setOffset &&
                      pagination?.setOffset(
                        (currentPage - 1) * (pagination?.limit || 0)
                      )
                    }
                  >
                    <Icon
                      type={IconTypes.arrowLeft}
                      color={colors.black.codGray}
                      size="16px"
                    />
                  </Button>
                )}
                <Button disabled={true} styleType={ButtonTypes.naked}>
                  {`${currentPage * pagination.limit + 1}-${Math.min(
                    (currentPage + 1) * pagination.limit,
                    pagination.total
                  )} of ${pagination.total}`}
                </Button>
                {currentPage + 1 < numberOfPages && (
                  <Button
                    styleType={ButtonTypes.naked}
                    onClick={() =>
                      pagination?.setOffset &&
                      pagination?.setOffset(
                        (currentPage + 1) * (pagination?.limit || 0)
                      )
                    }
                  >
                    <Icon
                      type={IconTypes.arrowRight}
                      color={colors.black.codGray}
                      size="16px"
                    />
                  </Button>
                )}
              </FlexRow>
            )}
        </FlexRow>
      )}
    </FlexColumn>
  );
};
