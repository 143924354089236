import { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  FlexRow,
  FlexContainer,
  TitleXXL,
  Title,
  Text,
  FlexColumn,
  Image,
  Button,
} from "../../../../shared/styled";
import { Loading } from "../../../../shared/components";
import {
  StyledBaseInput,
  StyledCheckbox,
} from "../../../../shared/components/Input/StyledBaseInput";
import { User } from "../../../../models/user";
import { getAvatarUrl } from "../../../../utilities/avatar";
import { colors, theme } from "../../../../constants";
import { Toaster, toast } from "react-hot-toast";

import {
  httpAdminUpdateUser,
  httpAdminInviteUser,
  httpAdminGetUsers,
} from "../../../../apis/admin/user";
import {
  AGENT_ROLE_JUNIOR,
  AGENT_ROLE_SENIOR,
  AGENT_ROLE_SENIOR_TWO,
} from "../../../../models/roles/constants";

interface FormData {
  first_name: string;
  last_name: string;
  email: string;
  confirm_email: string;
  roles: string[];
  agent_roles: number[];
  password: string;
  confirm_password: string;
  phone_number: string;
  confirm_phone_number: string;
  is_agent_junior: boolean;
  is_agent_senior: boolean;
  is_agent_senior_two: boolean;
  is_admin: boolean;
  is_marketing: boolean;
}

export const EditUser = () => {
  const navigate = useNavigate();

  const { emailFromUrl } = useParams();
  const creatingUser = emailFromUrl ? false : true;

  const [data, setData] = useState<User>();
  const [isLoading, setLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<FormData>();

  const createUserSubmit: SubmitHandler<FormData> = async (newData) => {
    if (newData.password !== newData.confirm_password) {
      toast.error("Passwords do not match", {
        style: {
          border: `1px solid ${theme.colors.danger}`,
          padding: "16px",
          boxShadow: "none",
          borderRadius: "24px",
        },
        iconTheme: {
          primary: `${theme.colors.danger}`,
          secondary: `${theme.colors.clean}`,
        },
      });
      return;
    }

    if (newData.email !== newData.confirm_email) {
      toast.error("Emails do not match", {
        style: {
          border: `1px solid ${theme.colors.danger}`,
          padding: "16px",
          boxShadow: "none",
          borderRadius: "24px",
        },
        iconTheme: {
          primary: `${theme.colors.danger}`,
          secondary: `${theme.colors.clean}`,
        },
      });
      return;
    }

    try {
      await httpAdminInviteUser({
        assign_agent_roles: [
          ...(newData?.is_agent_junior ? [AGENT_ROLE_JUNIOR] : []),
          ...(newData?.is_agent_senior ? [AGENT_ROLE_SENIOR] : []),
          ...(newData?.is_agent_senior_two ? [AGENT_ROLE_SENIOR_TWO] : []),
        ],
        email: newData.email,
        first_name: newData.first_name,
        is_admin: newData?.is_admin || false,
        is_marketing: newData?.is_marketing || false,
        last_name: newData?.last_name,
        password: newData?.password,
        phone_number: newData?.phone_number,
      });
      toast.success("User Invited Successfully", {
        style: {
          border: `1px solid ${theme.colors.primary}`,
          padding: "16px",
          boxShadow: "none",
          borderRadius: "24px",
        },
        iconTheme: {
          primary: `${theme.colors.primary}`,
          secondary: `${theme.colors.clean}`,
        },
      });
      navigate("/admin/users");
      console.log(newData);
    } catch (err) {
      toast.error("Error inviting user", {
        style: {
          border: `1px solid ${theme.colors.danger}`,
          padding: "16px",
          boxShadow: "none",
          borderRadius: "24px",
        },
        iconTheme: {
          primary: `${theme.colors.danger}`,
          secondary: `${theme.colors.clean}`,
        },
      });
      console.log(err);
    }
  };

  const updateUserSubmit: SubmitHandler<FormData> = async (newData) => {
    if (newData?.password !== newData?.confirm_password) {
      toast.error("Passwords do not match", {
        style: {
          border: `1px solid ${theme.colors.danger}`,
          padding: "16px",
          boxShadow: "none",
          borderRadius: "24px",
        },
        iconTheme: {
          primary: `${theme.colors.danger}`,
          secondary: `${theme.colors.clean}`,
        },
      });
      return;
    }

    if (
      data?.email !== newData?.email &&
      newData?.email !== newData?.confirm_email
    ) {
      toast.error("Emails do not match", {
        style: {
          border: `1px solid ${theme.colors.danger}`,
          padding: "16px",
          boxShadow: "none",
          borderRadius: "24px",
        },
        iconTheme: {
          primary: `${theme.colors.danger}`,
          secondary: `${theme.colors.clean}`,
        },
      });
      return;
    }
    try {
      await httpAdminUpdateUser(
        data?.id as number,
        // This will remove all attributes with undefined value
        JSON.parse(
          JSON.stringify({
            assign_agent_roles: [
              ...(newData?.is_agent_junior ? [AGENT_ROLE_JUNIOR] : []),
              ...(newData?.is_agent_senior ? [AGENT_ROLE_SENIOR] : []),
              ...(newData?.is_agent_senior_two ? [AGENT_ROLE_SENIOR_TWO] : []),
            ],
            email: newData?.email,
            first_name: newData.first_name,
            last_name: newData.last_name,
            password:
              !newData?.password || !newData?.password?.length
                ? undefined
                : newData?.password,
            phone_number: newData.phone_number,
            is_admin: newData?.is_admin || false,
            is_marketing: newData?.is_marketing || false,
          })
        )
      );

      toast.success("User updated Successfully", {
        style: {
          border: `1px solid ${theme.colors.primary}`,
          padding: "16px",
          boxShadow: "none",
          borderRadius: "24px",
        },
        iconTheme: {
          primary: `${theme.colors.primary}`,
          secondary: `${theme.colors.clean}`,
        },
      });
      navigate("/admin/users");
    } catch (err) {
      toast.error("Error updating user", {
        style: {
          border: `1px solid ${theme.colors.danger}`,
          padding: "16px",
          boxShadow: "none",
          borderRadius: "24px",
        },
        iconTheme: {
          primary: `${theme.colors.danger}`,
          secondary: `${theme.colors.clean}`,
        },
      });
      console.log(err);
    }
  };

  if (!creatingUser) {
    const fetch = async () => {
      try {
        const response = await httpAdminGetUsers(emailFromUrl);
        const user = (response?.data as User[])[0];
        setData(user);

        setValue("first_name", user?.first_name);
        setValue("last_name", user?.last_name);
        setValue("email", user?.email as string);
        setValue("phone_number", user?.phone_number as string);
        setValue(
          "is_admin",
          (user?.roles || [])?.includes("System Super Administrator") ||
            (user?.roles || [])?.includes("Administrator")
        );
        setValue("is_marketing", (user?.roles || [])?.includes("Marketing"));

        setValue(
          "is_agent_junior",
          (user?.agent_roles || [])?.some(
            (role) => role?.id === AGENT_ROLE_JUNIOR
          )
        );
        setValue(
          "is_agent_senior",
          (user?.agent_roles || [])?.some(
            (role) => role?.id === AGENT_ROLE_SENIOR
          )
        );
        setValue(
          "is_agent_senior_two",
          (user?.agent_roles || [])?.some(
            (role) => role?.id === AGENT_ROLE_SENIOR_TWO
          )
        );
      } catch (err) {}
      setLoading(false);
    };

    useEffect(() => {
      fetch();
    }, []);
  }

  const avatarUrl = getAvatarUrl(
    watch().first_name || "Unknown",
    watch().first_name || "Unknown",
    `${data?.id}`
  );

  return (
    <>
      <Toaster position="bottom-left" reverseOrder={false} />
      <FlexContainer
        flexDirection="column"
        dimensions={{ width: "100%", height: "100%" }}
        padding={{ left: "32px", right: "32px", top: "64px" }}
      >
        {isLoading && <Loading />}
        {!isLoading && (
          <form
            onSubmit={handleSubmit(
              creatingUser ? createUserSubmit : updateUserSubmit
            )}
          >
            <FlexColumn
              dimensions={{ width: "100%" }}
              gap="10px"
              background={{ color: "white" }}
              style={{
                boxShadow: ` 0px 4px 4px${theme.shadow.color}`,
                borderRadius: "16px 16px 16px 16px",
              }}
            >
              <FlexRow
                dimensions={{ width: "100%" }}
                padding={{ bottom: "16px" }}
              >
                <FlexRow
                  style={{ borderRadius: "16px 16px 0px 0px" }}
                  justifyContent="flex-end"
                  alignItems="center"
                  gap="20px"
                  dimensions={{ height: "72px", width: "100%" }}
                  background={{ color: "#E4ECFB" }}
                  padding={{
                    top: "4px",
                    bottom: "4px",
                    left: "40px",
                    right: "40px",
                  }}
                >
                  <TitleXXL text-align="center" color={theme.colors.primary}>
                    {creatingUser ? <>Create User</> : <>Edit user</>}
                  </TitleXXL>
                </FlexRow>
              </FlexRow>
              <FlexColumn gap="48px">
                <FlexRow
                  dimensions={{ width: "100%" }}
                  padding={{ top: "16px", left: "40px", right: "40px" }}
                  gap="100px"
                >
                  <FlexColumn
                    gap="10px"
                    dimensions={{ width: "auto" }}
                    justifyContent="center"
                  >
                    <Title text-align="center" color={theme.font.color}>
                      Profile Image
                    </Title>
                    <Image
                      padding="2px"
                      background={colors.white.clean}
                      border={{
                        radius: "50%",
                        width: "2px",
                        color: theme.colors.disabled,
                      }}
                      // TODO Download this image and put it in `/public/assets/images/default_avatar.png` path
                      // use it from there
                      src={
                        creatingUser
                          ? "https://www.w3schools.com/howto/img_avatar.png"
                          : avatarUrl
                      }
                      dimensions={{ width: "128px", height: "128px" }}
                    ></Image>
                  </FlexColumn>
                  <FlexRow dimensions={{ width: "auto" }} gap="16px">
                    <FlexColumn gap="20px">
                      <Title text-align="left" color={theme.font.color}>
                        System Roles
                      </Title>
                      <FlexContainer
                        flexDirection="column"
                        gap="20px"
                        justify-content="between"
                      >
                        <FlexRow gap="12px">
                          <StyledCheckbox
                            defaultChecked={
                              data?.roles?.includes(
                                "System Super Administrator"
                              ) || data?.roles?.includes("Administrator")
                            }
                            {...register("is_admin")}
                          />
                          <Text>Is Admin</Text>
                        </FlexRow>
                        <FlexRow gap="12px">
                          <StyledCheckbox
                            defaultChecked={data?.roles?.includes("Marketing")}
                            {...register("is_marketing")}
                          />
                          <Text>Is Marketing</Text>
                        </FlexRow>
                      </FlexContainer>
                    </FlexColumn>
                    <FlexColumn gap="20px">
                      <Title text-align="left" color={theme.font.color}>
                        Agent Roles
                      </Title>
                      <FlexColumn gap="5px">
                        <FlexRow gap="12px">
                          <StyledCheckbox
                            defaultChecked={data?.agent_roles?.some(
                              (role) => role.id === AGENT_ROLE_JUNIOR
                            )}
                            value={AGENT_ROLE_JUNIOR}
                            {...register("is_agent_junior")}
                          />
                          <Text>Junior Agent</Text>
                        </FlexRow>
                        <FlexRow gap="12px">
                          <StyledCheckbox
                            defaultChecked={data?.agent_roles?.some(
                              (role) => role.id === AGENT_ROLE_SENIOR
                            )}
                            value={AGENT_ROLE_SENIOR}
                            {...register("is_agent_senior")}
                          />
                          <Text>Senior Agent</Text>
                        </FlexRow>
                        <FlexRow gap="12px">
                          <StyledCheckbox
                            defaultChecked={data?.agent_roles?.some(
                              (role) => role.id === AGENT_ROLE_SENIOR_TWO
                            )}
                            value={AGENT_ROLE_SENIOR_TWO}
                            {...register("is_agent_senior_two")}
                          />
                          <Text>Senior II Agent</Text>
                        </FlexRow>
                      </FlexColumn>
                    </FlexColumn>
                  </FlexRow>
                </FlexRow>
                <FlexRow
                  dimensions={{ width: "100%", height: "100%" }}
                  padding={{ top: "16px", left: "40px", right: "40px" }}
                  gap="100px"
                >
                  <FlexColumn
                    gap="40px"
                    dimensions={{ width: "100%", height: "100%" }}
                  >
                    <FlexColumn
                      gap="8px"
                      dimensions={{ width: "100%", height: "32px" }}
                    >
                      <Title text-align="left" color={theme.font.color}>
                        First Name
                      </Title>
                      <StyledBaseInput
                        type="text"
                        dimensions={{ width: "100%", height: "24px" }}
                        placeholder="Enter first name..."
                        {...register("first_name", { required: true })}
                      />
                    </FlexColumn>
                    {creatingUser && (
                      <FlexColumn
                        gap="8px"
                        dimensions={{ width: "100%", height: "32px" }}
                      >
                        <Title text-align="left" color={theme.font.color}>
                          Password
                        </Title>
                        <StyledBaseInput
                          type="password"
                          autoComplete="new-password"
                          dimensions={{ width: "100%", height: "24px" }}
                          placeholder="Enter password..."
                          {...register("password", {
                            required: creatingUser ? true : false,
                          })}
                        />
                      </FlexColumn>
                    )}
                    <FlexColumn
                      gap="8px"
                      dimensions={{ width: "100%", height: "32px" }}
                    >
                      <Title text-align="left" color={theme.font.color}>
                        Phone Number
                      </Title>
                      <StyledBaseInput
                        type="text"
                        dimensions={{ width: "100%", height: "24px" }}
                        placeholder="Enter phone number..."
                        {...register("phone_number", {
                          required: creatingUser ? true : false,
                        })}
                      />
                    </FlexColumn>
                    {creatingUser && (
                      <FlexColumn
                        gap="8px"
                        dimensions={{ width: "100%", height: "32px" }}
                      >
                        <Title text-align="left" color={theme.font.color}>
                          E-Mail Address
                        </Title>
                        <StyledBaseInput
                          type="text"
                          autoComplete="new-password"
                          dimensions={{ width: "100%", height: "24px" }}
                          placeholder="Enter e-mail..."
                          {...register("email", { required: true })}
                        />
                      </FlexColumn>
                    )}
                  </FlexColumn>
                  <FlexColumn
                    gap="40px"
                    dimensions={{ width: "100%", height: "100%" }}
                  >
                    <FlexColumn
                      gap="8px"
                      dimensions={{ width: "100%", height: "32px" }}
                    >
                      <Title text-align="left" color={theme.font.color}>
                        Last Name
                      </Title>
                      <StyledBaseInput
                        type="text"
                        dimensions={{ width: "100%", height: "24px" }}
                        placeholder="Enter last name..."
                        {...register("last_name", { required: true })}
                      />
                    </FlexColumn>
                    {creatingUser && (
                      <FlexColumn
                        gap="8px"
                        dimensions={{ width: "100%", height: "32px" }}
                      >
                        <Title text-align="left" color={theme.font.color}>
                          Confirm Password
                        </Title>
                        <StyledBaseInput
                          type="password"
                          dimensions={{ width: "100%", height: "24px" }}
                          placeholder="Enter password..."
                          {...register("confirm_password", {
                            required: creatingUser ? true : false,
                          })}
                        />
                      </FlexColumn>
                    )}
                    <FlexColumn
                      gap="8px"
                      dimensions={{ width: "100%", height: "32px" }}
                    >
                      <Title text-align="left" color={theme.font.color}>
                        Confirm Phone Number
                      </Title>
                      <StyledBaseInput
                        type="text"
                        dimensions={{ width: "100%", height: "24px" }}
                        placeholder="Enter phone number..."
                        {...register("confirm_phone_number", {
                          required: creatingUser ? true : false,
                        })}
                      />
                    </FlexColumn>
                    {creatingUser && (
                      <FlexColumn
                        gap="8px"
                        dimensions={{ width: "100%", height: "32px" }}
                      >
                        <Title text-align="left" color={theme.font.color}>
                          Confirm E-Mail Address
                        </Title>
                        <StyledBaseInput
                          type="text"
                          dimensions={{ width: "100%", height: "24px" }}
                          placeholder="Enter e-mail..."
                          {...register("confirm_email", {
                            required: creatingUser ? true : false,
                          })}
                        />
                      </FlexColumn>
                    )}
                  </FlexColumn>
                </FlexRow>
                <FlexRow
                  justifyContent="flex-end"
                  gap="32px"
                  padding={{ left: "40px", right: "40px", bottom: "40px" }}
                >
                  <Button
                    justifyContent="center"
                    alignItems="center"
                    dimensions={{ width: "auto", height: "40px" }}
                    background={{ color: theme.colors.danger }}
                    border={{ radius: "8px" }}
                    onClick={() => navigate("/admin/users")}
                  >
                    <Text color="white">Cancel</Text>
                  </Button>
                  <Button
                    justifyContent="center"
                    alignItems="center"
                    dimensions={{ width: "auto", height: "40px" }}
                    background={{ color: theme.colors.primary }}
                    border={{ radius: "8px" }}
                    type="submit"
                  >
                    <Text color="white">Submit</Text>
                  </Button>
                </FlexRow>
              </FlexColumn>
            </FlexColumn>
          </form>
        )}
      </FlexContainer>
    </>
  );
};
