import { JINGLE_NEW_LEAD } from "../../constants";

import { Lead } from "../../models/lead";
import { LeadFilters } from "../../models/lead/types";
import { User } from "../../models/user";
import { Pagination } from "../../types";
import { NewLeadAction } from "../types";

export const newLeadAction = (
  currentUser: User,
  data: NewLeadAction["data"],
  setLeads: (leads: Lead[]) => void,
  leads: Lead[],
  filters?: LeadFilters,
  pagination?: Pagination,
  setPagination?: (pagination: Pagination) => void
) => {
  if (Object.keys(filters || {})?.length) {
    /* Dont appned if does not belong to agent */
    if (filters?.agent__id && filters?.agent__id !== currentUser?.id) return;
    if (
      ![null, undefined].includes(filters?.priority as any) &&
      data?.priority !== filters?.priority
    )
      return;
    if (
      filters?.search &&
      !`${data?.first_name}${data?.last_name}${data?.email}${data.phone}`.includes(
        filters?.search
      )
    )
      return;
  }

  (new Audio(JINGLE_NEW_LEAD)).play();

  setLeads([data, ...leads]);
  setPagination &&
    setPagination({
      ...pagination,
      offset: (pagination?.offset || 0) + 1,
      limit: pagination?.limit || 10,
    });
};
