import { SectionSchema } from "../types/sectionSchema";

import { FormSection } from "./FormSection";
import { FormSectionDocumentation } from "./documentation";


export const FormSectionSchema: SectionSchema = {
  component: FormSection,
  documentation: FormSectionDocumentation,
  label: "Form",
  id: "form"
}