import { Lead } from "../../models/lead";
import { User } from "../../models/user";
import { Pagination } from "../../types";
import { NewCallEventAction as NewCallEventActionType } from "../types";

export const newCallEventAction = (
  currentUser: User,
  data: NewCallEventActionType["data"],
  setLeads: (leads: Lead[]) => void,
  leads: Lead[],
  //TODO Create Interface for filters
  filters?: any,
  pagination?: Pagination,
  setPagination?: (pagination: Pagination) => void
) => {
  setLeads(
    leads.map((lead) => {
      if (Number(data.lead_id) !== lead.id) return lead;

      return {
        ...lead,
        calls: [...(lead?.calls ?? []), data],
      };
    })
  );
};
