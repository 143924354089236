import { Route } from "react-router-dom";
import { Sandbox } from "./Sandbox";
import { SANDBOX_SECTIONS } from "./sections";

export const SANDBOX_ROUTES = (
  <>
    <Route path="/dev/sandbox" Component={Sandbox}>
      {SANDBOX_SECTIONS.map((section) => (
        <Route
          key={`PATH:/dev/sandbox/${section.id}`}
          path={`/dev/sandbox/${section.id}`}
          Component={section.component}
        />
      ))}

      <Route key="PATH:/dev/sandbox/*" path="/dev/sandbox/*" />
    </Route>
  </>
);
