import { Template } from "../../../models/template";
import { httpGet } from "../../utilities";

interface HttpPaginatedTemplatesListResponse {
  count: number;
  results: Template[];
}

export interface HttpPaginatedTemplatesListResponseFilters {
  type__in?: number[];
  type?: number;
  is_active?: boolean;
}

export const httpAdminTemplateList = async (
  search: string,
  offset: number | undefined,
  limit: number | undefined,
  order: string[],
  filters: HttpPaginatedTemplatesListResponseFilters
) => {
  return httpGet<HttpPaginatedTemplatesListResponse>(`/admin/template`, {
    search: search,
    offset: offset,
    limit: limit,
    ordering: order,
    ...filters,
  });
};
