import { Map } from "../../../shared/components/Map";

export const MapSubscreen = () => {
  return (
    <Map
      styling={{ wrapper: { dimensions: { width: "100%", height: "100%" } } }}
      hideControles={false}
    />
  );
};
