import { useRef } from "react";
import EmailEditor, { EditorRef, EmailEditorProps } from "react-email-editor";

import { EmailTemplaterPropsType } from "./types";
// import { theme } from "../../../constants";
import { EMAIL_TEMPLATER_TEXT_VARIABLE } from "./features/textVariable";

const host = `${window.location.protocol}//${window.location.host}`;

export const EmailTemplater = ({
  setValue,
  value,
}: EmailTemplaterPropsType) => {
  const emailEditorRef = useRef<EditorRef | null>(null);

  const onReady: EmailEditorProps["onReady"] = (unlayer) => {
    unlayer.addEventListener("design:updated", () => {
      if (emailEditorRef?.current?.editor) {
        emailEditorRef?.current?.editor?.exportHtml((data) =>
          setValue(JSON.stringify(data))
        ); //. exportJson((data) => {
        //  console.log("Design updated: ", data);
        //});
      }
    });

    if(typeof value === "string") value = JSON.parse(value);
    unlayer.loadDesign(value);
    // editor is ready
    // you can load your template here;
    // the design json can be obtained by calling
    // unlayer.loadDesign(callback) or unlayer.exportHtml(callback)
    // const templateJson = { DESIGN JSON GOES HERE };
    // unlayer.loadDesign(templateJson);
  };

  return (
    <EmailEditor
      // projectId={123123}
      options={{
        displayMode: "email",
        // TODO Add tool for email statistics
        // customJS: [`${host}/templateEditor/VariableWidget.js`],
        features: {
          textEditor: {
            customButtons: [EMAIL_TEMPLATER_TEXT_VARIABLE],
          },
        },
      }}
      ref={emailEditorRef}
      onReady={onReady}
    />
  );
};
